import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    // Exercise num
    unit: "Unit 7",
    id: "WB10-U7-P61-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page61/E1/Key/answerKey.png",
    textAlign: "center",
    inputSize: 100,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "1",
        title: `
                Complete the holiday-related compound nouns. Use the words below. Which of them are written as one word?
				`,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
		       	<div style='color: rgb(69, 62, 156);'><b>
            back&emsp;
            camp&emsp;
            pen&emsp;
            sun&emsp;
            sun&emsp;
            sun&emsp;
            travel&emsp;
            travel</b></div>
          <div style='display: flex; justify-content: space-between; width:700px; font-size: 26px;'>
            <div >
                <div><b>1 &ensp;</b>#guide</div>
                <div><b>2 &ensp;</b>#burn</div>
                <div><b>3 &ensp;</b>#friend</div>
                <div><b>4 &ensp;</b>#cream</div>
            </div>    
            <div >
                <div><b>5 &ensp;</b>#pack</div>
                <div><b>6 &ensp;</b>#glasses</div>
                <div><b>7 &ensp;</b>#site</div>
                <div><b>8 &ensp;</b>#programme</div>
            </div>    
          </div>    
        `,
        answer: [
          "travel",
          "sun",
          "pen",
          "sun",
          "back",
          "sun",
          "camp",
          "travel",
        ],
      },
    ],
  },

  2: {
    unit: "Unit 7",
    id: "WB10-U7-P61-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page61/E2/key/answerKey.png",
    selectStyle: {
      color: "black",
      fontWeight: "bold",
      width: 40,
      textAlign: "center",
      border: "2px solid black",
      borderRadius: "20%",
    },
    isHiddenCheck: true,

    selectOptionValues: ["T", "F"],
    titleQuestion: [
      {
        num: "2",
        title: `
				Read the texts. Are the sentences true (T) or false (F)?
			  `,
        color: "black",
      },
    ],
    inputSize: 410,
    textAlign: "left",
    background: "white",
    maxLength: 50,
    titleImage: "",
    questions: [
      {
        title: `
        <img src='/img/FriendsPlus/Page61/E2/1.jpg' style='margin: 10px 0px 30px -100px; width: 1300px;'/>
          <div style='display:flex; gap:15px;'>
            <b>1</b>
            <div style='display:flex; gap:15px; justify-content: space-between;width: 600px;'>
                <div>Sam's parents chose his holiday.</div>
                <select id='0'></select>
            </div>
          </div>
          <div style='display:flex; gap:15px;'>
            <b>2</b>
            <div style='display:flex; gap:15px; justify-content: space-between;width: 600px;'>
                <div>Sam had a frightening experience. </div>
                <select id='1'></select>
            </div>
          </div>
          <div style='display:flex; gap:15px;'>
            <b>3</b>
            <div style='display:flex; gap:15px; justify-content: space-between;width: 600px;'>
                <div>The weather was good during Harriet's holiday. </div>
                <select id='2'></select>
            </div>
          </div>
          <div style='display:flex; gap:15px;'>
            <b>4</b>
            <div style='display:flex; gap:15px; justify-content: space-between;width: 600px;'>
                <div>Harriet's holiday was shorter than she expected.</div>
                <select id='3'></select>
            </div>
          </div>
        
			  `,
        answer: ["T", "T", "F", "T"],
      },
    ],
  },

  3: {
    unit: "Unit 7",
    id: "WB10-U7-P61-E3",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page61/E3/Key/answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: `
        Read the  <span style='color:rgb(69, 62, 156);'>Reading Strategy</span>. For questions 1–4, choose the 
correct option (a–d).
        `,
        color: "black",
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
          color: "black",
          fontWeight: "bold",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 3px",
          borderColor: "gray",
        },
        limitSelect: 1,
        listWords: [
          "a <br/> b <br/> c <br/> d ",
          "a <br/> b <br/> c <br/> d ",
          "a <br/> b <br/> c <br/> d ",
          "a <br/> b <br/> c <br/> d ",
        ],
        answers: ["0-0", "1-12", "2-8", "3-8"],
        initialValue: [],
      },
      Layout: `
				<div style='background-color: rgb(228, 227, 241); margin-top: 20px; margin-left:100px; width:700px; border-radius: 15px; border: 5px dotted rgb(69, 62, 156)'>
					<div style='padding: 15px 25px 25px 25px;' >
						<div ><b style='color:rgb(69, 62, 156) ;'>Reading Strategy</b></div>
						<div>Do not assume that because a multiple-choice 
option contains words from the text, it is correct. 
Focus on meaning, not on individual words.</div>
						<div>Treat each option as a true or false task in order to 
find the option that is true.
</div>
					</div>
				</div>

        <div style='display:flex'>
          <b>1 &emsp;</b>
          <div>
            <div>When Sam's brother was twelve, he and his cousin</div>
            <div style='display:flex'>
              <div style='width:40px;'><input id='0' type='Circle' /></div>
              <div>
                did not enjoy their trip together.<br/>
                had a terrible holiday, but got over it quickly.<br/>
                went camping near the River Thames.<br/>
                went canoeing with his parents.<br/>
              </div>
            </div>
          </div>
        </div>
        
        <div style='display:flex'>
          <b>2 &emsp;</b>
          <div>
            <div>On Sam's first holiday without his parents, he</div>
            <div style='display:flex'>
              <div style='width:40px;'><input id='1' type='Circle' /></div>
              <div>
                walked all the way to the Lake District.<br/>
                had to hitch-hike across some mountains.<br/>
                kept warm, but couldn't keep dry.<br/>
                ate less food than he wanted to eat.<br/>
              </div>
            </div>
          </div>
        </div>
 
        <div style='display:flex'>
          <b>3 &emsp;</b>
          <div>
            <div>Harriet</div>
            <div style='display:flex'>
              <div style='width:40px;'><input id='2' type='Circle' /></div>
              <div>
                went on holiday with six friends.<br/>
                has been on one holiday without her mum and dad.<br/>
                arranged accommodation before the holiday.<br/>
                had a miserable time from the start of the holiday.<br/>
              </div>
            </div>
          </div>
        </div>
 
        <div style='display:flex'>
          <b>4 &emsp;</b>
          <div>
            <div>The holiday taught Harriet</div>
            <div style='display:flex'>
              <div style='width:40px;'><input id='3' type='Circle' /></div>
              <div>
                that it's best to take a map with you when you go backpacking.<br/>
                never to go backpacking in Britain.<br/>
                why backpacking in Britain in March isn't popular.<br/>
                to check the weather forecast before a holiday.<br/>
              </div>
            </div>
          </div>
        </div>
 
      `,
    },
  },
};

export default json;
