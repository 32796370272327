import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';

const json = {
  1: {
    unit: 'Unit 8',
    id: 'FG10-U8-P66-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page66/E1/Key/answerKey.png',
    component: T6,
    inputSize: 170,
    textAlign: 'center',
    // isHiddenCheck: true,
    maxLength: 15,
    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '',
        title: `
            `,
        color: '#000',
      },
    ],
    questions: [
      {
        title: `
    <div style=" background-repeat: no-repeat; background-size: 1100px auto; padding: 30px 60px 20px 110px" >
              <span><strong style="color: rgb(179, 6, 51)">Revision:</strong> <strong>Student Book page 97</strong></span>
              <div style="margin-top: 20px">
                <h1 style="font-size: 25px; color: black"><span style="margin-right: 10px">1</span> Complete the sentences with the verbs below. Remember to use the correct form of the verb</h1>
              </div>
              <div style="color: rgb(198, 29, 136); font-weight: bold; margin-bottom: 30px">
                <span style= "margin-right: 50px">browse</span>
                <span style= "margin-right: 50px">come up with</span>
                <span style= "margin-right: 50px">get</span>
                <span style= "margin-right: 50px">lose</span>
                <span style= "margin-right: 50px">prescribe</span>
                <span style= "margin-right: 50px">run</span>
              </div>
              <div>
                <ol type="1" style="font-weight: bold">
                  <li><span style="font-weight: normal;">He # 10 kg during his trip to
                  Antarctica.
                  </span></li>
                  <li><span style="font-weight: normal;">Yesterday, my friend # a great idea
                  for how to raise money for our club.</span></li>
                  <li><span style="font-weight: normal;">My mum's got a new car and it # on biofuel.
                  </span></li>
                  <li><span style="font-weight: normal;">If you've got a smartphone, you can #  the internet wherever you are.</span></li>
                  <li><span style="font-weight: normal;">My sister always # a good deal
                  when she goes shopping for clothes.</span></li>
                  <li><span style="font-weight: normal;">Last month, a doctor # some
                  medicine for my headaches.</span></li>
                </ol>
              </div>
            </div>
            `,
        answer: ['lost', 'came up with', 'runs', 'browse', 'gets', 'prescribed'],
      },
    ],
  },
  2: {
    unit: 'Unit 8',
    id: 'FG10-U8-P66-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page66/E2/Key/answerKey.png',
    component: T6,
    inputSize: 170,
    textAlign: 'center',
    // isHiddenCheck: true,
    maxLength: 15,
    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '2',
        title: `
        Complete the definitions using the verbs below. 
            `,
        color: '#000',
      },
    ],
    questions: [
      {
        title: `
            <div >
              <div style="color: rgb(198, 29, 136); font-weight: bold; margin-bottom: 30px; display: grid; grid-template-columns: repeat(6, minmax(0, 1fr)); grid-gap: 20px; grid-auto-flow: row;">
                <span style= "margin-right: 10px">apologise</span>
                <span style= "margin-right: 10px">challenge</span>
                <span style= "margin-right: 10px">comfort</span>
                <span style= "margin-right: 10px">complain</span>
                <span style= "margin-right: 10px">encourage</span>
                <span style= "margin-right: 10px">enquire</span>
                <span style= "margin-right: 10px">entertain</span>
                <span style= "margin-right: 10px">inform</span>
                <span style= "margin-right: 10px">persuade</span>
                <span style= "margin-right: 10px">recommend</span>
                <span style= "margin-right: 10px">warn</span>
                <span style= "margin-right: 10px">welcome</span>
              </div>
              <div>
                <ol type="1" style="font-weight: bold">
                  <li><span style="font-weight: normal;">If you # somebody (about something),
                  you tell them about a danger.
                  </span></li>
                  <li><span style="font-weight: normal;">If you # to somebody (about something),
                  you tell them why you are unhappy about it.
                  </span></li>
                  <li><span style="font-weight: normal;">If you # (for something), you say that you
                  are sorry
                  </span></li>
                  <li><span style="font-weight: normal;">If you # about something, you ask
                  questions about it.
                  </span></li>
                  <li><span style="font-weight: normal;">If you # something (to somebody), you
                  say why it is good.
                  </span></li>
                  <li><span style="font-weight: normal;">If you # somebody (to do something),
                  you make them feel more confident about it.
                  </span></li>
                  <li><span style="font-weight: normal;">If you # somebody or something, you say
                  you are happy about their arrival.
                  </span></li>
                  <li><span style="font-weight: normal;">If you # somebody (about something),
                  you tell them facts about it.
                  </span></li>
                  <li><span style="font-weight: normal;">If you # somebody, you invite them to
                  compete with you.
                  </span></li>
                  <li><span style="font-weight: normal;">If you # somebody, you try to make them
                  feel better when they're sad.
                  </span></li>
                  <li><span style="font-weight: normal;">If you # somebody (to do something),
                  you say things which make them want to do it.
                  </span></li>
                  <li><span style="font-weight: normal;">If you # somebody, you keep them
                  amused and interested
                  </span></li>
                  
                </ol>
              </div>
            </div>
            `,
        answer: [
          'warn',
          'complain',
          'apologise',
          'enquire',
          'recommend',
          'encourage',
          'welcome',
          'inform',
          'challenge',
          'comfort',
          'persuade',
          'entertain',
        ],
      },
    ],
  },
  3: {
    unit: 'Unit 8',
    id: 'FG10-U8-P66-E3',
    audio: 'Audios/2.08.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page66/E3/Key/answerKey.png',
    component: T6,
    inputSize: 160,
    textAlign: 'center',
    // isHiddenCheck: true,
    maxLength: 20,
    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '3',
        title: `
        <headphone name="2.08"></headphone> Read the <span style="color: rgb(202, 15, 73)">Listening Strategy</span>. Then listen to
        four speakers. What are their intentions? Complete the
        sentences with the <i>-ing</i> form of verbs from exercise 2
            `,
        color: '#000',
      },
    ],
    questions: [
      {
        title: `
              <div style="display: flex; justify-content: center; align-items: center; flex-direction: column; width: 1100px; gap: 30px 0; margin-top: 20px">
                <div style="background-image: url('img/FriendsPlus/Page66/E3/1.jpg'); background-repeat: no-repeat; background-size: 700px auto; height: 300px;width:700px; padding: 20px 100px 30px 30px; border-radius: 16px">
                  <h2 style="font-size: 24px; color: rgb(202, 15, 73)">Listening Strategy</h2>
                  <span style="font-size: 21px">You will sometimes have to identify the intentions
                  of the speaker (e.g. to persuade, to warn, to inform,
                  etc.). Listen for phrases such as <i>I think you should, be
                  careful to, I'm going to tell you about …</i> that give you
                  a clue to the speaker's intentions</span>
                </div>
                <div>
                  <ol type="1" style="font-weight: bold">
                    <li><span style="font-weight: normal;">Speaker 1 # is a group of tourists</span></li>
                    <li><span style="font-weight: normal;">Speaker 2 # to a friend.</span></li>
                    <li><span style="font-weight: normal;">Speaker 3 # about a museum.</span></li>
                    <li><span style="font-weight: normal;">Speaker 4 # a friend.</span></li>
                  </ol>
                </div>
              </div>
            `,
        answer: ['warning', 'apologising', 'enquiring', 'encouraging'],
      },
    ],
  },
  4: {
    unit: 'Unit 8',
    id: 'FG10-U8-P66-E4',
    audio: 'Audios/2.09.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page66/E4/Key/answerKey.png',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '4',
        title: `
        <headphone name="2.09"></headphone> Read the questions below. Then listen to four
        speakers one at a time. Pause after each one and answer
        the relevant question.
            `,
        color: '#000',
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: 'none',
          borderRadius: '50%',
          borderColor: 'transparent',
          color: 'black',
          fontWeight: 'bold',
        },
        selectWordStyle: {
          padding: 5,
          border: 'solid 3px',
          borderColor: 'gray',
        },
        limitSelect: 1,
        listWords: ['a <br/> b <br/> c', 'a <br/> b <br/> c', 'a <br/> b <br/> c', 'a <br/> b <br/> c'],
        answers: ['1-4', '0-0', '2-0', '3-8'],
        initialValue: [],
      },
      Layout: `
        <div style='display:flex'>
          <b>1 &emsp;</b>
          <div>
            <div>The speaker’s main aim is to</div>
            <div style='display:flex'>
              <div style='width:33px;'><input id='0' type='Circle' /></div>
              <div>
               welcome people to a special event. <br/>
               complain about problems with a new car. <br/>
               enquire about a new model of car. <br/>
              </div>
            </div>
          </div>
        </div>
        <div style='display:flex'>
          <b>2 &emsp;</b>
          <div>
            <div>The speaker’s main aim is to</div>
            <div style='display:flex'>
              <div style='width:33px;'><input id='1' type='Circle' /></div>
              <div>
               inform somebody about a new laptop. <br/>
               recommend buying a new laptop. <br/>
               apologise for damaging somebody’s laptop. <br/>
              </div>
            </div>
          </div>
        </div>
        <div style='display:flex'>
          <b>3 &emsp;</b>
          <div>
            <div>The speaker’s main aim is to</div>
            <div style='display:flex'>
              <div style='width:33px;'><input id='2' type='Circle' /></div>
              <div>
               persuade people to invest money. <br/>
               challenge people to invent a better product. <br/>
               thank people for helping to develop a fantastic product. <br/>
              </div>
            </div>
          </div>
        </div>
        <div style='display:flex'>
          <b>4 &emsp;</b>
          <div>
            <div>The speaker’s main aim is to</div>
            <div style='display:flex'>
              <div style='width:33px;'><input id='3' type='Circle' /></div>
              <div>
               describe a machine to her students. <br/>
               warn her students to be careful. <br/>
               challenge her students to do better than her. <br/>
              </div>
            </div>
          </div>
        </div>
        
      `,
    },
  },
};

export default json;
