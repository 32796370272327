import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P6-E1',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page6/E1/Key/answerKey.png',
		titleQuestion: [
			{
				num: '1',
				title:
					' Look at the pictures and describe the people’s hair with the adjectives below. Make sure the adjectives are in the correct order.',
			},
		],
		stylesTextInput: { textAlign: 'center' },
		inputSize: 180,
		isHiddenCheck: true,
		questions: [
			{
				title: `
        <div style="color: rgb(144, 38, 143); display: inline-flex; font-weight: bold; gap: 30px; flex-wrap: wrap; width: 660px; line-height: 15px; margin: 10px">
                <div>curly</div>
                <div>dark</div>
                <div>fair</div>
                <div>long</div>
                <div>medium-length</div>
                <div>short</div>
                <div>straight</div>
                <div>wavy </div>
                <div>black</div>
              </div>
          <div style='margin: 10px 0 0 10px; font-size: 22px'>
            <div><img src='img/FriendsPlus/Page6/E1/1.jpg' /></div>
            <div><b>1&emsp;</b> Maria's got # # # hair.</div>
            <div><b>2&emsp;</b> Tom's got # # # hair.</div>
            <div><b>3&emsp;</b> Alice's got # # # hair.</div>
            <div><b>4&emsp;</b> Sean's got # # # hair.</div>
            <div><b>5&emsp;</b> Lara's got # # # hair</div>
            <div><b>6&emsp;</b> Alex's got # # # hair.</div>
         </div>
                
              `,
				answer: [
					'long',
					'straight',
					'black ',
					'short',
					'curly',
					'dark',
					'medium-length',
					'wavy',
					'fair ',
					'short',
					'straight',
					'dark',
					'long',
					'wavy',
					'dark ',
					'medium-length',
					'straight',
					'dark',
				],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P6-E2',
		component: T6,
		stylesTextInput: {
			padding: 0,
			fontSize: 22,
		},
		exerciseKey: 'img/FriendsPlus/Page6/E2/Key/answerKey.png',
		inputSize: 100,
		titleQuestion: [
			{
				num: '2',
				title: 'Match the words below with the descriptions.',
			},
		],
		questions: [
			{
				title: `
              <div style="color: rgb(144, 38, 143); display: inline-flex; font-weight: bold; gap: 30px; flex-wrap: wrap; width: 660px; line-height: 10px; margin: 15px">
                <div>boots</div>
                <div>dress</div>
                <div>coat</div>
                <div>gloves</div>
                <div>hoodie</div>
                <div>jeans</div>
                <div>socks</div>
                <div>T-shirt</div>
                <div>tie</div>
                <div>trainers</div>
              </div>
              <div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; ">I wear them in winter to keep my feet warm and dry.  #</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; ">I wear it over my other clothes when it's very cold outside. #</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; ">I wear them on my feet, inside my shoes.  #</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; ">Men often wear one around their necks when they go to work.  #</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; ">I wear these on my feet when I run or exercise.  #</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; ">It's like a jacket. It's very soft and warm, and it has a part to cover my head.  #</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>7. </b></div><div style="margin-left: 30px; flex: 1; ">I wear these to cover my lower body. They are dark blue and very strong. #</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>8. </b></div><div style="margin-left: 30px; flex: 1; ">You can wear this short-sleeved top on its own or under a jumper. It sometimes has pictures or words on it.  #</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>9. </b></div><div style="margin-left: 30px; flex: 1; ">I wear these to cover my hands when the weather is cold.  #</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>10. </b></div><div style="margin-left: 30px; flex: 1; ">A woman wears this. It usually covers her from her shoulders to her knees. #</div></div>
              </div>
        `,
				answer: [
					'boots ',
					'coat ',
					'socks ',
					'tie ',
					'trainers ',
					'hoodie ',
					'jeans ',
					'T-shirt ',
					'gloves ',
					'dress',
				],
			},
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P6-E3',
		component: T6,
		inputSize: 1000,
		titleQuestion: [
			{
				num: '3',
				title:
					'Write short descriptions of the people. Describe their hair, facial hair and clothes.',
			},
		],
		isHiddenCheck: true,
		exerciseKey: 'img/FriendsPlus/Page6/E3/Key/answerKey.png',
		questions: [
			{
				title: `
        <div><img src='img/FriendsPlus/Page6/E3/1.jpg' /></div>
        <div style="margin-top: 30px;">
          <div><b>1&emsp;#</div>
          <div style="margin-top: 15px;"><b>2&emsp;#</div>
          <div style="margin-top: 15px;"><b>3&emsp;#</div>
          <div style="margin-top: 15px;"><b>4&emsp;#</div>
        </div>
        `,
				answer: [
					"He's got short, straight, dark hair. He's wearing a coat and tie.",
					"She's got shoulder-length, straight, fair hair. She's wearing a dress and gloves.",
					"He's got short, wavy, fair hair and a dark beard. He's wearing jeans, a T-shirt and a jacket.",
					"She's got short, wavy, dark hair. She's wearing dark trousers and a dark jacket.",
				],
			},
		],
	},
	4: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U-P6-E4',
		audio: '',
		video: '',
		component: T6,
		maxLength: 9,
		isHiddenCheck: true,
		stylesTextInput: {
			backgroundColor: 'transparent',
			border: 'none',
			padding: 0,
			fontSize: 20,
		},
		exerciseKey: 'img/FriendsPlus/Page6/E4/Key/answerKey.png',
		inputSize: 147,
		titleQuestion: [
			{
				num: '4',
				title:
					'Read the notices from a lost property website. Look at the photos and complete the descriptions.',
			},
		],
		questions: [
			{
				title: `
        <div style="color: rgb(144, 38, 143); display: flex; font-weight: bold; gap: 30px">
          <div>brown</div>
          <div>dark</div>
          <div>eyes</div>
          <div>jacket</div>
          <div>long</div>
          <div>moustache</div>
          <div>scarf</div>
          <div>shirt</div>
          <div>short</div>
          <div>straight</div>
          <div>tie</div>
          <div>wavy</div>
        </div>

        <div style="position: relative; margin: 10px">
            <div><img src='img/FriendsPlus/Page6/E4/1.jpg' style="max-width: 60%;" /></div>
            <div>
              <div style=" position: absolute; top: 112px; left: 325px; "><input id='0' /></div>
              <div style=" position: absolute; top: 112px; left: 452px; "><input id='1' /></div>
              <div style=" position: absolute; top: 142px; left: 250px; "><input id='2' /></div>
              <div style=" position: absolute; top: 172px; left: 250px; "><input id='3' /></div>
              <div style=" position: absolute; top: 170px; left: 446px; "><input id='4' /></div>
              <div style=" position: absolute; top: 400px; left: 100px; "><input id='5' /></div>
              <div style=" position: absolute; top: 398px; left: 234px; "><input id='6' /></div>
              <div style=" position: absolute; top: 428px; left: 54px; "><input id='7' /></div>
              <div style=" position: absolute; top: 428px; left: 289px; "><input id='8' /></div>
              <div style=" position: absolute; top: 457px; left: 256px; "><input id='9' /></div>
              <div style=" position: absolute; top: 486px; left: 213px; "><input id='10' /></div>
              <div style=" position: absolute; top: 513px; left: 59px; "><input id='11' /></div>
            </div>
          </div>
        `,
				answer: [
					'long ',
					'straight',
					'dark',
					'scarf',
					'jacket',
					'short',
					'wavy',
					'brown',
					'eyes',
					'moustache',
					'tie',
					'shirt',
				],
			},
		],
	},
};

export default json;
