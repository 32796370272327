import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 2",
    id: "WB10-U2-P19-E1",
    audio: "",
    video: "",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "1",
        title: `
        <b>
          Choose the correct tense to complete the sentences.
        </b>
        `,
      },
    ],
    exerciseKey: "/img/FriendsPlus/Page19/E1/Key/answerKey.png",
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        // initialWordStyle: { margin: '5px 0', border: 'solid 2px', borderColor: 'black', borderRadius: '50%', padding: '0 5px' },
        initialWordStyle: {
          margin: "5px 0",
          border: "none",
          borderColor: "transparent",
          borderRadius: "50%",
          fontWeight: "bold",
        },
        selectWordStyle: {
          border: "solid 2px",
          borderColor: "#00a8ec",
          margin: "-2px",
          padding: 5,
          fontWeight: "bold",
        },
        limitSelect: 1,
        listWords: [
          "didn't_rain / wasn't_raining",
          "did_you_do / were_you_doing",
          "put_on / was_putting_on",
          "left / was_leaving",
          "made / were_making",
          "didn't_believe / weren't_believing",
          "had / was_having",
        ],
        answers: ["0-4", "1-4", "2-0", "3-0", "4-4", "5-0", "6-4"],
        initialValue: [],
      },
      Layout: `
        <div>
          <div style="display: flex; gap: 20px;">
            <div style="width: 40px; font-weight: bold; text-align: right;">1</div>
            <div>Jake looked at the weather outside: it was grey, but it <input type="Circle" id="0">.</div>
          </div>
          <div style="margin-top: 7px; display: flex; gap: 20px;">
            <div style="width: 40px; font-weight: bold; text-align: right;">2</div>
            <div>
              You didnt answer when I phoned you this morning. <br />
              What <input type="Circle" id="1">?
            </div>
          </div>	
          <div style="margin-top: 7px; display: flex; gap: 20px;">
            <div style="width: 40px; font-weight: bold; text-align: right;">3</div>
            <div>Lucy switched off her computer, <input type="Circle" id="2"> and <input type="Circle" id="3"> the office.</div>
          </div>	
          <div style="margin-top: 7px; display: flex; gap: 20px;">
            <div style="width: 40px; font-weight: bold; text-align: right;">4</div>
            <div>She closed the window because some children <input type="Circle" id="4"> a lot of noise outside.</div>
          </div>	
          <div style="margin-top: 7px; display: flex; gap: 20px;">
            <div style="width: 40px; font-weight: bold; text-align: right;">5</div>
            <div>When I told my friends about the robbery, they <input type="Circle" id="5"> me.</div>
          </div>	
          <div style="margin-top: 7px; display: flex; gap: 20px;">
            <div style="width: 40px; font-weight: bold; text-align: right;">6</div>
            <div>She didn’t hear the doorbell because she <input type="Circle" id="6"> a shower.</div>
          </div>	
        </div>
      `,
    },
  },
  2: {
    unit: "Unit 2",
    id: "WB10-U2-P19-E2",
    exerciseKey: "/img/FriendsPlus/Page19/E2/Key/answerKey.png",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    inputSize: 190,
    stylesTextInput: {
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
      
      <b>
      Complete the sentences. Use the past simple form of one
      verb and the past continuous form of the other.
      </b>
      `,
      },
    ],
    questions: [
      {
        title: `
          <div>
            <div style="display: flex; gap: 20px;">
              <div style="width: 40px; font-weight: bold; text-align: right;">1</div>
              <div>I # (see) Henry as I # (cycle) to the sports centre.</div>
            </div>
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="width: 40px; font-weight: bold; text-align: right;">2</div>
              <div>While we # (prepare) the picnic, it # (start) to rain.</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="width: 40px; font-weight: bold; text-align: right;">3</div>
              <div>All my friends # (leave) the party when I # (arrive).</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="width: 40px; font-weight: bold; text-align: right;">4</div>
              <div>As we # (walk) by the river, we # (hear) a shout.</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="width: 40px; font-weight: bold; text-align: right;">5</div>
              <div>We # (not eat) very much while we # (stay) in that hotel.</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="width: 40px; font-weight: bold; text-align: right;">6</div>
              <div>I # (drink) my dad's coffee while he # (not look).</div>
            </div>	
          </div>
        `,
        answer: [
          `saw`,
          `was cycling`,
          `were preparing`,
          `started`,
          `were leaving`,
          `arrived`,
          `were walking`,
          `heard`,
          `didn't eat`,
          `were staying`,
          `drank`,
          `wasn't looking`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "WB10-U2-P19-E3",
    exerciseKey: "/img/FriendsPlus/Page19/E3/Key/answerKey.png",
    audio: "",
    video: "",
    component: T6,
    // inputSize: 140,
    // maxLength: 14,
    stylesTextInput: {
      borderBottom: "none",
      backgroundColor: "transparent",
      fontSize: 18,
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "3",
        title: `
      
      <b>
        Complete the text with the past simple or past continuous
        form of the verbs in brackets.
      </b>
      `,
      },
    ],
    questions: [
      {
        title: `
      
      <div style='display: flex;'>
            <div style='position: relative;'>
              <div><img src="/img/FriendsPlus/Page19/E3/E3.png"></div>
              <div style='position: absolute; top: 129px; left: 298px;'>#</div>
              <div style='position: absolute; top: 179px; left: 260px;'>#</div>
              <div style='position: absolute; top: 226px; left: 210px;'>#</div>
              <div style='position: absolute; top: 252px; left: 200px;'>#</div>
              <div style='position: absolute; top: 299px; left: 190px;'>#</div>
              <div style='position: absolute; top: 324px; left: 205px;'>#</div>
              <div style='position: absolute; top: 349px; left: 235px;'>#</div>
              <div style='position: absolute; top: 373px; left: 235px;'>#</div>
              <div style='position: absolute; top: 395px; left: 175px;'>#</div>
              <div style='position: absolute; top: 81px; left: 715px;'>#</div>
              <div style='position: absolute; top: 106px; left: 620px;'>#</div>
              <div style='position: absolute; top: 129px; left: 675px;'>#</div>
              <div style='position: absolute; top: 154px; left: 645px;'>#</div>
              <div style='position: absolute; top: 178px; left: 815px;'>#</div>
              <div style='position: absolute; top: 202px; left: 775px;'>#</div>
              <div style='position: absolute; top: 225px; left: 845px;'>#</div>
              <div style='position: absolute; top: 250px; left: 850px;'>#</div>
              <div style='position: absolute; top: 275px; left: 830px;'>#</div>
              <div style='position: absolute; top: 325px; left: 630px;'>#</div>
              <div style='position: absolute; top: 325px; left: 855px;'>#</div>
            </div>
      </div>
      `,
        answer: [
          "made",
          "was fishing",
          `didn't see`,
          "felt",
          "were playing",
          "turned",
          "was",
          "realised",
          "was happening",
          "was carrying",
          "used ",
          "got",
          "looked",
          "were bleeding",
          "cleaned",
          "went",
          "were enjoying",
          "gave",
          "was bleeding",
          "went",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "WB10-U2-P19-E4",
    exerciseKey: "/img/FriendsPlus/Page19/E4/Key/answerKey.png",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    inputSize: 470,
    titleQuestion: [
      {
        num: "4",
        title: `
          <b>
            Complete the second sentence so that it has a similar
            meaning to the first.
          </b>
        `,
      },
    ],
    questions: [
      {
        title: `
          <div>
            <div style="display: flex; gap: 20px;">
              <div style="width: 40px; font-weight: bold; text-align: right;">1</div>
              <div>
                I hurt my finger while I was playing the guitar. <br />
                I was playing <span style="color: rgb(0,110,147);">the guitar when I hurt my finger.</span>
              </div>
            </div>
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="width: 40px; font-weight: bold; text-align: right;">2</div>
              <div>
                I was walking home when it started to rain.<br />
                While I #.
              </div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="width: 40px; font-weight: bold; text-align: right;">3</div>
              <div>
                She broke her leg while she was learning to ski.<br />
                She was learning #.
              </div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="width: 40px; font-weight: bold; text-align: right;">4</div>
              <div>
                He was making lunch when he suddenly felt ill.<br />
                As he #.
              </div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="width: 40px; font-weight: bold; text-align: right;">5</div>
              <div>
                She was telling me about her party when she started to laugh loudly.<br />
                She started <input id='4' width='620px' />.
              </div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="width: 40px; font-weight: bold; text-align: right;">6</div>
              <div>
                I lost my wallet while I was shopping.<br />
                While I #.
              </div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="width: 40px; font-weight: bold; text-align: right;">7</div>
              <div>
                As the band were playing their first song, all the lights went out.<br />
                The band were playing #.
              </div>
            </div>	
          </div>
        `,
        answer: [
          "was walking home, it started to rain",
          "to ski when she broke her leg",
          "was making lunch, he suddenly felt ill",
          "to laugh loudly when she was telling me about her party",
          "was shopping, I lost my wallet",
          "their first song when all the lights went out",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "WB10-U2-P19-E5",
    exerciseKey: "",
    audio: "",
    video: "",
    component: T6,
    inputSize: 600,
    maxLength: 400,
    hideBtnFooter: true,
    stylesTextInput: {
      // textAlign: 'center',
      fontWeight: "bold",
      //borderBottom:'none',
      // color: 'rgb(0,110,147)',
      backgroundColor: "transparent",
      fontSize: 23,
    },
    textareaStyle: { width: 600 },
    titleQuestion: [
      {
        num: "5",
        title: `
      
      <b>
      Complete the sentences with your own ideas. Use the past
      simple or past continuous.
      </b>
      `,
      },
    ],

    questions: [
      {
        title: `
      <div>
        <b>1 </b> <span>As I was getting off the bus</span>
        <span> <input type="text" width="600px"></span><br>
        <span> <input type="text" width="915px"></span>
      </div>

      <div style="margin-top: 20px;">
        <b>2</b> <span> We were trying to find our hotel when</span>
        <span> <input type="text" width="490px"></span><br>
        <span> <input type="text" width="912px"></span>
      </div>

      <div style="margin-top: 20px;">
        <b>3 </b> <span> An amazing thing happened as</span>
        <span> <input type="text" width="557px"></span><br>
        <span> <input type="text" width="915px"></span>
      </div>
      `,
        answer: [],
      },
    ],
  },
};

export default json;
