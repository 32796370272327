import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P11-E1',
		component: T6,
		stylesTextInput: {
			padding: 0,
			fontSize: 22,
			textAlign: 'center',
		},
		exerciseKey: 'img/FriendsPlus/Page11/E1/Key/answerKey.png',
		inputSize: 140,
		titleQuestion: [
			{
				num: '1',
				title:
					'Complete the sentences with the correct negative past simple form of the verbs in brackets.',
			},
		],
		isHiddenCheck: true,
		questions: [
			{
				title: `
              <div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1 </b></div><div style="margin-left: 30px; flex: 1; ">We # (get) to the hotel in time for dinner</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2 </b></div><div style="margin-left: 30px; flex: 1; ">Our team # (win) the last match of the season</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3 </b></div><div style="margin-left: 30px; flex: 1; ">It was a long film, but I # (feel) bored.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4 </b></div><div style="margin-left: 30px; flex: 1; ">They # (spend) all their money on holiday.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5 </b></div><div style="margin-left: 30px; flex: 1; ">My aunt # (be) at the family wedding last weekend</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6 </b></div><div style="margin-left: 30px; flex: 1; ">Our teacher # (give) us a lot of homework.</div></div>
              </div>
        `,
				answer: [
					"didn't get ",
					"didn't win ",
					"didn't feel ",
					"didn't spend ",
					"wasn't ",
					"didn't give",
				],
			},
		],
	},
	2: {
		unit: 'Unit 1',
		id: 'WB10-U1-P11-E2',
		component: T6,
		stylesTextInput: {
			padding: 0,
			fontSize: 22,
			textAlign: 'center',
		},
		exerciseKey: 'img/FriendsPlus/Page11/E2/Key/answerKey.png',
		inputSize: 140,
		titleQuestion: [
			{
				num: '2',
				title:
					'Complete the first part of each sentence with the correct past simple negative form.',
			},
		],
		questions: [
			{
				title: `
              <div style="text-align: center"><img style="width: 74%" src='img/FriendsPlus/Page11/E2/1.jpg' /></div>
              <div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; ">I <span style="color: rgb(0, 68, 98)"> didn't go </span>skiing last winter, I went snowboarding.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; ">Einstein # in 1965, he died in 1955.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; ">The holidays # yesterday, they began last week.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; ">My grandparents # born in Russia, they were born in Germany.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; ">I # ‘thirteen', I said ‘thirty'.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; ">We # the first half of the match, but we saw the second half.</div></div>
              </div>
        `,
				answer: [
					"didn't die",
					"didn't begin",
					"weren't born",
					"didn't say",
					"didn't see",
				],
			},
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P11-E3',
		component: T6,
		stylesTextInput: {
			padding: 0,
			fontSize: 22,
			textAlign: 'center',
		},
		exerciseKey: 'img/FriendsPlus/Page11/E3/Key/answerKey.png',
		inputSize: 140,
		titleQuestion: [
			{
				num: '3',
				title:
					'Make these sentences true for you. Use the past simple affirmative or negative form of the verbs in brackets.',
			},
		],
		isHiddenCheck: true,
		questions: [
			{
				title: `
              <div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1 </b></div><div style="margin-left: 30px; flex: 1; ">I # (can) swim when I was five.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2 </b></div><div style="margin-left: 30px; flex: 1; ">Last weekend, I # (do) a lot of homework.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3 </b></div><div style="margin-left: 30px; flex: 1; ">Five years ago, I # (be) a student at this school.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4 </b></div><div style="margin-left: 30px; flex: 1; ">Ten years ago, I # (live) in a different town.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5 </b></div><div style="margin-left: 30px; flex: 1; ">I # (make) my own breakfast this morning.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6 </b></div><div style="margin-left: 30px; flex: 1; ">I # (choose) the clothes I'm wearing now.</div></div>
              </div>
        `,
				answer: [
					"could / couldn't",
					"did / didn't do",
					"was / wasn't",
					"lived / didn't live",
					"made / didn't make",
					"chose / didn't choose",
				],
			},
		],
	},
	4: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P11-E4',
		audio: '',
		video: '',
		component: T6,
		maxLength: 9,
		isHiddenCheck: true,
		textAlign: 'center',
		stylesTextInput: {
			backgroundColor: 'transparent',
			borderBottom: 'dotted 1px',
			padding: 0,
			fontSize: 22,
		},
		exerciseKey: 'img/FriendsPlus/Page11/E4/Key/answerKey.png',
		inputSize: 120,
		titleQuestion: [
			{
				num: '4',
				title: 'Complete the dialogue with the question words below.',
			},
		],
		checkUppercase: true,
		questions: [
			{
				title: `
        <div style="color: rgb(246, 140, 31); display: flex; font-weight: bold; gap: 30px">
          <div>how often</div>
          <div>what</div>
          <div>when</div>
          <div>where</div>
          <div>which</div>
          <div>who</div>
          <div>why</div>
        </div>
        <div>
          <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>Mia</b></div> <div style="flex: 9">Hi, Henry. <sup>1</sup> # did you do at the weekend?</div></div>
          <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>Henry</b></div> <div style="flex: 9">I went to the beach on Saturday</div></div>
          <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>Mia</b></div> <div style="flex: 9">That's nice! <sup>2</sup> # did you go with</div></div>
          <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>Henry</b></div> <div style="flex: 9">Matt and Alex.</div></div>
          <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>Mia</b></div> <div style="flex: 9">Alex West? <sup>3</sup> # do you see him?</div></div>
          <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>Henry</b></div> <div style="flex: 9">Only two or three times a year. He doesn't live near here any more.</div></div>
          <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>Mia</b></div> <div style="flex: 9"><sup>4</sup> # does he live now?</div></div>
          <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>Henry</b></div> <div style="flex: 9">In London.</div></div>
          <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>Mia</b></div> <div style="flex: 9">Does he? <sup>5</sup> # part of London?</div></div>
          <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>Henry</b></div> <div style="flex: 9">I'm not sure. North London, I think.</div></div>
          <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>Mia</b></div> <div style="flex: 9"><sup>6</sup> # did he move?</div></div>
          <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>Henry</b></div> <div style="flex: 9">I think his mum got a new job. Anyway, <sup>7</sup> # did you last see him?</div></div>
          <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>Mia</b></div> <div style="flex: 9">Oh, about two years ago.</div></div>
        </div>
        `,
				answer: ['What', 'Who', 'How often', 'Where', 'Which', 'Why', 'when'],
			},
		],
	},
	5: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P11-E5',
		component: T6,
		textareaStyle: { width: 390 },
		exerciseKey: 'img/FriendsPlus/Page11/E5/Key/answerKey.png',
		inputSize: 700,
		titleQuestion: [
			{
				num: '5',
				title:
					'Put the words in order to make questions. Then write answers that are true for you.',
			},
		],
		isHiddenCheck: true,
		questions: [
			{
				title: `
          <div>
            <div style="display: flex">
              <div style="display: inline-block; width: 40px; text-align: right;"><strong>1</strong></div>
              <div style="margin-left: 30px; flex: 1; display: flex; flex-direction: column;">
                <div>homework / how much / last / did / you / night / do / ?</div>
                <div style="margin-top: 8px;">#</div>
                <div style="display: flex;">
                  <div style="margin-top: 15px;"><i>The answer that is true for you:</i></div>
                  <div style="margin-top: 8px;"><textarea id="4" rows="1"></textarea></div>
                </div>
              </div>
            </div>
            <div style="display: flex">
              <div style="display: inline-block; width: 40px; text-align: right;"><strong>2</strong></div>
              <div style="margin-left: 30px; flex: 1; display: flex; flex-direction: column;">
                <div>with / travel / did / to / school / you / who / today / ?</div>
                <div style="margin-top: 8px;">#</div>
                <div style="display: flex;">
                  <div style="margin-top: 15px;"><i>The answer that is true for you:</i></div>
                  <div style="margin-top: 8px;"><textarea id="5" rows="1"></textarea></div>
                </div>
              </div>
            </div>
            <div style="display: flex">
              <div style="display: inline-block; width: 40px; text-align: right;"><strong>3</strong></div>
              <div style="margin-left: 30px; flex: 1; display: flex; flex-direction: column;">
                <div>best / meet / where / you / did / friend / your / ?</div>
                <div style="margin-top: 8px;">#</div>
                <div style="display: flex;">
                  <div style="margin-top: 15px;"><i>The answer that is true for you:</i></div>
                  <div style="margin-top: 8px;"><textarea id="6" rows="1"></textarea></div>
                </div>
              </div>
            </div>
            <div style="display: flex">
              <div style="display: inline-block; width: 40px; text-align: right;"><strong>4</strong></div>
              <div style="margin-left: 30px; flex: 1; display: flex; flex-direction: column;">
                <div>first / teacher / who / your / was / English / ?</div>
                <div style="margin-top: 8px;">#</div>
                <div style="display: flex;">
                  <div style="margin-top: 15px;"><i>The answer that is true for you:</i></div>
                  <div style="margin-top: 8px;"><textarea id="7" rows="1"></textarea></div>
                </div>
              </div>
            </div>
          </div>
        `,
				answer: [
					'How much homework did you do last night?',
					'Who did you travel to school with today?',
					'Where did you meet your best friend?',
					'Who was  your first English teacher?',
				],
			},
		],
	},
	6: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P11-E6',
		audio: '',
		video: '',
		component: T6,
		// maxLength: 1,
		isHiddenCheck: true,
		stylesTextInput: {
			fontSize: 22,
			borderBottom: 'solid 1px',
		},
		titleQuestion: [
			{
				num: '6',
				title:
					'Read the sentences. Then write questions for the given answers.',
			},
		],
		exerciseKey: 'img/FriendsPlus/Page11/E6/Key/answerKey.png',
		inputSize: 500,
		questionImage: [],
		questions: [
			{
				title: `
        <div style="display: flex; margin-block: 30px; width: 110%">
            <div style="flex: 1"><b>1</b></div>
            <div style="flex: 20">
                <div>Jack gave his old bike to his sister.</div>
                <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>Q:</b></div> <div style="flex: 15; color: rgb(0, 68, 98)">What did Jack give his sister?</div></div>
                <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>A:</b></div> <div style="flex: 15"> His old bike.</div></div>
            </div>
        </div>
        <div style="display: flex; margin-block: 30px; width: 110%">
            <div style="flex: 1"><b>2</b></div>
            <div style="flex: 20">
                <div>Sam had dinner early because he was hungry.</div>
                <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>Q:</b></div> <div style="flex: 15"> #</div></div>
                <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>A:</b></div> <div style="flex: 15">He was hungry.</div></div>
            </div>
        </div>
        <div style="display: flex; margin-block: 30px; width: 110%">
            <div style="flex: 1"><b>3</b></div>
            <div style="flex: 20">
                <div>Mason and Tyler went to London together. </div>
                <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>Q:</b></div> <div style="flex: 15">#</div></div>
                <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>A:</b></div> <div style="flex: 15">He went with Tyler.</div></div>
            </div>
        </div>
        <div style="display: flex; margin-block: 30px; width: 110%">
            <div style="flex: 1"><b>4</b></div>
            <div style="flex: 20">
                <div>Grace listened to three Lady Gaga CDs. </div>
                <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>Q:</b></div> <div style="flex: 15"> #</div></div>
                <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>A:</b></div> <div style="flex: 15">Three</div></div>
            </div>
        </div>
        `,
				answer: [
					'Why did Sam have dinner early?',
					'Who did Mason go to London with?',
					'How many Lady Gaga CDs did Grace listen to?',
				],
			},
		],
	},
};

export default json;
