import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

const json = {
  1: {
    unit: 'Unit 5',
    id: 'WB10-U5-P47-E1',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '1',
        title: 'Put elements 1– 7 in the correct places (A– G) in the letter',
        color: '#000000',
      },
    ],
    stylesTextInput: {
      textAlign: 'center',
      fontSize: 20,
      width: 36,
      border: '1px solid',
      borderBottom: '1px solid',
      borderRadius: 5,
      backgroundColor: 'transparent',
    },
    maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page47/E1/Key/answerKey.png',
    isHiddenCheck: true,
    questions: [
      // Row
      {
        title: /*html */ `
          <div style="display: flex;align-items: stretch; column-gap: 10px ">
            <div >
              <img src="img/FriendsPlus/Page47/E1/1.png" style="width: 500px; height: 600px;object-fit: contain;">
              
       
         
            </div>
              <div>
                <div>
                  <input margin-right="20px"/>-
                  <strong style="margin: 0 20px">1</strong>
                  <span>the email address the email is going to</span>
                </div>
                <div>
                  <input margin-right="20px"/>- 
                  <strong style="margin: 0 20px">2</strong>
                  <span>the writer's name</span>
                </div>
                <div>
                  <input margin-right="20px"/>- 
                  <strong style="margin: 0 20px">3</strong>
                  <span>the writer's address</span>
                </div>
                <div>
                  <input margin-right="20px"/>- 
                  <strong style="margin: 0 20px">4</strong>
                  <span>the date</span>
                </div>
                <div>
                  <input margin-right="20px"/>- 
                  <strong style="margin: 0 20px">5</strong>
                  <span>the writer's signature</span>
                </div>
                <div>
                  <input margin-right="20px"/>- 
                  <strong style="margin: 0 20px">6</strong>
                  <span>the person the letter is going to</span>
                </div>
                <div>
                  <input margin-right="20px"/>- 
                  <strong style="margin: 0 20px">7</strong>
                  <span>the subject line</span>
                </div>
              </div>
          </div>
        `,
        answer: ['A', 'F', 'C', 'D', 'G', 'E', 'B'],
      },
    ],
  },
  2: {
    unit: 'Unit 5',
    id: 'WB10-U5-P47-E2',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '2',
        title: 'Complete gaps 1-5 in the letter with these phrases',
        color: '#000000',
      },
    ],
    stylesTextInput: {
      textAlign: 'center',
      fontSize: 20,
      width: 36,
      border: '1px solid',
      borderBottom: '1px solid',
      borderRadius: 5,
      backgroundColor: 'transparent',
    },
    maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page47/E2/Key/answerKey.png',
    isHiddenCheck: true,
    questions: [
      // Row
      {
        title: /*html */ `
          <div style="display: flex;align-items: stretch; column-gap: 10px ">
            <div >
              <img src="img/FriendsPlus/Page47/E1/1.png" style="width: 500px; height: 600px;object-fit: contain;">
              
       
         
            </div>
              <div>
                <div>
                  <input margin-right="20px"/>-
                  <strong style="margin: 0 20px">a</strong>
                  <span>have experience</span>
                </div>
                <div>
                  <input margin-right="20px"/>- 
                  <strong style="margin: 0 20px">b</strong>
                  <span>sincerely</span>
                </div>
                <div>
                  <input margin-right="20px"/>- 
                  <strong style="margin: 0 20px">c</strong>
                  <span>am emailing to apply for</span>
                </div>
                <div>
                  <input margin-right="20px"/>- 
                  <strong style="margin: 0 20px">d</strong>
                  <span>saw your advertisement</span>
                </div>
                <div>
                  <input margin-right="20px"/>- 
                  <strong style="margin: 0 20px">e</strong>
                  <span>believe I would</span>
                </div>
                
              </div>
          </div>
        `,
        answer: ['3', '5', '2', '1', '4'],
      },
    ],
  },
  3: {
    unit: 'Unit 5',
    id: 'WB10-U5-P47-E3',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '3',
        title: 'Complete the summary of the email.',
        color: '#000000',
      },
    ],
    stylesTextInput: {
      textAlign: 'center',
      fontSize: 20,
      width: 200,
      // border: '1px solid',
      // borderRadius: 5,
      borderBottom: '1px solid',

      backgroundColor: 'transparent',
    },
    // maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page47/E3/Key/answerKey.png',
    isHiddenCheck: true,
    questions: [
      // Row
      {
        title: /*html */ `
          <div style="max-width: 700px">
            <span> The writer is applying for the voluntary role of
            <input /> He saw the advert
            <input /> He thinks that his
            <input /> will be useful for the job. He has experience of playing
            <input /> He thinks he is
            <input /> and
            <input /> He can
            <input /> every week and is available for
            <input />
          </div>
        `,
        answer: ['netball team leader ', 'in the leisure centre', 'experience', 'netball', 'sociable', 'responsible', 'train', 'Saturday games'],
      },
    ],
  },
  4: {
    unit: 'Unit 5',
    id: 'WB10-U5-P47-E4',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '4',
        title:
          'Read the <span style="color: rgb(39 131 197);">Writing Strategy</span> above and the volunteering advertisement below. Write an email applying for the volunteering role. Follow the paragraph plan below.',
        color: '#000000',
      },
    ],
    stylesTextInput: {
      textAlign: 'center',
      fontSize: 20,
      width: 40,
      height: 40,
      // border: '2px solid',
      borderBottom: 'none',

      backgroundColor: 'transparent',
    },
    maxLength: 1,
    exerciseKey: '',
    isHiddenCheck: true,
    hideBtnFooter: true,
    questions: [
      // Row
      {
        title: /*html */ `
        <div style="display: flex; column-gap: 20px">
        <div style="width: 500px; background-color:rgb(232 238 248); border: 3px dotted rgb(39 131 197); padding: 10px; border-radius: 10px ">
            <h3 style="font-size: 30px; font-weight: 700; color: rgb(39 131 197)">Writing Strategy</h3>
            <p style="font-size: 18px">When you write a formal letter:</p>
            <ul style="font-size: 18px">
              <li>Start with: Dear (Mrs White), if you know the name of the person, or Dear Sir or Madam, if you do not.</li>
              <li>Include a subject line at the top of the email.</li>
              <li>Write in paragraphs. One-sentence paragraphs are fine for opening or closing an application letter</li>
              <li>Do not use colloquial language or short forms (I'm, there's, etc.).</li>
              <li>Finish with Yours sincerely, if you used the person's name at the start, or Yours faithfully, if you did not.</li>
            </ul>
        </div>
        <div>
          <img src="img/FriendsPlus/Page47/E4/1.png" style="width: 500px" />

          <div >
            <div>
              <strong>Paragraph plan</strong>
            </div>
            <div style="margin-left: 20px; display: flex">
              <strong style="margin-right: 20px">A</strong>
              <span>what the volunteering role is and how you found out about it</span>
            </div>
            <div style="margin-left: 20px; display: flex">
              <strong style="margin-right: 20px">B</strong>
              <span>why you are interested in the role</span>
            </div>
            <div style="margin-left: 20px; display: flex">
              <strong style="margin-right: 20px">C</strong>
              <span>why you are the right person for the role</span>
            </div>
            <div style="margin-left: 20px; display: flex">
              <strong style="margin-right: 20px">D</strong>
              <span>which days you can attend </span>
            </div>
            <div style="margin-left: 20px; display: flex">
              <strong style="margin-right: 20px">E</strong>
              <span>what you would like to happen next</span>
            </div>
          </div>
        </div>
        
        </div>
        <div style="position: relative;">
          <img src="img/FriendsPlus/Page47/E4/2.png" />
          <div style="position: absolute; top: calc(50% + 18px ); left: calc(10%  - 30px)">#</div>
          <div style="position: absolute; top: calc(50% + 70px ); left: calc(10%  - 30px)">#</div>
          <div style="position: absolute; top: calc(50% + 120px ); left: calc(10%  - 30px)">#</div>
        </div>
        `,
        answer: [],
      },
    ],
  },
}

export default json
