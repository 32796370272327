import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
	1: {
		unit: 'Unit 6',
		id: 'WB10-U6-P54-E1',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '',
				title: '',
				color: ""
			}
		],
		stylesTextInput: {
			fontSize: 20,
			width: 100,
			textAlign: 'center',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		maxLength: 1,
		exerciseKey: 'img/FriendsPlus/Page54/E1/Key/answerKey.png',
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */`
					<div style="position: relative">
						<img src="img/FriendsPlus/Page54/E1/1.png" style="width: 700px"/>
						<div style="position: absolute; top: 74%; left: 40px; width: 650px; padding: 5px 20px;">
							<div style="display: flex;justify-content: space-between; ">
								<div>
									<div style="font-size: 22px">
										<strong>1</strong>
										<span style="margin-left: 20px">canteen</span>
										<input />
									</div>
									<div style="font-size: 22px">
										<strong>2</strong>
										<span style="margin-left: 20px">classroom</span>
										<input />
									</div>
									<div style="font-size: 22px">
										<strong>3</strong>
										<span style="margin-left: 20px">gym</span>
										<input />
									</div>
									<div style="font-size: 22px">
										<strong>4</strong>
										<span style="margin-left: 20px">language lab</span>
										<input />
									</div>
								</div>
								<div>
									<div style="font-size: 22px">
										<strong>5</strong>
										<span style="margin-left: 20px">library</span>
										<input />
									</div>
									<div style="font-size: 22px">
										<strong>6</strong>
										<span style="margin-left: 20px">playing field</span>
										<input />
									</div>
									<div style="font-size: 22px">
										<strong>7</strong>
										<span style="margin-left: 20px">science lab</span>
										<input />
									</div>
									<div style="font-size: 22px">
										<strong>8</strong>
										<span style="margin-left: 20px">toilets</span>
										<input />
									</div>
								</div>
							</div>
						</div>
					</div>
				`,
				answer: [
					"C",
					"E",
					"D",
					"F",
					"B",
					"A",
					"G",
					"H",
				],
			},
		],
	},
	2: {
		unit: 'Unit 6',
		id: 'WB10-U6-P54-E2',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '2',
				title: 'Match the phrases below with categories A–D.',
				color: "#000000"
			}
		],
		stylesTextInput: {
			fontSize: 18,
			width: 34,
			textAlign: 'center',
			border: '1px solid',
			borderRadius: 5,
			backgroundColor: 'transparent',
		},
		maxLength: 1,
		exerciseKey: 'img/FriendsPlus/Page54/E2/Key/answerKey.png',
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */`
					<div>
						<strong>A</strong>
						<span style="margin-left: 20px">Structuring your speech</span>
					</div>
					<div>
						<strong>B</strong>
						<span style="margin-left: 20px">Ordering points or opinions</span>
					</div>
					<div>
						<strong>C</strong>
						<span style="margin-left: 20px">Justifying your opinions</span>
					</div>
					<div>
						<strong>D</strong>
						<span style="margin-left: 20px">Summing up your opinion</span>
					</div>
					<div style="margin-top: 20px">
						<div>
							All in all, ... <span style="border: 1px solid rgb(0 0 0 ); font-style: oblique; font-size: 23px; color: rgb(0 68 98); padding: 0 5px; border-radius: 5px">D</span>
						</div>
						<div>
							First,... Second,... Finally,... <input />
						</div>
						<div>
							First of all, we need to decide / examine / look at /
							ask ourselves... <input />
						</div>
						<div>
							I'd like to start by saying / looking at... <input />
						</div>
						<div>
							Just to summarise the main points... <input />
						</div>
						<div>
							Now I'd like to move on to... <input />
						</div>
						<div>
							Now let's look at / move on to (the question of)... <input />
						</div>
						<div>
							The main reason I feel this way is... <input />
						</div>
						<div>
							There are a number of reasons why I believe this. First,... <input />
						</div>
						<div>
							I'll begin with... <input />
						</div>
						<div>
							This leads to my next point. <input />
						</div>
						<div>
							The reason I say that is... <input />
						</div>
						<div>
							I'll tell you why I think that... <input />
						</div>
						<div>
							To sum up,...In conclusion,... <input />
						</div>
					</div>
				`,
				answer: [
					"B", "A", "A", "D", "A", "A", "C", "C", "A", "A", "C", "C", "D"
				],
			},
		],
	},
	3: {
		unit: 'Unit 6',
		id: 'WB10-U6-P54-E3',
		audio: 'Audios/1.22.mp3',
		video: '',
		component: T6,

		titleQuestion: [
			{
				num: '3',
				title: '<headphone name="1.22"></headphone>Listen to a student presenting her answer to the task below.',
				color: "#000000"
			}
		],
		stylesTextInput: {
			fontSize: 18,
			width: 34,
			textAlign: 'center',
			border: '1px solid',
			borderRadius: 5,
			backgroundColor: 'transparent',
		},
		maxLength: 1,
		exerciseKey: 'img/FriendsPlus/Page54/E2/Key/answerKey.png',
		isHiddenCheck: true,
		hideBtnFooter: true,
		questions: [
			{
				title: /*html */`
					<div style="padding: 10px; background-color: rgb(209 222 241);">
						<strong style="font-size: 20px">The government does not spend enough money on schools.
						Do you agree? Why? / Why not?</strong>
					</div>
					
				`,
				answer: [],
			},
		],
	},
	4: {
		unit: 'Unit 6',
		id: 'WB10-U6-P54-E4',
		audio: 'Audios/1.22.mp3',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '4',
				title: '<headphone name="1.22"></headphone>Read the <span style="color: rgb(0 147 69)">Speaking Strategy.</span> Then listen again. Which of the phrases in exercise 2 does the student use?',
				color: "#000000"
			}
		],
		stylesTextInput: {
			fontSize: 20,
			width: 400,
			textAlign: 'left',
			borderBottom: '1px solid',
			backgroundColor: 'transparent',
		},
		// maxLength: 1,
		exerciseKey: 'img/FriendsPlus/Page54/E4/Key/answerKey.png',
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */`
			
					<div style="border: 5px dotted rgb(0 147 69);margin-top: 20px; ;border-radius: 10px; background-color: rgb(228 239 228); padding: 10px 20px; margin-bottom: 20px">
						<strong style="color:rgb(0 147 69); ">Speaking Strategy</strong>
						<span style="font-size: 20px">When you are giving a short presentation, use the phrases to
						give opinions and justify them, and to structure your speech.</span>
					</div>
					<div style="display: flex; column-gap: 20px">
						<img src="img/FriendsPlus/Page54/E4/1.png" style="width: 500px" />
						<img src="img/FriendsPlus/Page54/E4/2.png" style="width: 500px" />
					</div>
					
					<div style="padding: 20px 40px;">
						<div>
							<input />
						</div>
						<div>
							<input />
						</div>
						<div>
							<input />
						</div>
						<div>
							<input />
						</div>
						<div>
							<input />
						</div>
					</div>
				`,
				answer: [
					"I'd like to start by looking at",
					"There are a number of reasons why I",
					"First of all",
					"The main reason I feel this way is",
					"In conclusion"
				],
			},
		],
	},
	5: {
		unit: 'Unit 6',
		id: 'WB10-U6-P54-E5',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '5',
				title: 'Now prepare to do the task below. Make notes.',
				color: "#000000"
			}
		],
		stylesTextInput: {
			fontSize: 20,
			textAlign: 'left',
			borderBottom: '1px solid',
			backgroundColor: 'transparent',
		},
		textareaStyle: { width: 500 },
		// maxLength: 1,
		exerciseKey: 'img/FriendsPlus/Page54/E2/Key/answerKey.png',
		isHiddenCheck: true,
		hideBtnFooter: true,
		questions: [
			{
				title: /*html */`
					<div style="background-color: rgb(209 222 241); padding: 10px 20px; font-size: 22px">
						<div>
							<strong>1</strong>
							<span style="margin-left: 20px">Describe one of the photos.</span>
						</div>
						<div>
							<strong>2</strong>
							<span style="margin-left: 20px">Compare it with the other.</span>
						</div>
						<div style="display: flex">
							<strong>3</strong>
							<span style="margin-left: 20px">‘Only schools with a lot of money have good
							extracurricular activities.' Do you agree? Why? / Why not?</span>
						</div>
					</div>
					<div style="margin-top: 50px; ">
						<div style="display: flex">
							<span style="width: 300px">Describe one photo </span>
							<textarea rows="3"></textarea>
						</div>
						<div style="display: flex">
							<span style="width: 300px">Compare it with the other </span>
							<textarea rows="3"></textarea>
						</div>
						<div style="display: flex">
							<span style="width: 300px">Compare it with the other </span>
							<textarea rows="5"></textarea>
						</div>
					</div>
				`,
				answer: [],
			},
		],
	},
}

export default json;