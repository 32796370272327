import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 2",
    id: "WB10-U2-P16-E1",
    exerciseKey: "/img/FriendsPlus/Page16/E1/Key/answerKey.png",
    audio: "",
    video: "",
    component: T6,
    inputSize: 120,
    isHiddenCheck: true,
    maxLength: 9,
    // checkDuplicated: true,
    stylesTextInput: {
      // textAlign: 'center',
      borderBottom: "none",
      background: "none",
      fontSize: 23,
    },
    textareaStyle: {},
    titleQuestion: [
      {
        num: "1",
        title: `
			    <b>Complete the labels</b>
			`,
      },
    ],
    questions: [
      {
        title: `
	        <div style='display: flex;'>
	            <div style='position: relative;'>
	              <div><img src="/img/FriendsPlus/Page16/E1/E1.png" ></div>
	              <div style='position: absolute; top: 60px; left: 122px;'>#</div>
	              <div style='position: absolute; top: 116px; left: 641px;'>#</div>
	              <div style='position: absolute; top: 225px; left: 281px;'>#</div>
	              <div style='position: absolute; top: 275px; left: 561px;'>#</div>
	              <div style='position: absolute; top: 419px; left: 168px;'>#</div>
	              <div style='position: absolute; top: 630px; left: 145px;'>#</div>
	              <div style='position: absolute; top: 571px; left: 507px;'>#</div>
	              <div style='position: absolute; top: 745px; left: 240px;'>#</div>
	              <div style='position: absolute; top: 779px; left: 682px;'>#</div>
	              <div style='position: absolute; top: 945px; left: 226px;'>#</div>
	              <div style='position: absolute; top: 913px; left: 554px;'>#</div>
	              <div style='position: absolute; top: 1140px; left: 462px;'>#</div>
	              <div style='position: absolute; top: 1254px; left: 248px;'>#</div>
	              <div style='position: absolute; top: 1355px; left: 476px;'>#</div>
	              <div style='position: absolute; top: 1455px; left: 265px;'>#</div>
	          </div>
	        </div>
			`,
        answer: [
          "liff",
          "tream",
          "ave",
          "aterfall",
          "cean",
          "orest",
          "ountain",
          "ake",
          "ill",
          "iver",
          "hore",
          "olcano",
          "esert",
          "alley",
          "ocks",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "WB10-U2-P16-E2",
    audio: "",
    video: "",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "2",
        title: `
			<b>
				Put the letters in order to make adjectives that describe
				landscapes. Then circle the landscape feature that can go
				with each adjective. Sometimes both are possible.
			</b>
        `,
      },
    ],
    exerciseKey: "/img/FriendsPlus/Page16/E2/Key/answerKey.png",
    question: {
      Write: {
        inputStyle: { textAlign: "center" },
        answers: [
          "tall",
          "icy",
          "narrow",
          "dark",
          "shallow",
          "low",
          "rocky",
          "deep",
          "wide",
          "steep",
        ],
        initialValue: [],
      },
      Circle: {
        // initialWordStyle: { margin: '5px 0', border: 'solid 2px', borderColor: 'black', borderRadius: '50%', padding: '0 5px' },
        initialWordStyle: {
          margin: "5px 0",
          border: "none",
          borderColor: "transparent",
          borderRadius: "50%",
        },
        selectWordStyle: {
          border: "solid 2px",
          borderColor: "#00a8ec",
          margin: "-2px",
          padding: 5,
        },
        limitSelect: 2,
        listWords: [
          "stream / desert",
          "ocean / river",
          "cave / shore",
          "lake / valley",
          "ocean / waterfall",
          "shore / forest",
          "ocean / waterfall",
          "river / hill",
          "volcano / waterfall",
        ],
        answers: [
          "0-0",
          "1-4",
          "2-0",
          "3-0",
          "4-4",
          "5-0",
          "6-0",
          "7-0",
          "8-0",
          "8-4",
        ],
        initialValue: [],
      },
      Layout: `
	  <div>
      <div style="background-color: rgb(146,39,143); color: white; margin-bottom:1px; ">
       <span style="padding: 8px"><b>Landscape features</b></span>
      </div>
      <table style="border-collapse: collapse;">
      <thead>
        <tr style="background-color: rgb(146,39,143); color: white;">
          <th style="border: 1px solid;padding: 8px">Adjectives</th>
          <th style="border: 1px solid ; padding: 8px">Nouns </th>
        </tr>
      </thead>
      <tbody>
      <tr>
          <td style="border: 1px solid rgb(146,39,143); padding: 8px;">
            <span><b>1</b> <span> l a l t </span></span>
            <span style="float:right; margin-left: 40px;"> <input type="text" width="100px" id="0"></span>
          </td>
          <td style="border: 1px solid rgb(146,39,143); padding: 8px;">
            <span >river / </span>
            <span style="border: 1px solid;border-radius: 50%;padding: 5px;">mountain</span>
          </td>   
        </tr>
        <tr>
        <td style="border: 1px solid rgb(146,39,143); padding: 8px;">
          <span><b>2</b> <span> c i y </span></span>
          <span style="float:right; margin-left: 40px;"> <input type="text" width="100px" id="1"></span>
        </td>
        <td style="border: 1px solid rgb(146,39,143); padding: 8px;">
          <span ><input type="Circle" id="0"></span>
        </td>   
      </tr>
      <tr>
      <td style="border: 1px solid rgb(146,39,143); padding: 8px;">
        <span><b>3</b> <span> r o w r a n  </span></span>
        <span style="float:right; margin-left: 40px;"> <input type="text" width="100px" id="2"></span>
      </td>
      <td style="border: 1px solid rgb(146,39,143); padding: 8px;">
        <span ><input type="Circle" id="1"></span>
      </td>   
    </tr>
    <tr>
      <td style="border: 1px solid rgb(146,39,143); padding: 8px;">
        <span><b>4</b> <span> k a d r  </span></span>
        <span style="float:right; margin-left: 40px;"> <input type="text" width="100px" id="3"></span>
      </td>
      <td style="border: 1px solid rgb(146,39,143); padding: 8px;">
        <span ><input type="Circle" id="2"></span>
      </td>   
    </tr>
    <tr>
      <td style="border: 1px solid rgb(146,39,143); padding: 8px;">
        <span><b>5</b> <span> w h o l s a l   </span></span>
        <span style="float:right; margin-left: 40px;"> <input type="text" width="100px" id="4"></span>
      </td>
      <td style="border: 1px solid rgb(146,39,143); padding: 8px;">
        <span ><input type="Circle" id="3"></span>
      </td>   
    </tr>
    <tr>
      <td style="border: 1px solid rgb(146,39,143); padding: 8px;">
        <span><b>6</b> <span>  o w l   </span></span>
        <span style="float:right; margin-left: 40px;"> <input type="text" width="100px" id="5"></span>
      </td>
      <td style="border: 1px solid rgb(146,39,143); padding: 8px;">
        <span ><input type="Circle" id="4"></span>
      </td>   
    </tr>
    <tr>
    <td style="border: 1px solid rgb(146,39,143); padding: 8px;">
      <span><b>7</b> <span> c y k o r  </span></span>
      <span style="float:right; margin-left: 40px;"> <input type="text" width="100px" id="6"></span>
    </td>
    <td style="border: 1px solid rgb(146,39,143); padding: 8px;">
      <span ><input type="Circle" id="5"></span>
    </td>   
  </tr>

  <tr>
  <td style="border: 1px solid rgb(146,39,143); padding: 8px;">
    <span><b>8</b> <span> e d p e  </span></span>
    <span style="float:right; margin-left: 40px;"> <input type="text" width="100px" id="7"></span>
  </td>
  <td style="border: 1px solid rgb(146,39,143); padding: 8px;">
    <span ><input type="Circle" id="6"></span>
  </td>   
</tr>
<tr>
      <td style="border: 1px solid rgb(146,39,143); padding: 8px;">
        <span><b>9</b> <span> d i w e  </span></span>
        <span style="float:right; margin-left: 40px;"> <input type="text" width="100px" id="8"></span>
      </td>
      <td style="border: 1px solid rgb(146,39,143); padding: 8px;">
        <span ><input type="Circle" id="7"></span>
      </td>   
    </tr>
    <tr>
      <td style="border: 1px solid rgb(146,39,143); padding: 8px;">
        <span><b>10</b> <span> p e s t e  </span></span>
        <span style="float:right; margin-left: 40px;"> <input type="text" width="100px" id="9"></span>
      </td>
      <td style="border: 1px solid rgb(146,39,143); padding: 8px;">
        <span ><input type="Circle" id="8"></span>
      </td>   
    </tr>
      </tbody> 
</table>
    </div>
      `,
    },
  },
  3: {
    unit: "Unit 2",
    id: "WB10-U2-P16-E3",
    exerciseKey: "/img/FriendsPlus/Page16/E3/Key/answerKey.png",
    audio: "/Audios/1.05.mp3",
    video: "",
    component: T6,
    inputSize: 150,
    maxLength: 9,
    // checkDuplicated: true,
    stylesTextInput: {
      // textAlign: 'center',
      fontSize: 23,
    },
    textareaStyle: {},
    titleQuestion: [
      {
        num: "3",
        title: `
      <headphone name="1.05"></headphone>
			<b>
        Listen and complete the quiz questions.
			</b>
			`,
      },
    ],

    questions: [
      {
        title: `
			<div style="line-height: 50px;margin-top: 20px;">
        <b>1 </b> <span> In which country is the highest <span> #</span> in the world?</span> <br>
        <b>2 </b> <span> What's the name of the <span> #</span> to the east of  Africa?</span> <br>
        <b>3 </b> <span> What's the name of the <span> #</span> that flows through London?</span> <br>
        <b>4 </b> <span> In which country is <span> #</span> Baikal?</span> <br>
        <b>5 </b> <span> What's the name of the <span> #</span> that run along the west coast of South America?</span> <br>
        <b>6 </b> <span> In which country is the <span> #</span> Vesuvius?</span> <br>
        <b>7 </b> <span> What's the name of the <span> #</span> in southern Africa?</span> <br>
        <b>8 </b> <span> In which two continents is the Great Rift <span> #</span> ? </span> <br>
        <b>9 </b> <span> Which famous American city is on the <span> #</span> of Lake Michigan?</span> <br>
        <b>10 </b> <span> In which European country is the Black <span> #</span> ?</span>
      </div>
			`,
        answer: [
          "waterfall",
          "ocean",
          "river",
          "Lake",
          "mountains",
          "volcano",
          "desert",
          "Valley",
          "shore",
          "Forest",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "WB10-U2-P16-E4",
    exerciseKey: "/img/FriendsPlus/Page16/E4/Key/answerKey.png",
    audio: "",
    video: "",
    component: T6,
    inputSize: 150,
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    stylesTextInput: {
      textAlign: "center",
      fontSize: 21,
    },
    titleQuestion: [
      {
        num: "4",
        title: `
          <strong>
            Answer the questions in exercise 3. Use the internet to
            help you.
          </strong>
    `,
      },
    ],
    questions: [
      {
        title: `
          <div style="font-size: 21px;">
            <div style="display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right; width: 40px;">1</div>  
              <div>
                <div>In which country is the highest _____ in the world?</div>
                <div><span style="font-weight: bold; font-style: italic;">Answer:</span> #</div>
              </div>
            </div>
            <div style="margin-top: 5px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right; width: 40px;">2</div>  
              <div>
                <div>What's the name of the _____ to the east of  Africa?</div>
                <div><span style="font-weight: bold; font-style: italic;">Answer:</span> #</div>
              </div>
            </div>
            <div style="margin-top: 5px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right; width: 40px;">3</div>  
              <div>
                <div>What's the name of the _____ that flows through London?</div>
                <div><span style="font-weight: bold; font-style: italic;">Answer:</span> #</div>
              </div>
            </div>
            <div style="margin-top: 5px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right; width: 40px;">4</div>  
              <div>
                <div>In which country is _____ Baikal?</div>
                <div><span style="font-weight: bold; font-style: italic;">Answer:</span> #</div>
              </div>
            </div>
            <div style="margin-top: 5px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right; width: 40px;">5</div>  
              <div>
                <div>What's the name of the _____ that run along the west coast of South America?</div>
                <div><span style="font-weight: bold; font-style: italic;">Answer:</span> #</div>
              </div>
            </div>
            <div style="margin-top: 5px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right; width: 40px;">6</div>  
              <div>
                <div>In which country is the _____ Vesuvius?</div>
                <div><span style="font-weight: bold; font-style: italic;">Answer:</span> #</div>
              </div>
            </div>
            <div style="margin-top: 5px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right; width: 40px;">7</div>  
              <div>
                <div>What's the name of the _____ in southern Africa?</div>
                <div><span style="font-weight: bold; font-style: italic;">Answer:</span> #</div>
              </div>
            </div>
            <div style="margin-top: 5px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right; width: 40px;">8</div>  
              <div>
                <div>In which two continents is the Great Rift _____ ?</div>
                <div><span style="font-weight: bold; font-style: italic;">Answer:</span> # and #</div>
              </div>
            </div>
            <div style="margin-top: 5px; display: flex; gap: 20px;">
            <div style="font-weight: bold; text-align: right; width: 40px;">9</div>  
              <div>
                <div>Which famous American city is on the _____ of Lake Michigan?</div>
                <div><span style="font-weight: bold; font-style: italic;">Answer:</span> #</div>
              </div>
            </div>
            <div style="margin-top: 5px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right; width: 40px;">10</div>  
              <div>
                <div>In which European country is the Black _____ ?</div>
                <div><span style="font-weight: bold; font-style: italic;">Answer:</span> #</div>
              </div>
            </div>
          </div>
        `,
        answer: [
          "Venezuela",
          "Indian Ocean",
          "the Thames",
          "Russia",
          "the Andes",
          "Italy",
          "Kalahari",
          "Africa",
          "Asia",
          "Chicago",
          "Germany",
        ],
      },
    ],
  },
};

export default json;
