import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

const json = {
  1: {
    unit: 'Unit 5',
    id: 'WB10-U5-P46-E1',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '',
        title: '',
        color: '#000000',
      },
    ],
    stylesTextInput: {
      textAlign: 'center',
      fontSize: 20,
      width: 190,
      borderBottom: 'none',
      backgroundColor: 'transparent',
    },
    // maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page46/E1/Key/answerKey.png',
    isHiddenCheck: true,
    questions: [
      // Row
      {
        title: /*html */ `
          <div style="display: flex;align-items: stretch; column-gap: 10px ">
            <div style=" position: relative;">
              <img src="img/FriendsPlus/Page46/E1/1.png" style="width: 700px; object-fit: contain;">
              <div style="position: absolute; top: calc(30% - 2px ); left: calc(10%  + 132px)">#</div>
              <div style="position: absolute; top: calc(30% + 64px ); left: calc(10%  + 132px)">#</div>
              <div style="position: absolute; top: calc(30% + 133px ); left: calc(10%  + 132px)">#</div>
              <div style="position: absolute; top: calc(30% + 167px ); left: calc(10%  + 132px)">#</div>
              <div style="position: absolute; top: calc(30% + 201px ); left: calc(10%  + 132px)">#</div>
              <div style="position: absolute; top: calc(30% + 270px ); left: calc(10%  + 132px)">#</div>
              <div style="position: absolute; top: calc(30% + 338px ); left: calc(10%  + 132px)">#</div>
              <div style="position: absolute; top: calc(30% + 406px ); left: calc(10%  + 132px)">#</div>
         
            </div>
            
          </div>
        `,
        answer: ['patient', 'good at communicating', 'physically fit', 'honest', 'friendly', 'enthusiastic', 'flexible', 'reliable'],
      },
    ],
  },
  2: {
    unit: 'Unit 5',
    id: 'WB10-U5-P46-E2',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '2',
        title: 'Which qualities from exercise 1 do you think you have? Write three.',
        color: '#000000',
      },
    ],
    stylesTextInput: {
      textAlign: 'center',
      fontSize: 20,
      width: 150,
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    // maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page46/E1/Key/answerKey.png',
    isHiddenCheck: true,
    hideBtnFooter: true,
    questions: [
      // Row
      {
        title: /*html */ `
          <div>
            <span>I think I'm
              <input />, 
              <input /> and 
              <input />
            </span>
          </div>
        `,
        answer: [],
      },
    ],
  },
  3: {
    // Exercise num
    unit: 'Unit 5',
    id: 'WB10-U5-P46-E3',
    audio: 'Audios/1.17.mp3',
    video: '',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '3',
        title: '<headphone name="1.17"></headphone>Read the task below. Then listen to a candidate doing the task and tick the job he chooses.',
        color: '#000000',
      },
    ],
    exerciseKey: 'img/FriendsPlus/Page46/E3/Key/answerKey.png',

    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { padding: 0, border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 5, border: 'solid 2px', borderColor: '#2CBAEA' },
        limitSelect: 1,
        listWords: ['A </br></br></br> B'],
        answers: ['0-4'],
        // initialValue: ['3-2', '4-8', '4-10', '0-8', '1-6', '2-8', '3-6', '3-16', '5-6'],
        initialValue: [],
      },
      Layout: /*html */ `
      <div style="position: relative;">
        <img src="img/FriendsPlus/Page46/E3/1.png" style="width: 555px;height: 637px;object-fit: contain; z-index:1"/>
        <div style="position: absolute; top: calc(300px - 4px ); left: calc(45%  + 27px); z-index: 9">
          <input id="0" type='Circle'/>
        </div>
      </div>
        
      `,
    },
  },
  4: {
    unit: 'Unit 5',
    id: 'WB10-U5-P46-E4',
    audio: 'Audios/1.17.mp3',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '4',
        title:
          '<headphone name="1.17"></headphone>Read the <span style="color: rgb(0 147 69);">Speaking Strategy</span>. Then listen again and complete the sentences with the words below.',
        color: '#000000',
      },
    ],
    stylesTextInput: {
      textAlign: 'center',
      fontSize: 20,
      width: 150,
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    // maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page46/E4/Key/answerKey.png',
    isHiddenCheck: true,
    questions: [
      // Row
      {
        title: /*html */ `
          <div style="display: flex; column-gap: 20px">
            <span style='color: rgb(0 147 69); font-weight: 600'>although</span>
            <span style='color: rgb(0 147 69); font-weight: 600'>even though</span>
            <span style='color: rgb(0 147 69); font-weight: 600'>however</span>
            <span style='color: rgb(0 147 69); font-weight: 600'>nevertheless</span>
          </div>
          <div>
            <strong style="margin-right: 20px">1</strong>
            <span>I know it's only four hours a day. <input /> , it's at the
            end of the day when I am already tired.</span>
          </div>
          <div>
            <strong style="margin-right: 20px">2</strong>
            <span>I enjoy working outside<input /> the weather is
            often bad.</span>
          </div>
          <div>
            <strong style="margin-right: 20px">3</strong>
            <span><input />the hours are quite long, they're all at the
            weekend..</span>
          </div>
          <div>
            <strong style="margin-right: 20px">4</strong>
            <span>The money is slightly better too <input /> , I'm sure
            the other job is right for me.</span>
          </div>
        `,
        answer: ['However', 'even though', 'Although', 'Nevertheless'],
      },
    ],
  },
  5: {
    unit: 'Unit 5',
    id: 'WB10-U5-P46-E5',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '5',
        title: 'You are going to do the task in exercise 3. Look at the two jobs below and make notes. ',
        color: '#000000',
      },
    ],
    stylesTextInput: {
      textAlign: 'center',
      fontSize: 20,
      width: 150,
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    // maxLength: 1,
    exerciseKey: '',
    isHiddenCheck: true,
    hideBtnFooter: true,
    questions: [
      // Row
      {
        title: /*html */ `
          <div style="display: flex;align-items: stretch;">
            <img src="img/FriendsPlus/Page46/E5/1.png" style="width: 500px; object-fit: contain; "/>
            <div>
              <div >
                <span style="margin-top:7px">Which job do you prefer?  </span>
                </br>
                <div style="width: 600px">
                  <input width="550px"/>
                </div>
              </div>
              <div style="margin-top:20px" >
                <span >Why this job? </span>
                </br>
                <div style="width: 550px">
                  <textarea style="border: none;width: 100%" width="100%" id="1" rows="5" cols="50"></textarea>
                </div>
              </div>
              <div style="margin-top:20px; font-size: 23px" >
                <span >Why not the other job? </span>
                <div style="width: 550px">
                  <textarea style="border: none;width: 100%" width="100%" id="2" rows="5" cols="50"></textarea>
                </div>
              </div>
            </div>
       </div>
        `,
        answer: [],
      },
    ],
  },
}

export default json
