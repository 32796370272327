import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    // Exercise num
    unit: "Unit 4",
    id: "WB10-U4-P32-E1",
    audio: "",
    video: "",
    component: T6,
    textAlign: "left",
    isHiddenCheck: true,
    stylesTextInput: {
      // backgroundColor: 'transparent',
      // border: 'none',
      padding: 0,
      // fontSize: 22,
    },
    textareaStyle: { width: 495, resize: "none" },
    exerciseKey: "img/FriendsPlus/Page32/E1/Key/answerKey.png",
    inputSize: 60,
    titleQuestion: [
      {
        num: "1",
        title:
          "Complete the adjectives that describe the weather in the pictures.",
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
          <div style="margin-top: 30px; display: flex; flex-wrap: wrap; gap: 20px;">
            <div style="flex-basis: 355px; display: flex; flex-direction: column; gap: 10px;">
              <div><img src="img/FriendsPlus/Page32/E1/1.jpg" style="max-width: 100%;" /></div> 
              <div>
                It's <sup>1</sup><span style="color: rgb(0, 69, 99);">thundery</span> and <sup>2</sup><span style="margin-right: -4px;">c</span>#.
              </div>
            </div>
            <div style="flex-basis: 355px; display: flex; flex-direction: column; gap: 10px;">
              <div><img src="img/FriendsPlus/Page32/E1/2.jpg" style="max-width: 100%;" /></div> 
              <div>
                It's <sup>3</sup><span style="margin-right: -4px;">s</span># and <sup>4</sup><span style="margin-right: -4px;">f</span>#.
              </div>
            </div>
            <div style="flex-basis: 355px; display: flex; flex-direction: column; gap: 10px;">
              <div><img src="img/FriendsPlus/Page32/E1/3.jpg" style="max-width: 100%;" /></div> 
              <div>
                It's <sup>5</sup><span style="margin-right: -4px;">f</span># and <sup>6</sup><span style="margin-right: -4px;">i</span>#.
              </div>
            </div>

            <div style="flex-basis: 355px; display: flex; flex-direction: column; gap: 10px;">
              <div><img src="img/FriendsPlus/Page32/E1/4.jpg" style="max-width: 100%;" /></div> 
              <div>
                It's <sup>7</sup><span style="margin-right: -4px;">s</span># and <sup>8</sup><span style="margin-right: -4px;">w</span>#.
              </div>
            </div>
            <div style="flex-basis: 355px; display: flex; flex-direction: column; gap: 10px;">
              <div><img src="img/FriendsPlus/Page32/E1/5.jpg" style="max-width: 100%;" /></div> 
              <div>
                It's hot and <sup>9</sup><span style="margin-right: -4px;">s</span>#.
              </div>
            </div>
            <div style="flex-basis: 355px; display: flex; flex-direction: column; gap: 10px;">
              <div><img src="img/FriendsPlus/Page32/E1/6.jpg" style="max-width: 100%;" /></div> 
              <div>
                It's hot and <sup>10</sup><span style="margin-right: -4px;">r</span>#.
              </div>
            </div>
          </div>
        `,
        answer: [
          "loudy ",
          "nowy ",
          "rosty ",
          "oggy ",
          "cy ",
          "tormy ",
          "indy ",
          "unny ",
          "ainy ",
        ],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 4",
    id: "WB10-U4-P32-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page32/E2/Key/answerKey.png",
    inputSize: 100,
    titleQuestion: [
      {
        num: "2",
        title: "Write the nouns for the adjectives in exercise 1.",
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
        <div style='display:flex; gap:50px;'>
          <div>
            <div><b> 1&emsp;</b><i style='color:rgb(97, 156, 236);'>thunder</i></div>
            <div><b> 2&emsp;</b>#</div>
            <div><b> 3&emsp;</b>#</div>
            <div><b> 4&emsp;</b>#</div>
          </div>
          <div>
            <div><b> 5&emsp;</b>#</div>
            <div><b> 6&emsp;</b>#</div>
            <div><b> 7&emsp;</b>#</div>
            <div><b> 8&emsp;</b>#</div>
          </div>
          <div>
            <div><b>&nbsp; 9&emsp;</b>#</div>
            <div><b>10&emsp;</b>#</div>
          </div>
        </div>
            <div><img src='img/FriendsPlus/Page32/E2/1.jpg' /></div>

        `,
        answer: [
          "cloud ",
          "snow ",
          "frost ",
          "fog ",
          "ice ",
          "storm ",
          "wind ",
          "sun ",
          "rain ",
        ],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 4",
    id: "WB10-U4-P32-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "/img/FriendsPlus/Page32/E3/key/answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: `
				Complete the text with the words below.
			  `,
        color: "#000",
      },
    ],
    stylesTextInput: {
      backgroundColor: "transparent",
    },
    titleImage: "",
    isHiddenCheck: true,
    inputSize: 165,
    textAlign: "center",
    questions: [
      {
        title: `
				<div style='color: rgb(171, 89, 167); margin:0px 40px 20px 40px;'><b>
					blow &emsp; claps &emsp; flashes &emsp; raindrops &emsp; storm clouds &emsp; <br/>sunshine &emsp; thunderstorm
				</b></div>
              <div style='background-color:rgb(224, 205, 227); width: 800px;'>
                <div style='padding: 15px;'>
                  There was a terrible <b><sup>1</sup>#</b> yesterday evening. <br/>
                  We saw big, dark grey <b><sup>2</sup>#</b> in the distance. <br/>
                  Then a strong wind started to <b><sup>3</sup>#</b>
                  and it began to rain heavily – huge <b><sup>4</sup>#</b> 
                  fell from the sky. We got incredibly wet! There were 
                  <b><sup>5</sup>#</b> of lightning and loud 
                  <b><sup>6</sup>#</b> of thunder. It was quite scary. But then the storm passed and  
                  there was bright <b><sup>7</sup>#</b> !
                </div>
              </div>
			  `,
        answer: [
          "thunderstorm",
          "storm clouds",
          "blow",
          "raindrops",
          "flashes",
          "claps",
          "sunshine",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "WB10-U4-P32-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page32/E4/Key/answerKey.png",
    component: T6,
    inputSize: 40,
    stylesTextInput: {
      backgroundColor: "transparent",
      border: "solid 1px",
      borderRadius: 5,
      height: 40,
      padding: 0,
      fontSize: 22,
    },
    // isHiddenCheck: true,
    textAlign: "center",
    maxLength: 1,
    titleQuestion: [
      {
        num: "4",
        title: `
        Number the adjectives in order from the hottest (1) to the coldest (7).
            `,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
              <div style="display: flex; gap: 70px; margin-top: 20px">
                <div>
                   <div>#  cold</div>
                   <div style='margin:10px 0;'>#  mild</div>
                   <div>#  hot</div>
                </div>
                <div>
                   <div>#  warm</div>
                   <div style='margin:10px 0;'>#  sweltering</div>
                </div>
                <div>
                   <div>#  freezing</div>
                   <div style='margin:10px 0;'>#  cool</div>
                </div>
                
              </div>
                        
            `,
        answer: ["6", "4", "2", "3", "1", "7", "5"],
      },
    ],
  },
  5: {
    // Exercise num
    unit: "Unit 4",
    id: "WB10-U4-P32-E5",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page32/E5/Key/answerKey.png",
    inputSize: 360,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "5",
        title: "Write the temperatures in words.",
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
        <div style='display:flex; gap:15px;'>
          <div>
            <div><b>1</b></div>
            <div><b>2</b></div>
            <div><b>3</b></div>
            <div><b>4</b></div>
            <div><b>5</b></div>
            <div><b>6</b></div>
          </div>
          <div>
            <div> –5°</div>
            <div> 26°C</div>
            <div> –15°</div>
            <div> 14°C</div>
            <div> –7°C</div>
            <div> 32°</div>
          </div>
          <div>
            <div><i style='color:rgb(97, 156, 236);'>It's minus five.</i></div>
            <div><i style='color:rgb(97, 156, 236);'>It's twenty-six degrees Celsius.</i></div>
            <div>#</div>
            <div>#</div>
            <div>#</div>
            <div>#</div>
          </div>
        </div>

        `,
        answer: [
          "It's minus fifteen.",
          "It's fourteen degrees (Celsius).",
          "It's minus seven.",
          "It's thirty-two degrees (Celsius).",
        ],
      },
    ],
  },
  6: {
    // Exercise num
    unit: "Unit 4",
    id: "WB10-U4-P32-E6",
    audio: "Audios/1.12.mp3",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page32/E6/Key/answerKey.png",
    textAlign: "center",
    inputSize: 150,
    titleQuestion: [
      {
        num: "6",
        title: `<headphone1 name='1.12'></headphone1> Listen to people describing the weather. Complete each sentence with one word from exercises 1, 2 or 3.`,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
        <div style='display:flex; gap:15px;'>
          <div>
            <div><b>1<br/><br/>
            2<br/><br/>
            3<br/><br/>
            4</b></div>
          </div>
          <div>
            <div><b>a</b></div>
            <div><b>b</b></div>
            <div><b>a</b></div>
            <div><b>b</b></div>
            <div><b>a</b></div>
            <div><b>b</b></div>
            <div><b>a</b></div>
            <div><b>b</b></div>
          </div>
          <div>
            <div>It was <i style='color:rgb(97, 156, 236);'>rainy</i> all morning.</div>
            <div>It was # in the afternoon.</div>
            <div>There was thick # .</div>
            <div>There was # on the road.</div>
            <div>Most of the day the sky was # .</div>
            <div>It was mild and # .</div>
            <div>There was # on the grass.</div>
            <div>In the afternoon it was # and # .</div>
          </div>
        </div>

        `,
        answer: [
          "sunny",
          "fog",
          "ice",
          "cloudy",
          "thundery",
          "frost",
          "windy",
          "snowy",
        ],
      },
    ],
  },
  7: {
    // Exercise num
    unit: "Unit 4",
    id: "WB10-U4-P32-E7",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `Write a paragraph describing the weather one day last week. Write 15–30 words.`,
      },
    ],
    isHiddenCheck: true,
    textareaStyle: { width: 900 },
    exerciseKey: "",
    hideBtnFooter: true,
    questions: [
      {
        title: `
          <div><textarea rowS='5' id='0'></textarea></div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
