
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
	1: {
		unit: 'Unit 6',
		id: 'WB10-U6-P53-E1',
		audio: '',
		video: '',
		component: Circle_Write,
		titleQuestion: [
			{
				num: '',
				title: '',
				color: '#000000',
			},
		],
		exerciseKey: 'img/FriendsPlus/Page53/E1/Key/answerKey.png',

		question: {
			Write: {
				inputStyle: { width: 100, },
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: { padding: 0, border: 'none', borderRadius: '50%', borderColor: 'transparent' },
				selectWordStyle: { padding: 5, border: 'solid 2px', borderColor: '#2CBAEA' },
				limitSelect: 1,
				listWords: [
					"a <br/> b",
					"a <br/> b",
					"a <br/> b",
					"a <br/> b",
					"a <br/> b",
					"a <br/> b",
					"a <br/> b",
					"a <br/> b",
				],
				answers: [
					"0-0",
					"1-0",
					"2-4",
					"3-4",
					"4-4",
					"5-4",
					"6-0",
					"7-4",
				],
				initialValue: [],
			},
			Layout: /*html */ `
			<div style="position: relative;width: 900px; ">
				<img src="img/FriendsPlus/Page53/E1/1.png" style="width: 650px; object-fit: contain;"/>
				<div style="position: absolute;top: 20px; left: 60px; width: 555px; font-size: 18px ">
					<span style="font-size: 25px"><strong style="color:rgb(62 63 153); font-weight: 700">Revision:</strong> Student Book page 76</span>
					<div>
						<strong>1 Circle the correct words (a or b) to complete the text. </strong>
					</div>
					<div >
	  					<img src="img/FriendsPlus/Page53/E1/2.png" style="height: 400px; "/>
					</div>
					<div style="padding: 20px 50px 0 ">
						<div style="display: flex;  margin-top: 15px">
							<div style="display: flex; column-gap: 20px; width: 250px ">
								<strong>1</strong>
								<div style="display: flex; column-gap: 20px">
									<div>
										<strong><input id="0" type="Circle"/></strong>
									</div>
									<div>
										<div>CEO</div>
										<div>entrepreneur</div>
									</div>
								</div>
							</div>
							<div style="display: flex; column-gap: 20px; width: 250px ">
								<strong>2</strong>
								<div style="display: flex; column-gap: 20px">
									<div>
										<strong><input id="1" type="Circle"/></strong>
									</div>
									<div>
										<div>a contract</div>
										<div>a funding</div>
									</div>
								</div>
							</div>
						</div>
						<div style="display: flex;  margin-top: 15px">
							<div style="display: flex; column-gap: 20px; width: 250px ">
								<strong>3</strong>
								<div style="display: flex; column-gap: 20px">
									<div>
										<strong><input id="2" type="Circle"/></strong>
									</div>
									<div>
										<div>salary</div>
										<div>income</div>
									</div>
								</div>
							</div>
							<div style="display: flex; column-gap: 20px; width: 250px ">
								<strong>4</strong>
								<div style="display: flex; column-gap: 20px">
									<div>
										<strong><input id="3" type="Circle"/></strong>
									</div>
									<div>
										<div>an investor</div>
										<div>an entrepreneur</div>
									</div>
								</div>
							</div>
						</div>
						<div style="display: flex;  margin-top: 15px">
							<div style="display: flex; column-gap: 20px; width: 250px ">
								<strong>5</strong>
								<div style="display: flex; column-gap: 20px">
									<div>
										<strong><input id="4" type="Circle"/></strong>
									</div>
									<div>
										<div>profits</div>
										<div>funding</div>
									</div>
								</div>
							</div>
							<div style="display: flex; column-gap: 20px; width: 250px ">
								<strong>6</strong>
								<div style="display: flex; column-gap: 20px">
									<div>
										<strong><input id="5" type="Circle"/></strong>
									</div>
									<div>
										<div>CEOs</div>
										<div>investors</div>
									</div>
								</div>
							</div>
						</div>
						<div style="display: flex;  margin-top: 15px">
							<div style="display: flex; column-gap: 20px; width: 250px ">
								<strong>7</strong>
								<div style="display: flex; column-gap: 20px">
									<div>
										<strong><input id="6" type="Circle"/></strong>
									</div>
									<div>
										<div>profits</div>
										<div>investors</div>
									</div>
								</div>
							</div>
							<div style="display: flex; column-gap: 20px; width: 250px ">
								<strong>8</strong>
								<div style="display: flex; column-gap: 20px">
									<div>
										<strong><input id="7" type="Circle"/></strong>
									</div>
									<div>
										<div>funding</div>
										<div>salary</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>	
			</div>

			`,
		}
	},
	2: {
		unit: 'Unit 6',
		id: 'WB10-U6-P53-E2',
		audio: '',
		video: '',
		component: Circle_Write,
		titleQuestion: [
			{
				num: '2',
				title: 'Read the text about Warren Buffet, ignoring the gaps. Which sentence is true: a, b or c?',
				color: '#000000',
			},
		],
		exerciseKey: 'img/FriendsPlus/Page53/E2/Key/answerKey.png',

		question: {
			Write: {
				inputStyle: { width: 100, },
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: { padding: 0, border: 'none', borderRadius: '50%', borderColor: 'transparent' },
				selectWordStyle: { padding: 5, border: 'solid 2px', borderColor: '#2CBAEA' },
				limitSelect: 1,
				listWords: ["a <br/> b <br/> c"],
				answers: ["0-8"],
				initialValue: [],
			},
			Layout: /*html */ `
	  <div style="display: flex; column-gap: 20px">
		<div>
			<strong><input id="0" type="Circle" /></strong>
		</div>
		<div>
	  		<div>Warren Buffet is a billionaire who enjoys spending money</div>
	  		<div>Warren Buffet made billions, but has given all his money away.</div>
	  		<div>Warren Buffet is a billionaire and leads a modest lifestyle.</div>
		</div>
	  </div>
	  <div>
	 	<img src="img/FriendsPlus/Page53/E2/1.png" style="width: 1200px"/> 
	  </div>
		`,
		}
	},
	3: {
		unit: 'Unit 6',
		id: 'WB10-U6-P53-E3',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '3',
				title: 'Read the <span style="color:rgb(62 63 153)">Reading Strategy</span>. Then match sentences A–G with gaps 1–5 in the text. There are two extra sentences.',
				color: '#000000',
			},
		],
		stylesTextInput: {
			fontSize: 20,
			width: 36,
			textAlign: 'center',
			border: '1px solid',
			borderRadius: 5,
			backgroundColor: 'transparent',
		},
		maxLength: 1,
		exerciseKey: 'img/FriendsPlus/Page53/E3/Key/answerKey.png',
		isHiddenCheck: true,
		questions: [
			// Row
			{
				title: /*html */ `
				<div style="display: flex; column-gap: 50px">
					<div style="width: 600px; height: 400px; border: 5px dotted rgb(62 63 153); border-radius: 10px; background-color: rgb(227 226 241); padding: 15px">
						<strong style="color: rgb(62 63 153)">Reading Strategy</strong > <br/>
						<span style="font-size: 20px">When you do a task with gapped sentences,
						study the sentence after each gap and look for
						any connections with the missing sentence. For
						example, if it begins with but, there is a contrast.
						Other words suggest other types of connection:</span>
						<div style="font-size: 20px">
							<ul>
								<li>similarity: too</li>
								<li>cause / result: so</li>
								<li>a different option: or</li>
							</ul>
						</div>
					</div>
					<div style=" font-size: 22px">
						<div style="display: flex; column-gap: 20px;">
							<strong>A</strong>
							<span>He doesn't plan to give his children very much money
							when he dies.</span>
						</div>
						<div style="display: flex; column-gap: 20px">
							<strong>B</strong>
							<span>By the age of twenty he had made and saved $9,800
							(about $100,000 in today's money).</span>
						</div>
						<div style="display: flex; column-gap: 20px">
							<strong>C</strong>
							<span>However, he became the richest man in the world</span>
						</div>
						<div style="display: flex; column-gap: 20px">
							<strong>D</strong>
							<span>As a teenager, he worked in his grandfather's shop and
							delivered newspapers.</span>
						</div>
						<div style="display: flex; column-gap: 20px">
							<strong>E</strong>
							<span>Although he is rich, he isn't very happy</span>
						</div>
						<div style="display: flex; column-gap: 20px">
							<strong>F</strong>
							<span>Only his clothes are expensive, but he says they look cheap when he puts them on!</span>
						</div>
						<div style="display: flex; column-gap: 20px">
							<strong>G</strong>
							<span>He still lives in Omaha, in the house he bought more than fifty years ago</span>
						</div>
					</div>
				</div>
				<div style="display: flex; column-gap: 50px; margin-top: 20px">
					<div>
						<strong>1</strong>
						<input />
					</div>
					<div>
						<strong>2</strong>
						<input />
					</div>
					<div>
						<strong>3</strong>
						<input />
					</div>
					<div>
						<strong>4</strong>
						<input />
					</div>
					<div>
						<strong>5</strong>
						<input />
					</div>
				</div>

			`,
				answer: [
					"D",
					"B",
					"G",
					"F",
					"A",
				],
			},
		],
	},
	4: {
		unit: 'Unit 6',
		id: 'WB10-U6-P53-E4',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '4',
				title: 'Read the text again, including the missing sentences in exercise 3. Are the sentences true (T) or false (F)? ',
				color: '#000000',
			},
		],
		stylesTextInput: {
			fontSize: 20,
			width: 36,
			textAlign: 'center',
			border: '2px solid',
			borderRadius: 5,
			backgroundColor: 'transparent',
		},
		maxLength: 1,
		exerciseKey: 'img/FriendsPlus/Page53/E4/Key/answerKey.png',
		isHiddenCheck: true,
		questions: [
			// Row
			{
				title: /*html */ `
				<div>
					<strong>1</strong>
					<span>
						Buffet is over 80 years old.  
					<input />
					</span>
				</div>
				<div>
					<strong>2</strong>
					<span>
					He started earning money when he was a teenager.  
					<input />
					</span>
				</div>
				<div>
					<strong>3</strong>
					<span>
					He has recently moved into a very expensive new house.  
					<input />
					</span>
				</div>
				<div>
					<strong>4</strong>
					<span>
					The food he eats now is the same as the food he ate when
					he was young.  
					<input />
					</span>
				</div>
				<div>
					<strong>5</strong>
					<span>
					He plans to give a lot of money to his children.  
					<input />
					</span>
				</div>
			`,
				answer: [
					"T",
					"T",
					"F",
					"T",
					"F",
				],
			},
		],
	},
}

export default json;