import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';

const json = {
	1: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P12-E1',
		titleQuestion: [
			{
				num: '1',
				title: 'Circle the correct adjective',
			},
		],
		component: Circle_Write,
		exerciseKey: 'img/FriendsPlus/Page12/E1/Key/answerKey.png',
		question: {
			Write: {
				inputStyle: {},
				answers: [],
				initialValue: [],
			},
			Circle: {
				// initialWordStyle: { margin: '5px 0', border: 'solid 2px', borderColor: 'black', borderRadius: '50%', padding: '0 5px' },
				initialWordStyle: {
					margin: '5px 0',
					border: 'none',
					borderColor: 'transparent',
					borderRadius: '50%',
				},
				selectWordStyle: {
					border: 'solid 2px',
					borderColor: 'rgb(187, 7, 59)',
					margin: '-2px',
					padding: 5,
				},
				limitSelect: 1,
				listWords: [
					' tired / tiring',
					'amazed / amazing',
					'astonished / astonishing',
					'embarrassed / embarrassing',
					'interested / interesting',
					'shocked / shocking',
					'surprised / surprising',
					' annoyed / annoying ',
				],
				answers: ['0-6', '1-4', '2-0', '3-4', '4-4', '5-4', '6-0', '7-2'],
				initialValue: [],
			},
			Layout: `
            <div style="margin-left: 30px">
              <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1 </b></div><div style="margin-left: 30px; flex: 1; ">Playing volleyball is fun, but it's <b><input id='0' type='Circle' /></b>.</div></div>
              <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2 </b></div><div style="margin-left: 30px; flex: 1; ">This new computer game is <b><input id='1' type='Circle' /></b>!</div></div>
              <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3 </b></div><div style="margin-left: 30px; flex: 1; ">I was <b><input id='2' type='Circle' /></b> that we won the dance competition.</div></div>
              <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4 </b></div><div style="margin-left: 30px; flex: 1; ">‘I fell off my chair in the middle of a lesson.' ‘How <b><input id='3' type='Circle' /></b>!'</div></div>
              <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5 </b></div><div style="margin-left: 30px; flex: 1; ">My grandma's stories are always <b><input id='4' type='Circle' /></b>.</div></div>
              <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6 </b></div><div style="margin-left: 30px; flex: 1; ">I read a <b><input id='5' type='Circle' /></b> report about smoking.</div></div>
              <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>7 </b></div><div style="margin-left: 30px; flex: 1; ">I wasn't <b><input id='6' type='Circle' /></b> that he was late.</div></div>
              <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>8 </b></div><div style="margin-left: 30px; flex: 1; ">I was so <b><input id='7' type='Circle' /></b> about your comment!</div></div>
            </div>  
      `,
		},
	},
	2: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P12-E2',
		audio: '',
		video: '',
		component: T6,
		isHiddenCheck: true,
		stylesTextInput: {
			backgroundColor: 'transparent',
			border: 'none',
			padding: 0,
			fontSize: 16,
		},
		textareaStyle: { width: 495, resize: 'none' },
		exerciseKey: 'img/FriendsPlus/Page12/E2/Key/answerKey.png',
		inputSize: 147,
		titleQuestion: [
			{
				num: '2',
				title:
					'Complete the text with the correct -ed or -ing adjectives formed from the verbs in brackets.',
			},
		],
		questions: [
			{
				title: `
        <div style="position: relative; margin: 10px; ">
            <div><img src='img/FriendsPlus/Page12/E2/1.jpg' /></div>
            <div >
              <div style=" position: absolute; top: 203px; left: 381px; "><input id='0' /></div>
              <div style=" position: absolute; top: 257px; left: 169px; "><input id='1' /></div>
              <div style=" position: absolute; top: 305px; left: 181px; "><input id='2' /></div>
              <div style=" position: absolute; top: 353px; left: 84px; "><input id='3' /></div>
              <div style=" position: absolute; top: 398px; left: 284px; "><input id='4' /></div>
              <div style=" position: absolute; top: 548px; left: 123px; "><input id='5' /></div>
              <div style=" position: absolute; top: 594px; left: 331px; "><input id='6' /></div>
              <div style=" position: absolute; top: 651px; left: 385px; "><input id='7' /></div>
              <div style=" position: absolute; top: 674px; left: 234px; "><input id='8' /></div>
              <div style=" position: absolute; top: 698px; left: 315px; "><input id='9' /></div>
            </div>
          </div>
        `,
				answer: [
					'tiring',
					'exhausted',
					'worried',
					'frightening',
					'confused',
					'delighted',
					'moving',
					'interested',
					'astonishing',
					'exciting',
				],
			},
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P12-E3',
		component: T6,
		stylesTextInput: {
			padding: 0,
			fontSize: 22,
			textAlign: 'center',
		},
		exerciseKey: 'img/FriendsPlus/Page12/E3/Key/answerKey.png',
		inputSize: 120,
		titleQuestion: [
			{
				num: '3',
				title:
					'Complete the sentences with -ed or -ing adjectives formed from the verbs below.',
			},
		],
		isHiddenCheck: true,
		questions: [
			{
				title: `
              <div style="color: rgb(144, 38, 143); display: inline-flex; font-weight: bold; gap: 30px; flex-wrap: wrap; width: 660px; line-height: 10px; margin: 15px">
                <div>confuse</div>
                <div>disgust</div>
                <div>excite</div>
                <div>frighten</div>
                <div>move</div>
              </div>
              <div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1 </b></div><div style="margin-left: 30px; flex: 1; ">‘This milk has got black bits in it.' ‘How #  !'</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2 </b></div><div style="margin-left: 30px; flex: 1; ">We all cried at the end of the film – it was so # </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3 </b></div><div style="margin-left: 30px; flex: 1; ">I enjoyed the book, but I was #  about the ending. It didn't make sense.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4 </b></div><div style="margin-left: 30px; flex: 1; ">I love watching football, it's so  # !</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5 </b></div><div style="margin-left: 30px; flex: 1; ">I felt #  because it was dark in the house and I was alone.</div></div>
              </div>
        `,
				answer: [
					'disgusting ',
					'moving ',
					'confused',
					'exciting ',
					'frightened',
				],
			},
		],
	},
	4: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P12-E4',
		audio: '',
		video: '',
		component: T6,
		hideBtnFooter: true,
		isHiddenCheck: true,
		stylesTextInput: {
			backgroundColor: 'transparent',
			borderBottom: '1px solid',
			padding: 0,
		},
		exerciseKey: 'img/FriendsPlus/Page12/E4/Key/answerKey.png',
		inputSize: 127,
		titleQuestion: [
			{
				num: '4',
				title:
					'Read the <span style="color: rgb(144, 38, 143)">Vocab boost!</span> box. Choose ONE of the verbs below. Write down the related adjectives and noun, using a dictionary to help you. Then write example sentences.',
			},
		],
		questions: [
			{
				title: `
				<div style=" position: relative; ">
				  	<div> <img style="max-width:55%" src='img/FriendsPlus/Page12/E4/1.jpg' /> </div>
				      <div style="color: rgb(144, 38, 143); display: inline-flex; font-weight: bold; gap: 30px; flex-wrap: wrap; width: 660px; line-height: 10px; margin: 15px">
				        <div>amuse</div>
				        <div>depress</div>
				        <div>entertain</div>
				        <div>relax</div>
				        <div>satisfy</div>
				      </div>
				  	<div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1 </b></div><div style="margin-left: 30px; flex: 1; display: block "><div>verb: #</div><div>Example: <input width='600px' id='1'/></div></div> </div>
				  	<div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2 </b></div><div style="margin-left: 30px; flex: 1; "><div>-ed adjective: #</div><div>Example: <input width='600px' id='3'/></div></div></div>
				  	<div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3 </b></div><div style="margin-left: 30px; flex: 1; "><div>-ing adjective: #</div><div>Example: <input width='600px' id='5'/></div></div></div>
				  	<div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4 </b></div><div style="margin-left: 30px; flex: 1; "><div>noun: #</div><div>Example: <input width='600px' id='7'/></div></div></div>
				</div>
				`,
				answer: [],
			},
		],
	},
};

export default json;
