import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'

const json = {
  1: {
    unit: "Unit 8",
    id: "FG10-U8-P68-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page68/E1/Key/answerKey.png",
    component: T6,
    inputSize: 100,
    textAlign: 'center',
    // isHiddenCheck: true,
    maxLength: 10,
    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: "1",
        title: `
            Complete the text with these prepositions: <i>about</i>, <i>at</i>, <i>for</i>,
            <i>in</i>, <i>of</i>, <i>to</i>, and <i>with</i>. You will have to use some prepositions
            more than once.
            `,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
              <div style="position: relative; margin-top: 50px">
                <div>
                  <img src="img/FriendsPlus/Page68/E1/1.jpg" alt="" style='width:600px;'/>
                </div>
                <div style="position: absolute;top: -50px; right: -700px; background-color: white; height: auto; width: 950px; border: 1px solid; font-size: 22px; padding: 30px">
                  <span>
                    If somebody told you they were building a lift to take people into space, you would probably laugh
                    <sup><strong>1</strong></sup> # the idea. But in fact, if you listen <sup><strong>2</strong></sup> # scientists and engineers talking about the plan,
                    you soon realise they are being completely serious.
                    The elevator would consist <sup><strong>3</strong></sup> # a carbon fibre ribbon 100,000 km long. One end would be in the
                    ocean at the equator, and the other end would be in space.
                    At the moment, it takes months or years to prepare <sup><strong>4</strong></sup> # a trip into space by rocket. Space agencies
                    have begun searching <sup><strong>5</strong></sup> # a better option – partly because they worry <sup><strong>6</strong></sup> # the cost
                    of space missions. The idea of a space elevator appeals <sup><strong>7</strong></sup> # governments because it would be far
                    cheaper than sending rockets into space. It would be slower, but that would give passengers more time to
                    adjust <sup><strong>8</strong></sup> # the different conditions as they left the earth.
                    It may not be easy though: countries might argue <sup><strong>9</strong></sup> # the exact location of the elevator. But if
                    the political leaders can cope <sup><strong>10</strong></sup> # these problems and manage to take part <sup><strong>11</strong></sup> # the
                    project together, it is certainly possible.
                    Science fiction writer Arthur C. Clarke wrote a novel <sup><strong>12</strong></sup> # the idea in 1979. He said in a speech that
                    humans would build a space elevator ten years after they stopped laughing at the idea. Well, they've stopped
                    laughing now.
                  </span>
                </div>
              </div>
            `,
        answer: [
          "at",
          "to",
          "of",
          "for",
          "for",
          "about",
          "to",
          "to",
          "about",
          "with",
          "in",
          "about",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "FG10-U8-P68-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page68/E2/Key/answerKey.png",
    component: Circle_Write,
    inputSize: 100,
    // isHiddenCheck: true,
    maxLength: 10,
    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: "2",
        title: `
        Circle the correct prepositions to complete the sentences.
            `,
        color: "#000",
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { padding: 0, border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 5, border: 'solid 2px', borderColor: '#2CBAEA' },
        limitSelect: 1,
        listWords: [
          `for / to`,
          `at / to`,
          `about / for`,
          `for / about`,
          `from / of`,
          `about / of`,
          `to / with`,
          `for / to`,
        ],
        answers: ['0-0', '1-0', '2-4', '3-4', '4-4', '5-0', '6-0', '7-4'],
        initialValue: [],
      },
      Layout: `
        <div style="margin-top: 30px">
          <div>
            <ol type="1" style="font-weight: bold">
              <li><span style="font-weight: normal;">You should apologise <b><input id='0' type='Circle' /></b> that comment!</span></li>
              <li><span style="font-weight: normal;">Cats get frightened if you shout <b><input id='1' type='Circle' /></b> them.</span></li>
              <li><span style="font-weight: normal;">I asked <b><input id='2' type='Circle' /></b> the manager so that I could talk to him
              in person.</span></li>
              <li><span style="font-weight: normal;">We complained <b><input id='3' type='Circle' /></b> our hotel room.</span></li>
              <li><span style="font-weight: normal;">I've never heard <b><input id='4' type='Circle' /></b> this video game.</span></li>
              <li><span style="font-weight: normal;">I dreamed <b><input id='5' type='Circle' /></b> flying again last night.</span></li>
              <li><span style="font-weight: normal;">After a long discussion, they agreed <b><input id='6' type='Circle' /></b> our request
              and gave us our money back.</span></li>
              <li><span style="font-weight: normal;">If you're unhappy with the service, why don't you write
              <b><input id='7' type='Circle' /></b> the manager?</span></li>
            </ol>
          </div>
      </div>
      `,
    },
  },
  3: {
    unit: "Unit 8",
    id: "FG10-U8-P68-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page68/E3/Key/answerKey.png",
    component: T6,
    inputSize: 100,
    // isHiddenCheck: true,
    maxLength: 10,
    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: "3",
        title: `
        Study the dictionary entries and answer the questions.
            `,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
          <div>
            <div style="position: relative; margin-bottom: 20px">
              <img src="img/FriendsPlus/Page68/E3/1.jpg" alt="" style='width:900px;' />
              <div style="position: absolute; top: 60px; padding: 0px 80px 20px 50px; width: 90%">
                <span><strong style="color: rgb(31, 105, 170)">charge</strong> / tʃɑːdʒ / <i>verb</i> <strong>1</strong> [T, I] <strong>charge (sb/sth) for sth</strong>
                to ask sb to pay a particular amount of money: <i>We
                charge £75 a night for a single room.</i> ◆ <i>They forgot to
                charge us for the drinks</i>. <strong>2</strong> [T, I] <strong>charge sb (with sth)</strong> to
                accuse sb officially of doing sth which is against the
                law: <i>Three men have been charged with robbery.</i></span>
              </div>
              <div style="position: absolute; top: 300px; padding: 20px 80px 20px 50px; width: 90%">
                <span><strong style="color: rgb(31, 105, 170)">inform</strong> / ɪnˈfɔːm / <i>verb</i> [T] <strong>inform sb (of/about sth)</strong>
                to give sb information (about sth), especially in an
                official way: <i>You should inform the police of the accident.</i>
                ◆ <i>Do keep me informed of any changes.</i></span>
              </div>
            </div>
            <div>
              <ol type="1" style="font-weight: bold">
                <li>
                <span style="font-weight: normal;">Which verb is used with two prepositions with no change
                of meaning? #</span>
                </li>
                <li><span style="font-weight: normal;">Which verb is used with two prepositions, but the
                meaning changes?#</span></li>
                <li><span style="font-weight: normal;">Which entry uses <strong>bold and italic</strong> type for two words that often go together? #</span></li>
              </ol>
            </div>
          </div>
            `,
        answer: ["inform", "charge", "inform"],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "FG10-U8-P68-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page68/E4/Key/answerKey.png",
    component: T6,
    // isHiddenCheck: true,
    textareaStyle: { width: 700, resize: 'none', borderBottom: 'none' },
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: "4",
        title: `
        Read the <i style="color: rgb(93, 45, 131)">Vocab boost!</i> box. Write some words that often
        go with these verbs.
            `,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
              <div style="margin-top: 20px">
                <div style="background-image: url('img/FriendsPlus/Page68/E4/1.jpg'); background-repeat: no-repeat; background-size: 100%; width: 800px; height: 350px; padding: 30px 60px 20px 80px">
                  <span style="color: rgb(93, 45, 131)"><strong>VOCAB BOOST!</strong></span></br>
                  <span>Dictionaries often use <i>something</i> or <i>somebody</i> (<i>sth</i> or <i>sb</i>)
                  in their definitions, but when you record verbs it is a good
                  idea to include words which often go with those verbs.
                  to complain about sth / sb → <i style="color: rgb(55, 100, 131)">to complain about the
                  weather / the noise / your hotel / your neighbours / your
                  doctor</i></span>
                </div>
                <div>
                  <div>
                    <ol type="1" style="font-weight: bold">
                      <li><span style="font-weight: normal;">to care for sth / sb → to care for</span><textarea id="0" rows="2" ></textarea></li>
                      <li><span style="font-weight: normal;">to learn about sth → to learn about</span><textarea id="1" rows="2" ></textarea></li>
                      <li><span style="font-weight: normal;">to write to sb → to write to</span><textarea id="2" rows="2" ></textarea></li>
                    </ol>
                  </div>
                </div>
              </div>
            `,
        answer: [],
      },
    ],
  },
};

export default json;
