import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 7",
    id: "WB10-U7-P59-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page59/E1/key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `
				How do these tenses / modal verbs change in reported speech?
			  `,
        color: "black",
      },
    ],
    isHiddenCheck: true,
    inputSize: 248,
    textAlign: "center",
    maxLength: 25,
    titleImage: "",
    questions: [
      {
        title: `
        <div style='display:flex; gap:50px;'>
            <div>
                <b>Direct speech</b>
                <div style='display:flex; gap:15px;'>
                    <b>1</b>
                    <div>present simple</div>
                </div>
                <div style='display:flex; gap:15px;'>
                    <b>2</b>
                    <div>present continuous</div>
                </div>
                <div style='display:flex; gap:15px;'>
                    <b>3</b>
                    <div>past simple</div>
                </div>
                <div style='display:flex; gap:15px;'>
                    <b>4</b>
                    <div>present perfect</div>
                </div>
                <div style='display:flex; gap:15px;'>
                    <b>5</b>
                    <div>past perfect</div>
                </div>
                <div style='display:flex; gap:15px;'>
                    <b>6</b>
                    <div><i>can / can't</i></div>
                </div>
                <div style='display:flex; gap:15px;'>
                    <b>7</b>
                    <div><i>will / won't</i></div>
                </div>
                <div style='display:flex; gap:15px;'>
                    <b>8</b>
                    <div><i>might</i></div>
                </div>
                <div style='display:flex; gap:15px;'>
                    <b>9</b>
                    <div><i>should</i></div>
                </div>
            </div>
            <div>
                <b>Reported speech</b>
                <div>#</div>
                <div>#</div>
                <div>#</div>
                <div>#</div>
                <div>#</div>
                <div><input id='' width='110px'/> / <input id='' width='110px'/></div>
                <div><input id='' width='110px'/> / <input id='' width='110px'/></div>
                <div>#</div>
                <div>#</div>
            </div>
        </div>
			  `,
        answer: [
          "past simple",
          "past continuous",
          "past perfect",
          "past perfect",
          "past perfect",
          "could",
          "couldn't",
          "would",
          "wouldn't ",
          "might",
          "should",
        ],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 7",
    id: "WB10-U7-P59-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page59/E2/Key/answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "2",
        title: `
               Circle the correct words. 
        `,
        color: "black",
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "",
          color: "#000",
          textAlign: "center",
          maxLength: 16,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
        },
        selectWordStyle: {
          border: "solid 3px",
          borderColor: "gray",
          padding: "5px",
        },
        limitSelect: 1,
        listWords: [
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; b|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; b|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; b|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; b|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; b|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; b|",
        ],
        answers: ["0-4", "1-0", "2-4", "3-4", "4-0", "5-0"],
        initialValue: [],
      },
      Layout: `


      
      <div style="position: relative; width: 600px;">
        
        <div style='display:flex; '>
          <div><b>1 &emsp;</b></div>
          <div style='flex: 1;'>
            <div>John said he ___ ill.</div>
            <div style='display:flex; margin-left: 35px;'>
              <div style='flex: 1;'>is</div>
              <div style='flex: 1;'>was</div>
            </div>
          </div>
        </div>

        <div style='display:flex; '>
          <div><b>2 &emsp;</b></div>
          <div style='flex: 1;'>
            <div>Maisie said that she ___ buy a new phone.</div>
            <div style='display:flex; margin-left: 35px;'>
              <div style='flex: 1;'>would</div>
              <div style='flex: 1;'>will</div>
            </div>
          </div>
        </div>

        <div style='display:flex; '>
          <div><b>3 &emsp;</b></div>
          <div style='flex: 1;'>
            <div>Nathan said he ___ looking forward to summer.</div>
            <div style='display:flex; margin-left: 35px;'>
              <div style='flex: 1;'>is</div>
              <div style='flex: 1;'>was</div>
            </div>
          </div>
        </div>

        <div style='display:flex; '>
          <div><b>4 &emsp;</b></div>
          <div style='flex: 1;'>
            <div>Harry said that Kay ___ gone to Italy.</div>
            <div style='display:flex; margin-left: 35px;'>
              <div style='flex: 1;'>has</div>
              <div style='flex: 1;'>had</div>
            </div>
          </div>
        </div>

        <div style='display:flex; '>
          <div><b>5 &emsp;</b></div>
          <div style='flex: 1;'>
            <div>Emma told me that she ___ find her phone.</div>
            <div style='display:flex; margin-left: 35px;'>
              <div style='flex: 1;'>couldn't</div>
              <div style='flex: 1;'>can't</div>
            </div>
          </div>
        </div>

        <div style='display:flex; '>
          <div><b>6 &emsp;</b></div>
          <div style='flex: 1;'>
            <div>Pete told me he ___ go to the party.</div>
            <div style='display:flex; margin-left: 35px;'>
              <div style='flex: 1;'>had to</div>
              <div style='flex: 1;'>might have</div>
            </div>
          </div>
        </div>


        <div style="position: absolute; top: 37px; left: 45px; "><input id='0' type='Circle' /></div>
        <div style="position: absolute; top: 113px; left: 45px; "><input id='1' type='Circle' /></div>
        <div style="position: absolute; top: 189px; left: 45px; "><input id='2' type='Circle' /></div>
        <div style="position: absolute; top: 264px; left: 45px; "><input id='3' type='Circle' /></div>
        <div style="position: absolute; top: 339px; left: 45px; "><input id='4' type='Circle' /></div>
        <div style="position: absolute; top: 415px; left: 45px; "><input id='5' type='Circle' /></div>

      </div>
      
        `,
    },
  },

  3: {
    unit: "Unit 7",
    id: "WB10-U7-P59-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page59/E3/key/answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: `
				How do these references to time and place change in reported speech? 
			  `,
        color: "black",
      },
    ],
    isHiddenCheck: true,
    inputSize: 248,
    textAlign: "left",
    maxLength: 25,
    titleImage: "",
    questions: [
      {
        title: `
        <div style='display:flex; gap:90px;'>
            <div>
                <b>Direct speech</b>
                <div style='display:flex; gap:15px;'>
                    <b>1</b>
                    <div>today</div>
                </div>
                <div style='display:flex; gap:15px;'>
                    <b>2</b>
                    <div>a week ago</div>
                </div>
                <div style='display:flex; gap:15px;'>
                    <b>3</b>
                    <div>yesterday</div>
                </div>
                <div style='display:flex; gap:15px;'>
                    <b>4</b>
                    <div>last year</div>
                </div>
                <div style='display:flex; gap:15px;'>
                    <b>5</b>
                    <div>tomorrow</div>
                </div>
                <div style='display:flex; gap:15px;'>
                    <b>6</b>
                    <div>next month</div>
                </div>
                <div style='display:flex; gap:15px;'>
                    <b>7</b>
                    <div>here</div>
                </div>
            </div>
            <div>
                <b>Reported speech</b>
                <div>#</div>
                <div>#</div>
                <div>#</div>
                <div>#</div>
                <div>#</div>
                <div>#</div>
                <div>#</div>
            </div>
        </div>
			  `,
        answer: [
          "that day",
          "a week before",
          "the day before / the previous day",
          "the year before / the previous year",
          "the following day / the day after",
          "the following month / the month after",
          "there",
        ],
      },
    ],
  },

  4: {
    unit: "Unit 7",
    id: "WB10-U7-P59-E4",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page59/E4/key/answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title: `
				Complete the text with <i>said</i> or <i>told</i>.
			  `,
        color: "black",
      },
    ],
    // isHiddenCheck: true,
    stylesTextInput: {
      backgroundColor: "transparent",
    },
    inputSize: 90,
    textAlign: "center",
    maxLength: 85,
    titleImage: "",
    questions: [
      {
        title: `
            <div style='  width:680px; border-radius: 15px;'>
              <div style='padding: 15px 25px 25px 25px;' >
                <div>Dan 
                    <b><sup>1</sup>#</b>  Bev that he had bought her a new phone. 
She looked at it and 
                    <b><sup>2</sup>#</b> him that it was too big. 
<br/>He 
                    <b><sup>3</sup>#</b>  her that they were all that big nowadays. 
<br/>She 
                    <b><sup>4</sup>#</b> she wanted him to change it. He <br/>
                    <b><sup>5</sup>#</b> that he couldn't change it and 
                    <b><sup>6</sup>#</b> her that it had cost a lot of money. She 
                    <b><sup>7</sup>#</b> she 
didn't care and 
                    <b><sup>8</sup>#</b>  him she wouldn't use it. 
                </div>
              </div>
            </div>
			  `,
        answer: [
          "told",
          "told",
          "told",
          "said",
          "said",
          "told",
          "said",
          "told",
        ],
      },
    ],
  },
  5: {
    // Exercise num
    unit: "Unit 7",
    id: "WB10-U7-P59-E5",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page59/E5/Key/answerKey.png",
    textAlign: "left",
    inputSize: 900,
    titleQuestion: [
      {
        num: "5",
        title: `
                Rewrite the sentences as reported speech. If necessary, change the pronouns, possessive adjectives and references to time and place. 
				`,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
				<div style='display:flex; gap:15px;'>
					<b>1</b>
					<div>
						<div>John to Sue: ‘I left a message for you yesterday.'</div>
						<div><i style='color: rgb(1, 142, 211);'>John told</i> <input width='795px'/></div>
					</div>
				</div>
				<div style='display:flex; gap:15px;'>
					<b>2</b>
					<div>
						<div>Mark: ‘I'll top up my phone this evening.'</div>
						<div>#</div>
					</div>
				</div>
				<div style='display:flex; gap:15px;'>
					<b>3</b>
					<div>
						<div>Jenny to Dave: ‘I can't hear you.' </div>
						<div>#</div>
					</div>
				</div>
				<div style='display:flex; gap:15px;'>
					<b>4</b>
					<div>
						<div>Ann: ‘I've checked my balance three times this week.' </div>
						<div>#</div>
					</div>
				</div>
				<div style='display:flex; gap:15px;'>
					<b>5</b>
					<div>
						<div>Jo to Si: ‘You should turn off data roaming.'</div>
						<div>#</div>
					</div>
				</div>
				<div style='display:flex; gap:15px;'>
					<b>6</b>
					<div>
						<div>Kate: ‘I had already texted Harry at the weekend.'</div>
						<div>#</div>
					</div>
				</div>
				<div style='display:flex; gap:15px;'>
					<b>7</b>
					<div>
						<div>Fran to Fred: ‘I called Tom two days ago.'</div>
						<div>#</div>
					</div>
				</div>
        `,
        answer: [
          "Sue that he had left a message for her the previous day. /Sue that he had left a message for her  the day before.",
          "Mark said that he would top up his phone that evening.",
          "Jenny told Dave that she couldn't hear him.",
          "Ann said that she had checked her balance three times that week.",
          "Jo told Si that he should turn off data roaming.",
          "Kate said that she had already texted Harry at the weekend.",
          "Fran told Fred that he had called Tom two days before.",
        ],
      },
    ],
  },
  6: {
    // Exercise num
    unit: "Unit 7",
    id: "WB10-U7-P59-E6",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page59/E6/Key/answerKey.png",
    textAlign: "left",
    inputSize: 600,
    titleQuestion: [
      {
        num: "6",
        title: `
                Read the reported conversation between two identical twins. Write <br/> the direct speech below. 
				`,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
            <div style=" width: 750px; background-image: url('img/FriendsPlus/Page59/E6/1.jpg');  background-repeat: no-repeat;background-size: 750px 450px;">
                <div style='padding:40px 40px 20px 85px;'>
                    <div>Imo was walking down the road when she slipped and fell. Her twin sister Zoe phoned just afterwards. Zoe told Imo that she had fallen over a few moments before. Imo told her that was an amazing coincidence because she had just done the same! Zoe said her leg was hurting so much that she couldn't walk. Imo told Zoe that her own leg was OK and that she could easily walk home. But Imo told Zoe that if she couldn't walk, she should call an ambulance. Zoe said that wouldn't be necessary and that she could phone their parents. </div>
                </div>
            </div>

                    
				<div><b>1 &ensp;</b>Zoe: #</div>
				<div><b>2 &ensp;</b>Imo: #</div>
				<div>&emsp;&nbsp;because<input width='565px'/></div>
				<div><b>3 &ensp;</b>Zoe: #</div>
				<div><b>4 &ensp;</b>Imo: #</div>
				<div><b>5 &ensp;</b>Imo: But<input width='565px'/></div>
				<div><b>6 &ensp;</b>Zoe: #</div>
        `,
        answer: [
          " I fell over a few moments ago.",
          "This is an amazing coincidence ",
          " I have just done the same.",
          "My leg is hurting so much that I can't walk.",
          "My own leg is OK and I can easily walk home.",
          "if you can't walk, you should call an ambulance.",
          "It won't be necessary and I can call our parents.",
        ],
      },
    ],
  },
};

export default json;
