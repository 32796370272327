import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  6: {
    unit: "Cumulative",
    id: "FG10-C-P73-E6",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page73/E6/Key/answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "6",
        title: `
            <span style="color: rgb(0, 110, 147)"> GRAMMAR AND VOCABULARY</span> 
            Choose the best options to fill in the blanks
            `,
        color: "#000",
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "",
          color: "#000",
          textAlign: "center",
          maxLength: 16,
        },
        answers: [],
        initialValue: [],

      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
        },
        selectWordStyle: {
          border: "solid 3px",
          borderColor: "gray",
          padding: "5px",
        },
        limitSelect: 1,
        listWords: [
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
        ],
        answers: ['0-8', '1-4', '2-0', '3-0', '4-4', '5-4', '6-8', '7-4', '8-0', '9-8'],
        initialValue: [],
      },
      Layout: `
            <div style="margin-left: 100px">
                <div style="border: 3px solid rgb(0, 110, 147); height: 750px; width: 800px; border-radius: 30px; padding: 30px">
                    <h1 style="color: rgb(0, 110, 147); font-size: 40px; font-weight: 800; text-align: center" >TOUR BOAT DISASTER</h1>
                    <span>Last night, twenty tourists <strong>1</strong>___ on a boat in the Indian
                    Ocean when it started filling with water. The boat turned
                    over, but some of the passengers, including two British
                    women, <strong>2</strong>___ with it during the night. Twenty-one-year-old
                    Katherine Ostojic and her younger sister, nineteen-year-old
                    Alice, <strong>3</strong>___ on the part of the boat that was above the
                    water for ten hours. When they <strong>4</strong>___ any other boats,
                    they decided that they <strong>5</strong>___ to an island that they could
                    see on the horizon. But the island <strong>6</strong>___ as near as they
                    thought, and it took them over eight hours to get there. The
                    journey was very <strong>7</strong>___  . When they reached the island,
                    they saw that it had an active volcano. Fortunately, some
                    fishermen found the sisters and rescued them. Katherine
                    and Alice were very <strong>8</strong>___  , and now they are safe in Bali.
                    At the moment, they <strong>9</strong>___ emails to their parents so that
                    they <strong>10</strong>___ that their daughters are alive. Because of their
                    adventure, the two girls have changed their travel plans and
                    they are returning to the UK next weekend.
                    </span>
                </div>
                <div>
  
      <div style="position: relative; width: 800px; margin-top:10px;">
        
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;1 </b></div>
          <div style='flex: 3;'>are</div>
          <div style='flex: 3;'>was</div>
          <div style='flex: 3;'>were</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;2 </b></div>
          <div style='flex: 3;'>stay</div>
          <div style='flex: 3;'>stayed</div>
          <div style='flex: 3;'>stays</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;3 </b></div>
          <div style='flex: 3;'>sat</div>
          <div style='flex: 3;'>sit</div>
          <div style='flex: 3;'>sits</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;4 </b></div>
          <div style='flex: 3;'>didn’t see</div>
          <div style='flex: 3;'>don’t see</div>
          <div style='flex: 3;'>didn’t saw</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;5 </b></div>
          <div style='flex: 3;'>swam</div>
          <div style='flex: 3;'>should swim</div>
          <div style='flex: 3;'>swim</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;6 </b></div>
          <div style='flex: 3;'>isn’t</div>
          <div style='flex: 3;'>wasn’t</div>
          <div style='flex: 3;'>weren’t</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;7 </b></div>
          <div style='flex: 3;'>retired</div>
          <div style='flex: 3;'>tired</div>
          <div style='flex: 3;'>tiring</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;8 </b></div>
          <div style='flex: 3;'>cross</div>
          <div style='flex: 3;'>relieved</div>
          <div style='flex: 3;'>proud</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;9 </b></div>
          <div style='flex: 3;'>are writing</div>
          <div style='flex: 3;'>write</div>
          <div style='flex: 3;'>wrote</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>10 </b></div>
          <div style='flex: 3;'>are knowing</div>
          <div style='flex: 3;'>knew</div>
          <div style='flex: 3;'>know</div>
        </div>

        


        <div style="position: absolute; top: 0px; left: 49px; "><input id='0' type='Circle' /></div>
        <div style="position: absolute; top: 37px; left: 49px; "><input id='1' type='Circle' /></div>
        <div style="position: absolute; top: 76px; left: 49px; "><input id='2' type='Circle' /></div>
        <div style="position: absolute; top: 113px; left: 49px; "><input id='3' type='Circle' /></div>
        <div style="position: absolute; top: 152px; left: 49px; "><input id='4' type='Circle' /></div>
        <div style="position: absolute; top: 189px; left: 49px; "><input id='5' type='Circle' /></div>
        <div style="position: absolute; top: 227px; left: 49px; "><input id='6' type='Circle' /></div>
        <div style="position: absolute; top: 264px; left: 49px; "><input id='7' type='Circle' /></div>
        <div style="position: absolute; top: 302px; left: 49px; "><input id='8' type='Circle' /></div>
        <div style="position: absolute; top: 340px; left: 49px; "><input id='9' type='Circle' /></div>

      </div>
        `,
    },
  },
  7: {
    unit: "Cumulative",
    id: "FG10-C-P73-E7",
    audio: "",
    video: "",
    exerciseKey: "",
    component: T6,
    textareaStyle: { width: 1000, resize: 'none', borderBottom: 'none' },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "7",
        title: `
            <span style="color: rgb(0, 110, 147)"> WRITING</span> 
            Write a post about a frightening situation for an internet
            forum called <i>What a fright!</i> Include this information:
            `,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
            <div style="margin-left: 100px">
                <ul>
                    <li>where you were</li> 
                    <li>what happened</li> 
                    <li>how you felt</li> 
                    <li>how you feel about the event now.</li> 
                </ul>
                </div>

                <textarea id="1" rows="7" ></textarea>
            `,
        answer: [],
      },
    ],
  },
};

export default json;
