import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Unit 3",
    id: "WB10-U3-P24-E1",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page24/E1/key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `
        Find ten kinds of films or TV programmes in the word square → and ↓. Circle them and write them below.
			  `,
        color: "black",
      },
    ],
    titleImage: "",
    questionImage: [],
    maxLength: 10,
    inputSize: 170,
    fontSize: 30,

    questions: [
      {
        title: `
        <div style='display: flex;'>
          <div style='width:13cm'>
            <b>1</b>&emsp; g# s#<br>  
            <b>2</b>&emsp; d#<br>  
            <b>3</b>&emsp; r# c#<br>  
            <b>4</b>&emsp; r# s#<br>  
            <b>5</b>&emsp; p# d#<br>  
            <b>6</b>&emsp; a# f#<br>  
            <b>7</b>&emsp; c# s#<br>  
            <b>8</b>&emsp; t#<br>  
            <b>9</b>&emsp; s# o#<br>  
            <b>10</b>&emsp;s#<br>  

				  </div>
				  <img style='margin-left:5px;height:10cm;width:15cm' src='img/FriendsPlus/Page24/E1/1.jpg' />
        </div>
			  `,
        answer: [
          "ame",
          "how",
          "ocumentary",
          "omantic",
          "omedy",
          "eality",
          "how",
          "eriod",
          "rama",
          "ction",
          "ilm",
          "hat",
          "how",
          "hriller",
          "oap",
          "pera",
          "itcom",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 3",
    id: "WB10-U3-P24-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page24/E2/key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: `
        Identify the kinds of TV programmes and films. Choose from the words below.
			  `,
        color: "black",
      },
    ],
    titleImage: "",
    questionImage: [],
    inputSize: 240,
    textAlign: "center",
    maxLength: 20,
    questions: [
      {
        title: `
        <div style='color:rgb(135 48 136); width: 24cm'>
          animation&emsp;comedy&emsp;fantasy film&emsp;horror film&emsp;
          musical&emsp;news bulletin&emsp;science fiction film&emsp;western
        </div>
          <b>This kind of film or TV programme:</b>
        <div style='margin:10px 0px; line-height:45px'>
          <b>1</b>&ensp;usually has stories which take place in the future.#<br>
          <b>2</b>&ensp;often has characters with magical or supernatural powers.#<br>
          <b>3</b>&ensp;often has cowboys and Native Americans.#<br>
          <b>4</b>&ensp;often has frightening characters like vampires or ghosts.#<br>
          <b>5</b>&ensp;includes singing and dancing. #<br>
          <b>6</b>&ensp;tells you about important events in the world. #<br>
          <b>7</b>&ensp;includes cartoon characters, usually made with computers. #<br>
          <b>8</b>&ensp;makes you laugh.#<br>
        </div>
			  `,
        answer: [
          "science fiction film",
          "fantasy film",
          "western",
          "horror film",
          "musical",
          "news bulletin",
          "animation",
          "comedy",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "WB10-U3-P24-E3",
    audio: "",
    video: "",
    component: T6,
    maxLength: 20,
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    exerciseKey: "img/FriendsPlus/Page24/E3/key/answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: `
      Which words from exercises 1 and 2 are TV programmes only, never films? Which could be either films or TV programmes?
			  `,
        color: "black",
      },
    ],
    titleImage: "",
    questionImage: [],
    inputSize: 240,
    textAlign: "center",
    maxLength: 20,
    checkUppercase: true,
    checkDuplicated: true,
    questions: [
      {
        title: `
        <div style='width:24cm;line-height:46px'>
          Only TV: <i style='color:rgb(94 133 151)' >chat show</i>,#,#,#,#,#<br>
        
          TV or film: <i style='color:rgb(94 133 151)' >comedy</i>,#,#,#,#,#,#,#,#,#,#,#
        </div>
			  `,
        answer: [
          "game show/reality show/soap opera/sitcom/news bullentin",
          "game show/reality show/soap opera/sitcom/news bullentin",
          "game show/reality show/soap opera/sitcom/news bullentin",
          "game show/reality show/soap opera/sitcom/news bullentin",
          "game show/reality show/soap opera/sitcom/news bullentin",
          "documentary / romantic comedy / period drama / action film / thriller / science fiction film / fantasy film / western / horror film / musical / animation",
          "documentary / romantic comedy / period drama / action film / thriller / science fiction film / fantasy film / western / horror film / musical / animation",
          "documentary / romantic comedy / period drama / action film / thriller / science fiction film / fantasy film / western / horror film / musical / animation",
          "documentary / romantic comedy / period drama / action film / thriller / science fiction film / fantasy film / western / horror film / musical / animation",
          "documentary / romantic comedy / period drama / action film / thriller / science fiction film / fantasy film / western / horror film / musical / animation",
          "documentary / romantic comedy / period drama / action film / thriller / science fiction film / fantasy film / western / horror film / musical / animation",
          "documentary / romantic comedy / period drama / action film / thriller / science fiction film / fantasy film / western / horror film / musical / animation",
          "documentary / romantic comedy / period drama / action film / thriller / science fiction film / fantasy film / western / horror film / musical / animation",
          "documentary / romantic comedy / period drama / action film / thriller / science fiction film / fantasy film / western / horror film / musical / animation",
          "documentary / romantic comedy / period drama / action film / thriller / science fiction film / fantasy film / western / horror film / musical / animation",
          "documentary / romantic comedy / period drama / action film / thriller / science fiction film / fantasy film / western / horror film / musical / animation",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "WB10-U3-P24-E4",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page24/E4/key/answerKey.png",
    selectStyle: {
      color: "gray",
      fontWeight: "bold",
      width: 40,

      textAlign: "center",
      border: "2px solid black",
      borderRadius: "20%",
    },
    isHiddenCheck: true,

    selectOptionValues: ["✓", "✗"],
    titleQuestion: [
      {
        num: "4",
        title: `
				Complete the adjectives for describing films. Use a, e, i, o, u and y. Tick (✓) eight adjectives that usually have a positive meaning when describing film and cross (✗) six that usually have a negative meaning.
			  `,
        color: "black",
      },
    ],
    stylesTextInput: {
      borderBottom: "none",
      textAlign: "center",
      background: "white",
      fontSize: 30,
      color: "#2b292a",
    },
    maxLength: 1,
    inputSize: 38,

    titleImage: "",
    questions: [
      {
        title: `
          <div style="position: relative">
						<div> <img src='img/FriendsPlus/Page24/E4/1.jpg' /> </div>
            <div style=" position: absolute; top:6px; left: 546px; "><select id='43'></select></div>
						<div style=" position: absolute; top: 51px; left: 546px; "><select id='44'></select></div>
						<div style=" position: absolute; top: 96px; left: 546px; "><select id='45'></select></div>
						<div style=" position: absolute; top: 142px; left: 546px; "><select id='46'></select></div>
						<div style=" position: absolute; top: 187px; left: 546px; "><select id='47'></select></div>
						<div style=" position: absolute; top: 232px; left: 546px; "><select id='48'></select></div>
						<div style=" position: absolute; top: 278px; left: 546px; "><select id='49'></select></div>
						<div style=" position: absolute; top: 323px; left: 546px; "><select id='50'></select></div>
						<div style=" position: absolute; top: 368px; left: 546px; "><select id='51'></select></div>
						<div style=" position: absolute; top: 414px; left: 546px; "><select id='52'></select></div> 
						<div style=" position: absolute; top: 459px; left: 546px; "><select id='53'></select></div> 
						<div style=" position: absolute; top: 504px; left: 546px; "><select id='54'></select></div> 
						<div style=" position: absolute; top: 550px; left: 546px; "><select id='55'></select></div> 
						<div style=" position: absolute; top: 595px; left: 546px; "><select id='56'></select></div> 
						<div style=" position: absolute; top: 53px; left: 189px; ">#</div>
						<div style=" position: absolute; top: 53px; left: 259px; ">#</div>
						<div style=" position: absolute; top: 53px; left: 309px; ">#</div>
						<div style=" position: absolute; top: 98px; left: 201px; ">#</div>
						<div style=" position: absolute; top: 98px; left: 277px; "> #</div>
						<div style=" position: absolute; top: 144px; left: 219px; ">#</div>
						<div style=" position: absolute; top: 144px; left: 294px; ">#</div>
						<div style=" position: absolute; top: 189px; left: 204px; ">#</div>
						<div style=" position: absolute; top: 189px; left: 273px; ">#</div>
						<div style=" position: absolute; top: 189px; left: 326px; ">#</div>
						<div style=" position: absolute; top: 234px; left: 204px; ">#</div>
						<div style=" position: absolute; top: 234px; left: 277px; ">#</div>
						<div style=" position: absolute; top: 234px; left: 349px; ">#</div>
						<div style=" position: absolute; top: 280px; left: 189px; ">#</div>
						<div style=" position: absolute; top: 280px; left: 275px; ">#</div>
						<div style=" position: absolute; top: 280px; left: 338px; ">#</div>
						<div style=" position: absolute; top: 280px; left: 405px; ">#</div>
						<div style=" position: absolute; top: 325px; left: 189px; ">#</div>
						<div style=" position: absolute; top: 325px; left: 258px; ">#</div>
						<div style=" position: absolute; top: 325px; left: 314px; ">#</div>
						<div style=" position: absolute; top: 325px; left: 371px; ">#</div>
						<div style=" position: absolute; top: 325px; left: 423px; ">#</div>
						<div style=" position: absolute; top: 325px; left: 477px; ">#</div>
						<div style=" position: absolute; top: 370px; left: 221px; ">#</div>
						<div style=" position: absolute; top: 370px; left: 294px; ">#</div>
						<div style=" position: absolute; top: 370px; left: 347px; ">#</div>
						<div style=" position: absolute; top: 370px; left: 395px; ">#</div>
						<div style=" position: absolute; top: 416px; left: 189px; ">#</div>
						<div style=" position: absolute; top: 416px; left: 258px; ">#</div>
						<div style=" position: absolute; top: 416px; left: 304px; ">#</div>
						<div style=" position: absolute; top: 416px; left: 351px; ">#</div>
						<div style=" position: absolute; top: 416px; left: 417px; ">#</div>
						<div style=" position: absolute; top: 459px; left: 219px; ">#</div>
						<div style=" position: absolute; top: 459px; left: 269px; ">#</div>
						<div style=" position: absolute; top: 504px; left: 189px; ">#</div>
						<div style=" position: absolute; top: 504px; left: 259px; ">#</div>
						<div style=" position: absolute; top: 504px; left: 309px; ">#</div>
						<div style=" position: absolute; top: 504px; left: 375px; ">#</div>
						<div style=" position: absolute; top: 552px; left: 219px; ">#</div>
						<div style=" position: absolute; top: 552px; left: 273px; ">#</div>
						<div style=" position: absolute; top: 597px; left: 205px; ">#</div>
						<div style=" position: absolute; top: 597px; left: 251px; ">#</div>
						<div style=" position: absolute; top: 597px; left: 297px; ">#</div>
					</div>

			  `,
        answer: [
          "e",
          "i",
          "i",
          "u",
          "y",
          "i",
          "i",
          "o",
          "u",
          "i",
          "o",
          "i",
          "i",
          "e",
          "a",
          "a",
          "i",
          "i",
          "a",
          "i",
          "a",
          "i",
          "e",
          "e",
          "a",
          "u",
          "a",
          "u",
          "e",
          "a",
          "i",
          "i",
          "a",
          "y",
          "i",
          "e",
          "e",
          "i",
          "o",
          "i",
          "i",
          "o",
          "e",
          "✗",
          "✓",
          "✓",
          "✓",
          "✗",
          "✓",
          "✗",
          "✓",
          "✓",
          "✗",
          "✗",
          "✓",
          "✓",
          "✗",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 3",
    id: "WB10-U3-P24-E5",
    audio: "Audios/1.08.mp3",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page24/E5/Key/answerKey.png",
    titleQuestion: [
      {
        num: "5",
        title:
          "<headphone1 name='1.08'></headphone1> Listen to Anna and David discussing a film which they watched. Choose the correct answer.",
        color: "black",
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
          color: "black",
          fontWeight: "bold",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 3px",
          borderColor: "gray",
        },
        limitSelect: 1,
        listWords: [
          "a|&emsp;an&ensp;action&ensp;film.<br> b|&emsp;a&ensp;war&ensp;film.<br> c|&emsp;a&ensp;thriller.",
          "a|&emsp;Anna&ensp;and&ensp;David&ensp;both&ensp;liked&ensp;the&ensp;film.<br> b|&emsp;One&ensp;of&ensp;them&ensp;liked&ensp;the&ensp;film.<br> c|&emsp;Neither&ensp;of&ensp;them&ensp;liked&ensp;the&ensp;film.",
        ],
        answers: ["0-0", "1-0"],
        initialValue: [],
      },
      Layout: `
        <b>1</b>&emsp;Anna and David saw
        <div style='margin:0px 37px'> <input id='0' type='Circle' /> </div>
           
        <div style='display:flex'>
          <b>2</b>
          <div style='margin-left:24px
          '>
            <input id='1' type='Circle' /> 
          </div>
        </div>

        
      `,
    },
  },
  6: {
    // Exercise num
    unit: "Unit 3",
    id: "WB10-U3-P24-E6",
    audio: "Audios/1.08.mp3",
    video: "",
    component: D1,
    // textAlign: "center",
    maxLength: 11,
    fontSize: 33,
    // textAlign: "center",
    isHiddenCheck: true,
    inputHeight: 37,
    titleQuestion: [
      {
        num: "6",
        title:
          "<headphone1 name='1.08'></headphone1> Listen again. Complete the table with adjectives from exercise 4. (Sometimes the people have different opinions, and sometimes their opinions are the same.)",
        color: "black",
      },
    ],
    exerciseKey: "img/FriendsPlus/Page24/E6/Key/answerKey.png",
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page24/E6/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page24/E6/2.jpg" },
        {
          url: "img/FriendsPlus/Page24/E6/3.jpg",
          input: true,
          answer: "interesting",
        },
        {
          url: "img/FriendsPlus/Page24/E6/4.jpg",
          input: true,
          answer: "interesting",
        },
        { url: "img/FriendsPlus/Page24/E6/5.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page24/E6/6.jpg" },
        {
          url: "img/FriendsPlus/Page24/E6/7.jpg",
          input: true,
          answer: "spectacular",
        },
        {
          url: "img/FriendsPlus/Page24/E6/8.jpg",
          input: true,
          answer: "unrealistic",
        },
        { url: "img/FriendsPlus/Page24/E6/9.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page24/E6/10.jpg" },
        {
          url: "img/FriendsPlus/Page24/E6/11.jpg",
          input: true,
          answer: "interesting",
        },
        {
          url: "img/FriendsPlus/Page24/E6/12.jpg",
          input: true,
          answer: "interesting",
        },
        { url: "img/FriendsPlus/Page24/E6/13.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page24/E6/14.jpg" },
        {
          url: "img/FriendsPlus/Page24/E6/15.jpg",
          input: true,
          answer: "scary",
        },
        {
          url: "img/FriendsPlus/Page24/E6/16.jpg",
          input: true,
          answer: "violent",
        },
        { url: "img/FriendsPlus/Page24/E6/17.jpg" },
      ],
    ],
  },
};

export default json;
