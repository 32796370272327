import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 4",
    id: "WB10-U4-P39-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page39/E1/key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `
				Complete the zero conditional sentences with the correct form of the verbs in brackets.
			  `,
        color: "black",
      },
    ],
    // isHiddenCheck: true,
    inputSize: 120,
    textAlign: "center",
    maxLength: 15,
    titleImage: "",
    questions: [
      {
        title: `
        <div style='display:flex; gap:15px;'>
          <b>1</b>
          <div>If you #(burn) coal, it #(give) off greenhouse gases.</div>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>2</b>
          <div>A rainbow often #(appear) if the sun #(come) out when it's raining.</div>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>3</b>
          <div>If an earthquake #(happen) under the ocean, it sometimes #(cause) a tsunami. </div>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>4</b>
          <div>If a hurricane #(form) over the Indian Ocean, they #(call) it a typhoon.</div>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>5</b>
          <div>Floods #(happen) if it #(rain) a lot more than usual for a long time.</div>
        </div>
			  `,
        answer: [
          "burn",
          "gives",
          "appears",
          "comes",
          "happens",
          "causes",
          "forms",
          "call",
          "happen",
          "rains",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "WB10-U4-P39-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page39/E2/key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: `
				Read the <span style='color:rgb(36, 131, 197);'>Writing Strategy</span>. Then read the task below and the model text. Decide where the second paragraph should begin: A, B or C.
			  `,
        color: "black",
      },
    ],
    // isHiddenCheck: true,
    stylesTextInput: {
      backgroundColor: "transparent",
      border: "solid 1px",
      borderRadius: 5,
      height: 40,
      padding: 0,
      fontSize: 22,
    },
    inputSize: 40,
    textAlign: "center",
    maxLength: 1,
    isDuplicated: true,
    titleImage: "",
    questions: [
      {
        title: `  
            <div style='background-color: rgb(234, 239, 249); margin-top: 20px; margin-left:90px; width:750px; border-radius: 15px; border: 5px dotted rgb(36, 131, 197)'>
              <div style='padding: 15px 25px 25px 25px;' >
                <div ><b style='color:rgb(36, 131, 197) ;'>Writing Strategy</b></div>
                <div>When you are expressing your opinions and the opinions of others, give your text a clear structure. For example:</div>
                <ul>
                  <li>In one paragraph, give your opinion with two or three arguments that support it.</li>
                  <li>In another paragraph, give the opposing view with one or two arguments supporting it. You can then say why you disagree with the opposing view (a counter argument).</li>
                </ul>
              </div>
            </div>

          <div style='margin: 20px;'>The second paragraph should begin at #.</div>

          <img src='img/FriendsPlus/Page39/E2/1.jpg' style='width:700px;margin-left:90px;' />
			  `,
        answer: ["B"],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "WB10-U4-P39-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page39/E3/key/answerKey.png",
    selectStyle: {
      color: "black",
      fontWeight: "bold",
      width: 40,
      textAlign: "center",
      border: "2px solid black",
      borderRadius: "20%",
    },
    isHiddenCheck: true,

    selectOptionValues: ["✓", "✗"],
    titleQuestion: [
      {
        num: "3",
        title: `
				Add two phrases below to each group (A–D). Then tick (✓) the phrases that are in the model text.
			  `,
        color: "black",
      },
    ],
    inputSize: 410,
    textAlign: "left",
    background: "white",
    maxLength: 50,
    titleImage: "",
    questions: [
      {
        title: `
		       	<div style='color: rgb(33, 133, 198); margin:0px 40px 20px 40px;'><b>
          As I see it, … &emsp; Having said that, … &emsp; However, … &emsp; In my opinion, … &emsp; Moreover, … &emsp;
          Nevertheless, …  &emsp; On the other hand, … &emsp; To be honest, … &emsp; What is more, …</b></div>
        <div style='display:flex; gap:15px;'>
            <div>
          <div style='display:flex; gap:15px;'>
            <b>A</b>
            <div>
              <b>Expressing an opinion</b>
              <div style='display:flex; gap:15px; justify-content: space-between;'>
                <div><i  style='color: rgb(1, 142, 211);'>I think / I don't think that … </i></div>
                <select id='8'></select>
              </div>
              <div style='display:flex; gap:15px;'>
                <div>#</div>
                <select id='9'></select>
              </div>
              <div style='display:flex; gap:15px;'>
                <div>#</div>
                <select id='10'></select>
              </div>
            </div>
          </div>
          
          <div style='display:flex; gap:15px;'>
            <b>B</b>
            <div>
              <b>Presenting an opposing opinion</b>
              <div style='display:flex; gap:15px; justify-content: space-between;'>
                <div><i  style='color: rgb(1, 142, 211);'>There are people who believe that … </i></div>
                <select id='11'></select>
              </div>
              <div style='display:flex; gap:15px;'>
                <div>#</div>
                <select id='12'></select>
              </div>
              <div style='display:flex; gap:15px;'>
                <div>#</div>
                <select id='13'></select>
              </div>
            </div>
          </div>
          
          <div style='display:flex; gap:15px;'>
            <b>C</b>
            <div>
              <b>Presenting a counter-argument</b>
              <div style='display:flex; gap:15px; justify-content: space-between;'>
                <div><i  style='color: rgb(1, 142, 211);'>In spite of this, … </i></div>
                <select id='14'></select>
              </div>
              <div style='display:flex; gap:15px;'>
                <div>#</div>
                <select id='15'></select>
              </div>
              <div style='display:flex; gap:15px;'>
                <div>#</div>
                <select id='16'></select>
              </div>
            </div>
          </div>
          
          <div style='display:flex; gap:15px;'>
            <b>D</b>
            <div>
              <b>Making an additional point</b>
              <div style='display:flex; gap:15px; justify-content: space-between;'>
                <div><i  style='color: rgb(1, 142, 211);'>Furthermore, … </i></div>
                <select id='17'></select>
              </div>
              <div style='display:flex; gap:15px;'>
                <div>#</div>
                <select id='18'></select>
              </div>
              <div style='display:flex; gap:15px;'>
                <div>#</div>
                <select id='19'></select>
              </div>
            </div>
          </div>
       </div>
       <div><img src='img/FriendsPlus/Page39/E3/1.jpg' style='width: ;'</div>
        </div>

        
			  `,
        answer: [
          "In my opinion, …",
          "To be honest, …",
          "Having said that, …",
          "On the other hand, …",
          "However, …",
          "Nevertheless, …",
          "Moreover, …",
          "What is more, …",
          "✓",
          "✗",
          "✗",
          "✗",
          "✓",
          "✗",
          "✗",
          "✓",
          "✗",
          "✓",
          "✗",
          "✓",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "WB10-U4-P39-E4",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "",
    titleQuestion: [
      {
        num: "4",
        title: `
				Read the task and make a plan.
			  `,
        color: "black",
      },
    ],
    inputSize: 570,
    textAlign: "left",
    hideBtnFooter: true,
    titleImage: "",
    questions: [
      {
        title: `  
                <div  style='background-color:rgb(209, 222, 241); padding : 20px 30px; margin: 5px; width: 700px;'><b>‘Individuals can do more to stop global warming than governments.' Do you agree?</b></div>

				<b>Paragraph 1 (my opinion)</b>
        <ul>
					<li>Argument 1: #</li>
					<li>Argument 2: #</li>
        </ul>
				<b>Paragraph 2 (opposing view)</b>
        <ul>
					<li>Argument 1: #</li>
					<li>Argument 2: #</li>
					<li>Counter-argument: <input id='4' width='500px'/></li>
        </ul>

			  `,
        answer: [],
      },
    ],
  },
  5: {
    unit: "Unit 4",
    id: "WB10-U4-P39-E5",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "",
    titleQuestion: [
      {
        num: "5",
        title: `
				Write an article like the one in exercise 2. Use your notes from exercise 4 and include phrases from exercise 3.
			  `,
        color: "black",
      },
    ],
    textareaStyle: { width: 1000 },
    hideBtnFooter: true,
    titleImage: "",
    questions: [
      {
        title: `
        <div><img src='/img/FriendsPlus/Page39/E5/1.jpg' style='width:600px; margin-left: 100px;'/></div>
				 <textarea id="0" rows="7" ></textarea>
			  `,
        answer: [],
      },
    ],
  },
};

export default json;
