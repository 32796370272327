
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
  1: {
    // Exercise num
    unit: 'Unit 6',
    id: 'WB10-U6-P52-E1',
    audio: '',
    video: '',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '1',
        title: 'Circle the correct verb form in these sentences. Tick the box if both forms are correct.',
        color: '#000000',
      },
    ],
    exerciseKey: 'img/FriendsPlus/Page52/E1/Key/answerKey.png',
    
    question: {
      Write: {
          inputStyle: {},
          answers: [],
          initialValue: [],
   
      },
      Circle: {
        initialWordStyle: { padding: 0, border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 5, border: 'solid 2px', borderColor: '#2CBAEA' }, 
        limitSelect: 1,
        listWords: [
          "paying / to&ensp;pay  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;✓ ✗",
          "cycling / to&ensp;cycle  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; ✓ ✗",
          "earning / to&ensp;learn &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; ✓ ✗",
          "stealing / to&ensp;steal &emsp;&emsp;&emsp;&emsp;&emsp;&emsp; ✓ ✗",
          "staying / to&ensp;stay &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; ✓ ✗",
          "going / to&ensp;go &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; ✓ ✗",
          "seeing / to&ensp;see &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; ✓ ✗",
          "passing / to&ensp;pass &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; ✓ ✗",
          "phoning / to&ensp;phone &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; ✓ ✗",

        ],
        answers: [
          "0-4",
          "1-0",
          "2-8",
          "3-0",
          "4-0",
          "5-8",
          "6-8",
          "7-4",
          "8-4",
        ],
        initialValue: [],
      },
      Layout: /*html */ `
        <div style="display: flex; column-gap: 20px;">
          <strong>1</strong>
          <div style="display: flex; column-gap: 20px;  position: relative;">
            <span>My friends agreed <strong><input id="0" type="Circle" /></strong></span>
            <span style="position: absolute; left: 62%">for my ticket</span>  
          </div>        
        </div>
        
        <div style="display: flex; column-gap: 20px;">
          <strong>2</strong>
          <div style="display: flex; column-gap: 20px;  position: relative; ">
            <span>I don’t mind <strong><input id="1" type="Circle" /></strong></span>
            <span style="position: absolute; left: 61%">in the rain.</span>  
          </div>        
        </div>
        
        <div style="display: flex; column-gap: 20px;">
          <strong>3</strong>
          <div style="display: flex; column-gap: 20px;  position: relative; ">
            <span>I’ve started <strong><input id="2" type="Circle" /></strong></span>
            <span style="position: absolute; left: 58%">the saxophone.</span>  
          </div>        
        </div>
        
        <div style="display: flex; column-gap: 20px;">
          <strong>4</strong>
          <div style="display: flex; column-gap: 20px;  position: relative; ">
            <span>Two men have admitted <strong><input id="3" type="Circle" /></strong></span>
            <span style="position: absolute; left: 71%">the painting.</span>  
          </div>        
        </div>
        
        <div style="display: flex; column-gap: 20px;">
          <strong>5</strong>
          <div style="display: flex; column-gap: 20px;  position: relative; width: 700px">
            <span>We ended up <strong><input id="4" type="Circle" /></strong></span>
            <span style="position: absolute; left: 49%">at the hotel for a week  <br /> instead of two nights</span>  
          </div>        
        </div>
        
        <div style="display: flex; column-gap: 20px;">
          <strong>6</strong>
          <div style="display: flex; column-gap: 20px;  position: relative; width: 700px">
            <span>Do you like <strong><input id="5" type="Circle" /></strong></span>
            <span style="position: absolute; left: 41%">for walks on the beach?</span>  
          </div>        
        </div>
        
        <div style="display: flex; column-gap: 20px;">
          <strong>7</strong>
          <div style="display: flex; column-gap: 20px;  position: relative; width: 700px">
            <span>I hate <strong><input id="6" type="Circle" /></strong></span>
            <span style="position: absolute; left: 36%">animals in pain.</span>  
          </div>        
        </div>
        
        <div style="display: flex; column-gap: 20px;">
          <strong>8</strong>
          <div style="display: flex; column-gap: 20px;  position: relative; width: 700px">
            <span>I don’t expect <strong><input id="7" type="Circle" /></strong></span>
            <span style="position: absolute; left: 52%">all my exams.</span>  
          </div>        
        </div>
       
        
        <div style="display: flex; column-gap: 20px;">
          <strong>9</strong>
          <div style="display: flex; column-gap: 20px;  position: relative; width: 700px">
            <span>She offered <strong><input id="8" type="Circle" /></strong></span>
            <span style="position: absolute; left: 51%">me later. </span>  
          </div>        
        </div>
       
      `,
    },
  },
  2: {
    unit: 'Unit 6',
    id: 'WB10-U6-P52-E2',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '2',
        title: 'Complete the text with the infinitive or -ing form of the verbs in brackets. Sometimes both are correct.',
        color: '#000000',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      width: 100,
      textAlign: 'left',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    exerciseKey: 'img/FriendsPlus/Page52/E2/Key/answerKey.png',
    isHiddenCheck: true,
    questions: [
      // Row
      {
        title: /*html */ `
          <div style="position: relative;width: 900px; ">
            <img src="img/FriendsPlus/Page52/E2/1.png" style="width: 600px;height:1200px; object-fit: contain;"/>
            <div style="position: absolute;top: 37%; left: 70px; width: 460px; font-size: 18px ">
           
              At the end of a long journey, most people prefer
              1 <strong style="color: rgb(0 68 98);">to get</strong> (get) home and relax as soon as possible.
              They don't fancy <input /> (shop), but they don't
              want <input /> (arrive) home with nothing to eat.
              Now, travellers who pass through Gatwick Airport in
              London can avoid <input />  (return) home to an
              empty fridge by choosing <input /> (visit) a virtual
              shop. There are no real products in the shop, only images
              on ten large screens. When you decide <input /> (buy) something, you use your smartphone to scan it.
              You keep <input /> (scan) items until you've found
              everything you need, then you pay. After that, you can
              spend a week or two <input />  (enjoy) your holiday.
              Let the shop <input /> (deliver) your shopping soon
              after you get home. According to the retailer, the virtual
              shop is a perfect combination of traditional and online
              shopping. People enjoy <input /> (look) around
              shops, but they also love <input > (shop) online
              because it's so convenien
             
            </div>
          
            </div>
        `,
        answer: [
          "shopping",
          "to arrive",
          "returning",
          "to visit",
          "to buy",
          "scanning",
          "enjoying",
          "deliver",
          "looking",
          "shopping / to shop",
        ],
      },
    ],
  },
  3: {
    unit: 'Unit 6',
    id: 'WB10-U6-P52-E3',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '3',
        title: 'Study the dictionary entries and answer the questions',
        color: '#000000',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      width: 200,
      textAlign: 'left',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    // maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page52/E3/Key/answerKey.png',
    isHiddenCheck: true,
    questions: [
      // Row
      {
        title: /*html */ `
          <div>
            <strong style="margin-right: 20px">1</strong>
            <span>How many meanings are there for each verb? <input /></span>
          </div>
          <div>
            <strong style="margin-right: 20px">2</strong>
            <span>Which verb takes an infinitive? <input /></span>
          </div>
          <div>
            <strong style="margin-right: 20px">3</strong>
            <span>Which verb takes an -ing form? <input /></span>
          </div>
          <div>
            <strong style="margin-right: 20px">4</strong>
            <span>Which verb takes a bare form? <input /></span>
          </div>
          <div style="display: flex; column-gap: 20px; margin-top: 20px">
            <img src="img/FriendsPlus/Page52/E3/1.png" style="width: 700px;object-fit: contain;"/>
            <img src="img/FriendsPlus/Page52/E3/2.png" style="width: 700px;object-fit: contain;"/>
          </div>
        `,
        answer: [
          "two",
          "fail",
          "risk",
          "make",
        ],
      },
    ],
  },
  4: {
    unit: 'Unit 6',
    id: 'WB10-U6-P52-E4',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '4',
        title: 'Write example sentences to show the verb patterns for these verbs.',
        color: '#000000',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      width: 200,
      textAlign: 'left',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    // maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page52/E3/Key/answerKey.png',
    isHiddenCheck: true,
    hideBtnFooter: true,
    questions: [
      // Row
      {
        title: /*html */ `
        <div style="display: flex;column-gap: 20px">
          <strong>1</strong >
          <span style="display: flex;column-gap: 20px; justify-content: space-between; width: 550px">Hope <textarea style="border: none;" width="400px" id="0" rows="2" cols="50"></textarea></span>
        </div>
        <div style="display: flex;column-gap: 20px">
          <strong>2</strong >
          <span style="display: flex;column-gap: 20px; justify-content: space-between; width: 550px">fancy <textarea style="border: none;" width="400px" id="1" rows="2" cols="50"></textarea></span>
        </div>
        <div style="display: flex;column-gap: 20px">
          <strong>3</strong >
          <span style="display: flex;column-gap: 20px; justify-content: space-between; width: 550px">make <textarea style="border: none;" width="400px" id="2" rows="2" cols="50"></textarea></span>
        </div>
        <div style="display: flex;column-gap: 20px">
          <strong>4</strong >
          <span style="display: flex;column-gap: 20px; justify-content: space-between; width: 550px">can't stand <textarea style="border: none;" width="400px" id="3" rows="2" cols="50"></textarea></span>
        </div>
        `,
        answer: [],
      },
    ],
  },
}

export default json;