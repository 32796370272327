import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 4",
    id: "WB10-U4-P38-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page38/E1/key/answerKey.png",
    selectStyle: {
      color: "black",
      fontWeight: "bold",
      width: 40,
      textAlign: "center",
      border: "2px solid black",
      borderRadius: "20%",
    },
    isHiddenCheck: true,

    selectOptionValues: ["✓", "✗"],
    titleQuestion: [
      {
        num: "1",
        title: `
				Complete the phrases with the words below. Tick the phrases that are used for describing differences.
			  `,
        color: "black",
      },
    ],
    inputSize: 110,
    textAlign: "center",
    background: "white",
    maxLength: 10,
    titleImage: "",
    questions: [
      {
        title: `
				<div style='color: rgb(25, 158, 86); margin:0px 40px 20px 40px;'><b>
					both &emsp; can &emsp; contrast &emsp; first &emsp; obvious &emsp; photos &emsp; second &emsp; theme &emsp; Unlike
				</b></div>
        <div style='display:flex; gap:15px;'>
          <b>1</b>
          <div>Both # show …</div>
          <select id='9'></select>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>2</b>
          <div>In # to the first photo, the second photo … </div>
          <select id='10'></select>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>3</b>
          <div>In # photos, there are …</div>
          <select id='11'></select>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>4</b>
          <div>In the first photo … , but in the # photo, … </div>
          <select id='12'></select>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>5</b>
          <div>One # difference is (that) …</div>
          <select id='13'></select>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>6</b>
          <div>The common # in the photos is …</div>
          <select id='14'></select>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>7</b>
          <div>The # photo shows … , whereas the second photo shows …</div>
          <select id='15'></select>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>8</b>
          <div># the first photo, the second photo shows … </div>
          <select id='16'></select>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>9</b>
          <div>You # see … in both photos.</div>
          <select id='17'></select>
        </div>
			  `,
        answer: [
          "photos",
          "contrast",
          "both",
          "second",
          "obvious",
          "theme",
          "first",
          "Unlike",
          "can",
          "✗",
          "✓",
          "✗",
          "✓",
          "✓",
          "✗",
          "✓",
          "✓",
          "✗",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "WB10-U4-P38-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page38/E2/key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: `
				Read the text, ignoring the gaps. Answer the questions.
			  `,
        color: "black",
      },
    ],
    stylesTextInput: {
      backgroundColor: "transparent",
    },
    // isHiddenCheck: true,
    inputSize: 110,
    textAlign: "center",
    maxLength: 85,
    titleImage: "",
    questions: [
      {
        title: `
        <div style='display:flex; gap:15px;'>
          <div>
			<div style='color: rgb(25, 158, 86); margin:0px 40px 20px 40px;'><b>
			children &emsp; country &emsp; enjoying &emsp; fun &emsp; judging &emsp; jumping &emsp; must &emsp; see &emsp; shows &emsp; throwing &emsp; wearing</b></div>

            <div style='background-color: rgb(204, 225, 206);  width:700px; border-radius: 15px;'>
              <div style='padding: 15px 25px 25px 25px;' >
                <div>The first photo <b><sup>1</sup>#</b> two little girls. They are in the <b><sup>2</sup>#</b> walking on a path. It <b><sup>3</sup>#</b> be raining 
because the path is very wet and muddy. In the second photo 
I can <b><sup>4</sup>#</b> a man and a woman. They are playing in 
the snow.</div>
                <div>Both photos show two people having <b><sup>5</sup>#</b> in bad 
weather. In the first photo, the girls are <b><sup>6</sup>#</b> in 
a puddle. In the second photo, the man is <b><sup>7</sup>#</b> a 
snowball at the woman. You can see trees in both photos. </div>
                <div>In both photos, the people are <b><sup>8</sup>#</b> warm coats. 
The obvious difference is that they are <b><sup>9</sup>#</b> in the 
first photo, but adults in the second. <b><sup>10</sup>#</b> by their 
expressions, I'd say they are <b><sup>11</sup>#</b> themselves.</div>
              </div>
            </div>
          </div>
          <img src='img/FriendsPlus/Page38/E2/1.jpg' style='width:360px;' />
        </div>
			  `,
        answer: [
          "shows",
          "country",
          "must",
          "see",
          "fun",
          "jumping",
          "throwing",
          "wearing",
          "children",
          "Judging",
          "enjoying",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "WB10-U4-P38-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "",
    titleQuestion: [
      {
        num: "3",
        title: `
				Read the <span style='color:rgb(25, 158, 86);'>Speaking Strategy</span> and the task. Then write notes for your answer.
			  `,
        color: "black",
      },
    ],
    hideBtnFooter: true,
    titleImage: "",
    questions: [
      {
        title: `  
				
            <div style='background-color: rgb(204, 225, 206); margin-top: 20px; margin-left:30px; width:750px; border-radius: 15px; border: 5px dotted rgb(25, 158, 86)'>
              <div style='padding: 15px 25px 15px 25px;' >
                <div ><b style='color:rgb(25, 158, 86) ;'>Speaking Strategy</b></div>
				<div>When you do a photo comparison task, follow these steps:</div>
                <ol>
					<li>Describe each photo in general (e.g. mention the people, where they are, speculate about what is happening, etc.).</li>
					<li>Describe any obvious differences or similarities between the photos.</li>
					<li>Speculate about how the people in the photo are feeling, 
or what they are thinking or saying.</li>
                </ol>
              </div>
       		 </div>
          <div>
          <img src='img/FriendsPlus/Page38/E3/1.jpg' style='width:800px; margin: 10px;' />
		  <div><b>Describe and compare the two photos, C and D.</b></div>
            <div style='display:flex; gap:50px; margin-top: 10px;'>
              <b>1</b>
              <div>
             	 <div>First photo: Description </div>
				 <textarea id="0" rows="3" ></textarea>
			  </div>
            </div>
            <div style='display:flex; gap:50px; margin-top: 10px;'>
              <b>2</b>
              <div>
             	 <div>Second photo: Description </div>
				 <textarea id="1" rows="3" ></textarea>
			  </div>
            </div>
            <div style='display:flex; gap:50px; margin-top: 10px;'>
              <b>3</b>
              <div>
             	 <div>Obvious differences and similarities </div>
				 <textarea id="2" rows="3" ></textarea>
			  </div>
            </div>
            <div style='display:flex; gap:50px; margin-top: 10px;'>
              <b>4</b>
              <div>
             	 <div>How are the people feeling? </div>
				 <textarea id="3" rows="3" ></textarea>
			  </div>
            </div>
          </div>

			  `,
        answer: [],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "WB10-U4-P38-E4",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "",
    titleQuestion: [
      {
        num: "4",
        title: `
				Now do the task from exercise 3 using your notes.
			  `,
        color: "black",
      },
    ],
    hideBtnFooter: true,
    titleImage: "",
    questions: [
      {
        title: `  
          <div>
          <img src='img/FriendsPlus/Page38/E4/1.jpg' style='width:800px; margin: 10px;' />
		  <div><b>Describe and compare the two photos, C and D.</b></div>
			<textarea id="0" rows="10" ></textarea>
			  `,
        answer: [],
      },
    ],
  },
};

export default json;
