import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots'

const json = {
	1: {
		unit: 'Unit 7',
		id: 'FG10-U7-P57-E1',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page57/E1/Key/answerKey.png',
		component: T6,
		inputSize: 200,
		// isHiddenCheck: true,
		maxLength: 20,
		// hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '1',
				title: `
		Complete the sentences with the verbs in brackets. Use the
		present perfect.
				`,
				color: '#000',
			},
		],
		questions: [
			{
				title: `
		<div style="margin-top: 30px">
			<ol type="1" style="font-weight: bold; display: flex; flex-direction: column; gap: 20px 0;">
				<li><span style="font-weight: normal;">Poor Jeff. He  # (break) his arm.</span></li>
				<li><span style="font-weight: normal;">Look! The sun # (come out).</span></li>
				<li><span style="font-weight: normal;">John # (not visit) Italy.</span></li>
				<li><span style="font-weight: normal;">Oh dear. I  # (lose) my dictionary.</span></li>
				<li><span style="font-weight: normal;">We # (not finish) our homework.</span></li>
				<li><span style="font-weight: normal;">Harry # not read) the Hunger Games
				books, but he'd like to.</span></li>
			</ol>
		</div>
				`,
				answer: [
					"'s broken",
					'has come out',
					"hasn't visited",
					"'ve lost",
					"haven't finished",
					"hasn't read",
				],
			},
		],
	},
	2: {
		unit: 'Unit 7',
		id: 'FG10-U7-P57-E2',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page57/E2/Key/answerKey.png',
		component: T6,
		inputSize: 150,
		stylesTextInput: {
			backgroundColor: 'transparent',
			fontSize: '22px',
		},
		// isHiddenCheck: true,
		maxLength: 10,
		// hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '2',
				title: `
			Complete the postcard with the present perfect form of
			the verbs below.
				`,
				color: '#000',
			},
		],
		questions: [
			{
				title: `
					<div style="color: rgb(246, 152, 71); font-weight: bold; margin-bottom: 30px;">
						<span style= "margin-right: 70px">be</span>
						<span style= "margin-right: 70px">be</span>
						<span style= "margin-right: 70px">buy</span>
						<span style= "margin-right: 70px">go</span>
						<span style= "margin-right: 70px">not go</span>
						<span style= "margin-right: 70px">not rain</span>
						<span style= "margin-right: 70px">take</span>
						<span style= "margin-right: 70px">visit</span>
					</div>
					<div style="background-image: url('img/FriendsPlus/Page57/E2/1.jpg'); background-repeat: no-repeat; background-size: 900px auto; height: 1130px;width:900px; padding: 20px 100px 30px 30px; border-radius: 16px; position: relative; font-size: 22px">
						<div style="width: 550px; position: absolute; top: 550px; padding: 30px">
							<span>Dear Amy,
							We <sup><strong>1</strong></sup> # in the USA for
							two weeks. The weather <sup><strong>2</strong></sup> #
							fantastic – it <sup><strong>3</strong></sup> # once!
							We <sup><strong>4</strong></sup> # two cities – San
							Diego and Los Angeles. Now we're in San
							Francisco. It's a great city. We
							<sup><strong>5</strong></sup> # shopping, and I <sup><strong>6</strong></sup> # some clothes.
							I <sup><strong>7</strong></sup> # lots of photos, which
							I'll upload to my blog later. We
							<sup><strong>8</strong></sup> # to Alcatraz island
							yet – we're going there tomorrow. I'm really
							looking forward to it!
							Love, Rose</span>
						</div>
						<div style="position: absolute; right: 140px; top: 650px">
							<span>Amy White</span></br>
							<span>20 Oxford Road</span></br>
							<span>Abingdon</span></br>
							<span>UK</span></br>
						</div>
					</div>
				`,
				answer: [
					"'ve been",
					'has been',
					"hasn't rained",
					"'ve visited ",
					"'ve been",
					"'ve bought",
					"'ve taken",
					"haven't been",
				],
			},
		],
	},
	3: {
		unit: 'Unit 7',
		id: 'FG10-U7-P57-E3',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page57/E3/Key/answerKey.png',
		component: T6,
		inputSize: 350,
		isHiddenCheck: true,
		maxLength: 50,
		// hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '3',
				title: `
		Hannah is on holiday in Greece. Write questions and
		answers about the things she has and has not done.
				`,
				color: '#000',
			},
		],
		questions: [
			{
				title: `
				<div style="margin-top: 30px">
					<ol type="1" style="font-weight: bold; display: flex; flex-direction: column; gap: 20px 0;">
						<div>
							<li><span style="font-weight: normal;">visit Athens ✗</span></li>
							<span style="color: rgb(51, 96, 165); font-weight: normal;"><i>Has she visited Athens? No, she hasn't</i></span>
						</div>
						<div>
							<li><span style="font-weight: normal;">spend lots of money ✓</span></li>
							<span style="color: rgb(51, 96, 165); font-weight: normal;"># ? #</span>
						</div>
						<div>
							<li><span style="font-weight: normal;">go windsurfing ✓</span></li>
							<span style="color: rgb(51, 96, 165); font-weight: normal;"># ? #</span>
						</div>
						<div>
							<li><span style="font-weight: normal;">read lots of books ✗</span></li>
							<span style="color: rgb(51, 96, 165); font-weight: normal;"># ? #</span>
						</div>
						<div>
							<li><span style="font-weight: normal;">swim in the sea ✓</span></li>
							<span style="color: rgb(51, 96, 165); font-weight: normal;"># ? #</span>
						</div>
						<div>
							<li><span style="font-weight: normal;">send many postcards ✗</span></li>
							<span style="color: rgb(51, 96, 165); font-weight: normal;"># ? #</span>
						</div>
						
					</ol>
			  	</div>
				`,
				answer: [
					'Has she spent lots of money',
					'Yes, she has.',
					'Has she gone windsurfing',
					'Yes, she has.',
					'Has she read lots of books',
					"No, she hasn't.",
					'Has she swum in the sea',
					'Yes, she has.',
					'Has she sent many postcards',
					"No, she hasn't.",
				],
			},
		],
	},
	4: {
		unit: 'Unit 7',
		id: 'FG10-U7-P57-E4',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page57/E4/Key/answerKey.png',
		component: T6,
		inputSize: 120,
		// isHiddenCheck: true,
		maxLength: 10,
		// hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '4',
				title: `
		Complete the sentences with <i>has / have been, has / have
		gone</i> or <i>went</i>.
				`,
				color: '#000',
			},
		],
		questions: [
			{
				title: `
				  <div style="margin-top: 30px">
					<ol type="1" style="font-weight: bold; display: flex; flex-direction: column; gap: 20px 0;">
						<li><span style="font-weight: normal;">Lucy isn't at home. She<i style="color: rgb(72, 177, 242)">'s gone</i> to the sports centre.</span></li>
						<li><span style="font-weight: normal;">We haven't had a holiday this year, but last year we #  to Brazil.
						</span></li>
						<li><span style="font-weight: normal;">We've got plenty of food – I # to the
						supermarket yesterday.</span></li>
						<li><span style="font-weight: normal;">She knows Madrid very well. She # there
						lots of times since she moved to Spain.</span></li>
						<li><span style="font-weight: normal;">I love water sports. I # surfing in lots of
						different countries.</span></li>
						<li><span style="font-weight: normal;">I hope Toby is enjoying the summer. He # to Italy for six weeks.</span></li>
					</ol>
				  </div>
				`,
				answer: ['went', 'went', "'s been", "'ve been", "'s gone"],
			},
		],
	},
	5: {
		unit: 'Unit 7',
		id: 'FG10-U7-P57-E5',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page57/E5/Key/answerKey.png',
		component: T6,
		inputSize: 150,
		isHiddenCheck: true,
		maxLength: 20,
		// hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '5',
				title: `
		Complete the mini-dialogues. Use the correct present
		perfect or past simple form of the verbs in brackets.
				`,
				color: '#000',
			},
		],
		questions: [
			{
				title: `
		<div style="margin-top: 30px">
			<ol type="1" style="font-weight: bold">
				<li>
					<ol type="A" style="font-weight: bold">
					<li style="margin-bottom: 10px"><span style="font-weight: normal;"># ever # (you / have) a
					holiday in Turkey?</span></li>
					<li style="margin-bottom: 10px"><span style="font-weight: normal;">Yes, I #. I # (spend) two
					weeks there last year.</span></li>
					</ol>
					<ol type="A" style="font-weight: bold">
					<li style="margin-bottom: 10px"><span style="font-weight: normal;">Which cities # (you / visit)?
					</span></li>
					<li style="margin-bottom: 10px"><span style="font-weight: normal;">Only two. We # (start) in Istanbul and
					then # (travel) to Izmir.
					</span></li>
					</ol>
				</li>
				<li>
					<ol type="A" style="font-weight: bold">
					<li style="margin-bottom: 10px"><span style="font-weight: normal;"># ever # (you / try) an
					extreme sport?</span></li>
					<li style="margin-bottom: 10px"><span style="font-weight: normal;">No, I #. But I # (learn) to ski
					last winter.</span></li>
					</ol>
					<ol type="A" style="font-weight: bold">
					<li style="margin-bottom: 10px"><span style="font-weight: normal;">Really? # (you / enjoy) it?
					</span></li>
					<li style="margin-bottom: 10px"><span style="font-weight: normal;">Yes, I # . In fact, yesterday I # (book) my next skiing holiday!
					</span></li>
					</ol>	
				</li>
				<li>
					<ol type="A" style="font-weight: bold">
					<li style="margin-bottom: 10px"><span style="font-weight: normal;"># ever # (you / go) on a
					coach tour?</span></li>
					<li style="margin-bottom: 10px"><span style="font-weight: normal;">Yes, we #. We # (go) across
					the USA by coach last July.</span></li>
					</ol>
					<ol type="A" style="font-weight: bold">
					<li style="margin-bottom: 10px"><span style="font-weight: normal;">How long # (it / take)?
					</span></li>
					<li style="margin-bottom: 10px"><span style="font-weight: normal;">Three weeks. We # (stop) at lots of
					interesting places on the way.
					</span></li>
					</ol>
				</li>
				<li>
					<ol type="A" style="font-weight: bold">
					<li style="margin-bottom: 10px"><span style="font-weight: normal;"># ever # (you / eat) insects?</span></li>
					<li style="margin-bottom: 10px"><span style="font-weight: normal;">Yes, I #. I # (buy) a bag of
					insects as a snack in Thailand. But I # (not
					enjoy) them very much.</span></li>
					</ol>
				</li>
					
			</ol>
	  	</div>
				`,
				answer: [
					'Have you',
					'had',
					'have',
					'spent',
					'did you visit',
					'started',
					'travelled',
					'Have you',
					'tried',
					"haven't",
					'learned',
					'Did you enjoy',
					'did',
					'booked',
					'Have you',
					'gone',
					'have',
					'went',
					'did it take',
					'stopped',
					'Have you',
					'eaten',
					'have',
					'bought',
					"didn't enjoy",
				],
			},
		],
	},
}

export default json
