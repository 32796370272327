import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

let answerObj = 'dentistprogrammerpilothairdresserparamedicsportscoachreceptionistengineersolicitorarchitecttravelagentcleanerwaiter'

const ArrAnwser = []
for (let i = 0; i < answerObj.length; i++) {
  ArrAnwser.push(answerObj[i])
}

const json = {
  1: {
    unit: 'Unit 5',
    id: 'WB10-U5-P40-E1',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '1',
        title: 'Read the definitions and complete the puzzle.',
        color: '#000000',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      textAlign: 'center',
      backgroundColor: 'transparent',
    },
    inputSize: 36,
    // maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page40/E1/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */`
        <div style=' position: relative; '>
      
            <div><img src='img/FriendsPlus/Page40/E1/1.jpg' /></div>
              
            <div style=" position: absolute; top: 11.8px; left: 609px;"><input   maxlength="1"/></div>
              <div style=" position: absolute; top: 11.8px; left: calc(609px + 36px);" ><input   maxlength="1"/></div>
              <div style=" position: absolute; top: 11.8px; left: calc(609px + 36px * 2)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top: 11.8px; left: calc(609px + 36px * 3)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top: 11.8px; left: calc(609px + 36px * 4)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top: 11.8px; left: calc(609px + 36px * 5)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top: 11.8px; left: calc(609px + 36px * 6)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top: calc(12px + 33px); left: 609px;"><input   maxlength="1"/></div>
              <div style=" position: absolute; top: calc(12px + 33px); left: calc(609px + 36px)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top: calc(12px + 33px); left: calc(609px + 36px * 2)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top: calc(12px + 33px); left: calc(609px + 36px * 3)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top: calc(12px + 33px); left: calc(609px + 36px * 4)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top: calc(12px + 33px); left: calc(609px + 36px * 5)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top: calc(12px + 33px); left: calc(609px + 36px * 6)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top: calc(12px + 33px); left: calc(609px + 36px * 7)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top: calc(12px + 33px); left: calc(609px + 36px * 8)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top: calc(12px + 33px); left: calc(609px + 36px * 9)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top: calc(12px + 33px * 2); left: calc(609px + 36px * 3)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top: calc(12px + 33px * 2); left: calc(609px + 36px * 4)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top: calc(12px + 33px * 2); left: calc(609px + 36px * 5)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top: calc(12px + 33px * 2); left: calc(609px + 36px * 6)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top: calc(12px + 33px * 2); left: calc(609px + 36px * 7)"><input   maxlength="1"/></div>
            <div style=" position: absolute; top: calc(12px + 33.5px * 4); left: calc(609px - 37px * 2)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top: calc(12px + 33.5px * 4); left: calc(609px - 37px )"><input   maxlength="1"/></div>
              <div style=" position: absolute; top: calc(12px + 33.5px * 4); left: calc(609px)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top: calc(12px + 33.5px * 4); left: calc(609px + 36px * 1)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top: calc(12px + 33.5px * 4); left: calc(609px + 36px * 2)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top: calc(12px + 33.5px * 4); left: calc(609px + 36px * 3)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top: calc(12px + 33.5px * 4); left: calc(609px + 36px * 4)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top: calc(12px + 33.5px * 4); left: calc(609px + 36px * 5)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top: calc(12px + 33.5px * 4); left: calc(609px + 36px * 6)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top: calc(12px + 33.5px * 4); left: calc(609px + 36px * 7)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top: calc(12px + 33.5px * 4); left: calc(609px + 36px * 8)"><input   maxlength="1"/></div>
            <div style=" position: absolute; top: calc(12px + 33.7px * 5); left: calc(609px + 36px * 2)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.7px * 5); left: calc(609px + 36.4px * 3)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.7px * 5); left: calc(609px + 36.4px * 4)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.7px * 5); left: calc(609px + 36.4px * 5)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.7px * 5); left: calc(609px + 36.4px * 6)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.7px * 5); left: calc(609px + 36.4px * 7)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.7px * 5); left: calc(609px + 36.4px * 8)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.7px * 5); left: calc(609px + 36.4px * 9)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.7px * 5); left: calc(609px + 36.4px * 10)"><input   maxlength="1"/></div>
            <div style=" position: absolute; top: calc(12px + 33.7px * 6); left: calc(609px)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top: calc(12px + 33.7px * 6); left: calc(609px + 36px)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top: calc(12px + 33.7px * 6); left: calc(609px + 36px * 2)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.7px * 6); left: calc(609px + 36.4px * 3)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.7px * 6); left: calc(609px + 36.4px * 4)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.7px * 6); left: calc(609px + 36.4px * 5)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.7px * 6); left: calc(609px + 36.4px * 6)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.7px * 6); left: calc(609px + 36.4px * 7)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.7px * 6); left: calc(609px + 36.4px * 8)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.7px * 6); left: calc(609px + 36.4px * 9)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.7px * 6); left: calc(609px + 36.4px * 10)"><input   maxlength="1"/></div>
            <div style=" position: absolute; top: calc(12px + 33.8px * 7); left: calc(609px - 36.5px * 5)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top: calc(12px + 33.8px * 7); left: calc(609px - 36.5px * 4)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top: calc(12px + 33.8px * 7); left: calc(609px - 36.5px * 3)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 7); left: calc(609px - 36.4px * 2)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 7); left: calc(609px - 36.4px)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 7); left: calc(609px )"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 7); left: calc(609px + 36.4px)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 7); left: calc(609px + 36.4px * 2)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 7); left: calc(609px + 36.4px * 3)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 7); left: calc(609px + 36.4px * 4)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 7); left: calc(609px + 36.4px * 5)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 7); left: calc(609px + 36.4px * 6)"><input   maxlength="1"/></div>
            <div style=" position: absolute; top: calc(12px + 33.8px * 8); left: calc(609px + 36px * 2)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 8); left: calc(609px + 36.4px * 3)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 8); left: calc(609px + 36.4px * 4)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 8); left: calc(609px + 36.4px * 5)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 8); left: calc(609px + 36.4px * 6)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 8); left: calc(609px + 36.4px * 7)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 8); left: calc(609px + 36.4px * 8)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 8); left: calc(609px + 36.4px * 9)"><input   maxlength="1"/></div>
            <div style=" position: absolute; top: calc(12px + 33.8px *9); left: calc(609px + 36.2px * 5)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 9); left: calc(609px + 36.4px * 6)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 9); left: calc(609px + 36.4px * 7)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 9); left: calc(609px + 36.4px * 8)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 9); left: calc(609px + 36.4px * 9)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 9); left: calc(609px + 36.4px * 10)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 9); left: calc(609px + 36.4px * 11)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 9); left: calc(609px + 36.4px * 12)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 9); left: calc(609px + 36.4px * 13)"><input   maxlength="1"/></div>
            <div style=" position: absolute; top: calc(12px + 33.8px * 10); left: calc(609px)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 10); left: calc(609px + 36.4px * 1)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 10); left: calc(609px + 36.4px * 2)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 10); left: calc(609px + 36.4px * 3)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 10); left: calc(609px + 36.4px * 4)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 10); left: calc(609px + 36.4px * 5)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 10); left: calc(609px + 36.4px * 6)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 10); left: calc(609px + 36.4px * 7)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 10); left: calc(609px + 36.4px * 8)"><input   maxlength="1"/></div>
            <div style=" position: absolute; top: calc(12px + 33.8px * 11); left: calc(609px - 36.2px)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 11); left: calc(609px)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 11); left: calc(609px + 36.4px)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 11); left: calc(609px + 36.4px * 2)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 11); left: calc(609px + 36.4px * 3)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 11); left: calc(609px + 36.4px * 4)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 11); left: calc(609px + 36.4px * 5)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 11); left: calc(609px + 36.4px * 6)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 11); left: calc(609px + 36.4px * 7)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 11); left: calc(609px + 36.4px * 8)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 11); left: calc(609px + 36.4px * 9)"><input   maxlength="1"/></div>
            <div style=" position: absolute; top: calc(12px + 33.8px * 12); left: calc(609px + 36.2px)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 12); left: calc(609px + 36.4px * 2)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 12); left: calc(609px + 36.4px * 3)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 12); left: calc(609px + 36.4px * 4)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 12); left: calc(609px + 36.4px * 5)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 12); left: calc(609px + 36.4px * 6)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 12); left: calc(609px + 36.4px * 7)"><input   maxlength="1"/></div>
            <div style=" position: absolute; top: calc(12px + 33.8px * 13); left: calc(609px + 36px * 2)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 13); left: calc(609px + 36.4px * 3)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 13); left: calc(609px + 36.4px * 4)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 13); left: calc(609px + 36.4px * 5)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 13); left: calc(609px + 36.4px * 6)"><input   maxlength="1"/></div>
              <div style=" position: absolute; top:calc(12px + 33.8px * 13); left: calc(609px + 36.4px * 7)"><input   maxlength="1"/></div>  
            <div style="position: absolute; top:542px; left: 222px"> <input id='113' width='303px' maxlength="50"/></div>  
              
					</div>
				`,

        answer: [...ArrAnwser, 'sales assistant'],
      },
    ],
  },
  2: {
    // Exercise num
    unit: 'Unit 5',
    id: 'WB10-U5-P40-E2',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '2',
        title: 'Complete the work activities with the words below. Use </br> each word or phrase only once',
        color: '#000000',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      textAlign: 'center',
      backgroundColor: 'transparent',
    },
    inputSize: 30,
    exerciseKey: 'img/FriendsPlus/Page40/E2/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      // Row
      {
        title: /*html */ `
					<div style='position: relative;'>
            <div style="width: 540px;">
              <div style="display: flex; flex-wrap:wrap; column-gap: 20px;color: rgb(132 36 129)">
                <span style="font-size: 20px; font-weight: 600">alone</span>
                <span style="font-size: 20px; font-weight: 600;">children</span>
                <span style="font-size: 20px; font-weight: 600;">a computer</span>
                <span style="font-size: 20px; font-weight: 600;">customer</span>
                <span style="font-size: 20px; font-weight: 600;">your feet</span>
                <span style="font-size: 20px; font-weight: 600;">five</span>
                <span style="font-size: 20px; font-weight: 600;">hours</span>
                <span style="font-size: 20px; font-weight: 600;">indoors</span>
                <span style="font-size: 20px; font-weight: 600;">a lot</span>
                <span style="font-size: 20px; font-weight: 600;">a lot of money</span>
                <span style="font-size: 20px; font-weight: 600;">outdoors</span>
                <span style="font-size: 20px; font-weight: 600;">the phone</span>
                <span style="font-size: 20px; font-weight: 600;">phone calls</span>
                <span style="font-size: 20px; font-weight: 600;">the public</span>
                <span style="font-size: 20px; font-weight: 600;">a team</span>
                <span style="font-size: 20px; font-weight: 600;">a unifrom</span>
              </div>
            </div>
          <div style="width: 400px;">
          
          <div style="display: flex; column-gap: 5px; justify-content: space-between;">
              <div>
                <span style="font-size: 20px; font-weight: 600; margin: 7px 0px 0px 8px ;">1</span>
                <span style="font-size: 20px; font-weight: 500; margin: 7px 0px 0px 8px ;">travel</span>
              </div>
              <input  width="200px" />
            </div>

            <div style="display: flex; column-gap: 5px; justify-content: space-between;">
              <div>
                <span style="font-size: 20px; font-weight: 600; margin: 7px 0px 0px 8px ;">2</span>
                <span style="font-size: 20px; font-weight: 500; margin: 7px 0px 0px 8px ;">be on</span>
              </div>
              <input  width="200px" />
            </div>

            <div style="display: flex; column-gap: 5px; justify-content: space-between;">
              <div>
                <span style="font-size: 20px; font-weight: 600; margin: 7px 0px 0px 8px ;">3</span>
                <span style="font-size: 20px; font-weight: 500; margin: 7px 0px 0px 8px ;">serve</span>
              </div>
              <input  width="200px" />
            </div>

            <div style="display: flex; column-gap: 5px; justify-content: space-between;">
              <div>
                <span style="font-size: 20px; font-weight: 600; margin: 7px 0px 0px 8px;">4</span>
                <span style="font-size: 20px; font-weight: 500; margin: 7px 0px 0px 8px;">work</span>
              </div>
              <input  width="200px" />
            </div>
            <div style="display: flex; column-gap: 5px; justify-content: space-between;">
              <div>
              </div>
              <input  width="200px"  />
            </div>
            
            <div style="display: flex; column-gap: 5px; justify-content: space-between;">
              <div>
              </div>
              <input  width="200px"  />
            </div>

            <div style="display: flex; column-gap: 5px; justify-content: space-between;">
              <div>
                <span style="font-size: 20px; font-weight: 600; margin: 7px 0px 0px 8px ;">5</span>
                <span style="font-size: 20px; font-weight: 500; margin: 7px 0px 0px 8px ;">wear</span>
              </div>
              <input  width="200px" />
            </div>

            <div style="display: flex; column-gap: 5px; justify-content: space-between;">
              <div>
                <span style="font-size: 20px; font-weight: 600; margin: 7px 0px 0px 8px ;">6</span>
                <span style="font-size: 20px; font-weight: 500; margin: 7px 0px 0px 8px ;">deal with</span>
              </div>
              <input  width="200px" />
            </div>

            <div style="display: flex; column-gap: 5px; justify-content: space-between;">
              <div>
                <span style="font-size: 20px; font-weight: 600; margin: 7px 0px 0px 8px ;">7</span>
                <span style="font-size: 20px; font-weight: 500; margin: 7px 0px 0px 8px ;">be part of</span>
              </div>
              <input  width="200px" />
            </div>

            <div style="display: flex; column-gap: 5px; justify-content: space-between;">
              <div>
                <span style="font-size: 20px; font-weight: 600; margin: 7px 0px 0px 8px ;">8</span>
                <span style="font-size: 20px; font-weight: 500; margin: 7px 0px 0px 8px ;">work nime-to-</span>
              </div>
              <input  width="200px" />
            </div>

            <div style="display: flex; column-gap: 5px; justify-content: space-between;">
              <div>
                <span style="font-size: 20px; font-weight: 600; margin: 7px 0px 0px 8px ;">9</span>
                <span style="font-size: 20px; font-weight: 500; margin: 7px 0px 0px 8px ;">work long</span>
              </div>
              <input  width="200px" />
            </div>

            <div style="display: flex; column-gap: 5px; justify-content: space-between;">
              <div>
                <span style="font-size: 20px; font-weight: 600; margin: 7px 0px 0px 8px ;">10</span>
                <span style="font-size: 20px; font-weight: 500; margin: 7px 0px 0px 8px ;">make</span>
              </div>
              <input  width="200px" />
            </div>


            <div style="display: flex; column-gap: 5px; justify-content: space-between;">
              <div>
                <span style="font-size: 20px; font-weight: 600; margin: 7px 0px 0px 8px ;">11</span>
                <span style="font-size: 20px; font-weight: 500; margin: 7px 0px 0px 8px ;">answer</span>
              </div>
              <input  width="200px" />
            </div>

            <div style="display: flex; column-gap: 5px; justify-content: space-between;">
              <div>
                <span style="font-size: 20px; font-weight: 600; margin: 7px 0px 0px 8px ;">12</span>
                <span style="font-size: 20px; font-weight: 500; margin: 7px 0px 0px 8px ;">work with</span>
              </div>
              <input  width="200px" />
            </div>

            <div style="display: flex; column-gap: 5px; justify-content: space-between;">
              <div>
                <span style="font-size: 20px; font-weight: 600; margin: 7px 0px 0px 8px ;">13</span>
                <span style="font-size: 20px; font-weight: 500; margin: 7px 0px 0px 8px ;">earn</span>
              </div>
              <input  width="200px" />
            </div>
            
            <div style="display: flex; column-gap: 5px; justify-content: space-between;">
              <div>
                <span style="font-size: 20px; font-weight: 600; margin: 7px 0px 0px 8px ;">14</span>
                <span style="font-size: 20px; font-weight: 500; margin: 7px 0px 0px 8px ;">use</span>
              </div>
              <input  width="200px" />
            </div>
          </div>
          </div>
       
      

      
      `,
        answer: [
          'a lot',
          'your feet',
          'customers',
          'alone',
          'indoors',
          'outdoors',
          'a uniform',
          'the public',
          'a team',
          'five',
          'hours',
          'phone calls',
          'the phone',
          'children',
          'a lot',
          'a computer',
        ],
      },
    ],
  },
  3: {
    // Exercise num
    unit: 'Unit 5',
    id: 'WB10-U5-P40-E3',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '3',
        title: 'Complete the adjectives for describing jobs with a, e, i, o, u and y. Then use them to complete the table.',
        color: '#000000',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      backgroundColor: 'transparent',
    },
    inputSize: 30,
    // maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page40/E3/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    checkDuplicate: true,
    questions: [
      {
        title: /*html*/`
        <div style="display: flex; align-items: stretch; width: 620px; justify-content: space-between;">
          <div>
            <div style="display: flex; column-gap: 1rem">
              <span>1</span>
              <div>
                <span>
                ch
                  <span style="color: rgb(0 68 98)">a</span>
                ll
                  <span style="color: rgb(0 68 98)">e</span>
                ng
                <span style="color: rgb(0 68 98)">e</span>
                ing
                </span>
              </div>
            </div>
            <div style="display: flex; column-gap: 1rem">
              <span>2</span>
              <div>
                <span>
                b
                  <span>
                    <input maxlength="1" style="text-align: center" />
                  </span>
                dl
                  <span>
                    <input maxlength="1" style="text-align: center" />
                  </span>
                p
                  <span>
                    <input maxlength="1" style="text-align: center" />
                  </span>
                  <span>
                    <input maxlength="1" style="text-align: center" />
                  </span>
                d
                </span>
              </div>
            </div>
            <div style="display: flex; column-gap: 1rem">
              <span>3</span>
              <div>
                <span>
                  cr
                    <span>
                      <input maxlength="1" style="text-align: center" />
                    </span>
                    <span>
                      <input maxlength="1" style="text-align: center" />
                    </span>
                  t
                    <span>
                      <input maxlength="1" style="text-align: center" />
                    </span>
                  v
                    <span>
                      <input maxlength="1" style="text-align: center" />
                    </span> 
                  </span>
              </div>
            </div>
            <div style="display: flex; column-gap: 1rem">
              <span>4</span>
              <div>
                <span>
                  r
                    <span>
                      <input maxlength="1" style="text-align: center" />
                    </span>
                  p
                    <span>
                      <input maxlength="1" style="text-align: center" />
                    </span>
                  t
                    <span>
                      <input maxlength="1" style="text-align: center" />
                    </span>
                  t
                    <span>
                      <input maxlength="1" style="text-align: center" />
                    </span>
                  v
                    <span>
                      <input maxlength="1" style="text-align: center" />
                    </span> 
                </span>
              </div>
            </div>
            <div style="display: flex; column-gap: 1rem">
              <span>5</span>
              <div>
                <span>
                  r
                    <span>
                      <input maxlength="1" style="text-align: center" />
                    </span>
                  w
                    <span>
                      <input maxlength="1" style="text-align: center" />
                    </span>
                  rd
                    <span>
                      <input maxlength="1" style="text-align: center" />
                    </span>
                  ng
                </span>
              </div>
            </div>
          </div>
          <div>
          <div style="display: flex; column-gap: 1rem">
            <span>6</span>
            <div>
              <span>
              str
                <span>
                  <input maxlength="1" style="text-align: center" />
                </span>
              ssf
                <span>
                  <input maxlength="1" style="text-align: center" />
                </span>
              l
              </span>
            </div>
          </div>
          <div style="display: flex; column-gap: 1rem">
            <span>7</span>
            <div>
              <span>
              t
                <span>
                  <input maxlength="1" style="text-align: center" />
                </span>
              r
                <span>
                  <input maxlength="1" style="text-align: center" />
                </span>
              ng
              </span>
            </div>
          </div>
          <div style="display: flex; column-gap: 1rem">
            <span>8</span>
            <div>
              <span>
              v
                <span>
                  <input maxlength="1" style="text-align: center" />
                </span>
              r
                <span>
                  <input maxlength="1" style="text-align: center" />
                </span>
                <span>
                  <input maxlength="1" style="text-align: center" />
                </span>
              d
              </span>
            </div>
          </div>
          <div style="display: flex; column-gap: 1rem">
            <span>9</span>
            <div>
              <span>
              w
                <span>
                  <input maxlength="1" style="text-align: center" />
                </span>
              ll p
                <span>
                  <input maxlength="1" style="text-align: center" />
                </span>
                <span>
                  <input maxlength="1" style="text-align: center" />
                </span>
              d
              </span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <table style="width: 100%; margin-top: 2rem; border-collapse: collapse">
        <thead>
        <tr style ="background-color: rgb(132 36 129); color: rgb(255 255 255);">
        <th style="padding: 0.2rem 1rem; font-size: 1.4rem;border-left: 1px solid rgb(132 36 129)">Positive
        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAAFKElEQVRoge2ZXWwUVRTHf2d3WUrTWnaBLrVUhEa3SKAlNKgJCR/2QbohQROBAA8+kKihJMY3U03U+PFEiBEQEzUxgWCBBE2KkQSq0VpCU7QFFAotBlqqAcrHUj52l53jw9IwdGdnph+UTexJJnvv3Zyd33/OvWf2ngtjNmb/b5OR+BFVvHSwAFiiwnwMyoAilDwUgD6UHqBdDI4iNDCLZhGM4d57WAK0gxJD2CDKOqAYhXvApLXTx7oVdniSbJVyuofKMCQBeoYphpcPRXkVxW8JbA9vHour8rUnwbtSyeWHLkA7WaPwGRDMCOce3jzWK1Aj5Xw7GB7XArSFcUaQbaKstwUZCryprcoXHoONUklixARoD7l6m73AsocJb/r8QQxekUpuDVuAtjBOA3w/avD9bTgoOURkNnE7Po+TACPItlGHV8CgyrjFp058thHQTtYq7Bh1eFNbYLUsoG7QArSbSRrjFDD5UcHfu66Ij3CmFJtxChlxPs4CeFCCRoL3M3FaRkDPME09dDL8l9Rw4fvH4mLwlCzk/EBWywgYHmqyCB4UvwFvWLGmRUAVj3ZyDpiWJfD9/QvSw3RZSdLMmx6BDp51hB9XghTvRcJRpCyKlOyD8WH38P4SZMZepCKKzIsipTb+9/vFTGX+QFxfmgBY4gj/ZCt4g/c98lcguYvQM+WQ6HKGL2sFn8k/sALJX4QeK4d4l10klgLNZti0CKhSafcUpXAzeIM0NjZSXV1NJBKhqakJvAGkaJPjtJHizeCz8PcFkOmbMsJL6jMtAmlrwDjNCWB2JhAJR8GTTyQS4eLFiwCEQiHq6+shGUVPFGSER0EqouC18T9SYA2f6h+Xxcw181ploSLHxZfR1BbelX9meFCKBnqkC7i/DbS++c1DANTW1lJYWEgoFKK2tjb13Y0DzvBRG/+rB+zgQckfiJs+hU4RA/wZQcaHkRmHwRt40PFuL9peAYlu+2w0Pow8cxh8Fv5/VECsOxM8KDF5gRyzm9UU6rNNhXfaU9nm+h5IRlPXtd3u4BW43Z7KNr0m/8u73cAD3BgImx6Bk/aL2FWez9S2z/NO8KlFXOW8iE9nKTwYtA+ETRMgBi1ZCZ9qtzgKQGjISngFPPfYbAXMohmlK+vg4TyNHHUUIIKhsNMt/K5DIb75cSrJpLiGTyaFnT+FqPulcDCCd8p76aVI6w1NG9NU6KT/fWDz5Kvemkf0ppe5M/t4e805Sotu24J09Ezgk7rpHD+XR0HuXQ5+0OoGPkacUlnOBVcCAJJtfC7wutO0OX42j3e+msk/vX5EYOHsaywuv0b5zD4mPxYHhcvX/bSdzePnYxNp/GsiqlAUiPHRur+Z80Sfm6m2RV5koxVn5k39nwQ1QTsu9sU3bnr5cv/j7PttCnfi9pWaHL/By89fYn1VD/k5STfwvSQok+XWm3r7skobq9Vglx28+aZXoj4aWgMcOVnA2X9z6I2mdqWT8uOUFt3huaevs2TOVYJ5d10vcpSVsow9mRgdK3PJ39ku8NooZhtzf6sso8aOz7Ey5+lgA8p3jwB+Pzm86cTnrrjbQq7CHpTqUYE3qCfJKlnuXNx1jACAVHJLYIXC9tGYNkzgJTfwMJQDjmZWqcEWlMkjDH8JZYPdgrUyVxEwmyygToQyhW0osRGAj6FsAcoGCw/DPeRrotgwqBFYi1IySPgulB3E2Wr1hnVrI3XM6uFXKlGWkip9hIFijAeOWbsxOA204KGBRo5a/bcZszEbs8HZf8rUWkDnu/kCAAAAAElFTkSuQmCC" style="width: 23px; height: 23px">
        </th>
        <th style="padding: 0.2rem 1rem; font-size: 1.4rem;  border-left: 1px solid rgb(255 255 255)">Negative
        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAAFMElEQVRoge3ZX0yVdRjA8e/zHv4oA+0cCWTICF0DswFOZrnV/JNtCXPTi9S0iy5q1cStiy5a1GZbdudcS8226kpXiJu1kZub2RoMi0GJmYmCpqAZAeoBpXOO5/d0cWQeOe+fwx+RLZ7t7Pzelz2cz3N+f97z/l6Yjun4f4dMxD9RxUcHS4GVKizBUALkoWSiAAyiXAXaxdCKcJyFNItgxvvZ4ypAOygwwlZRXgbyUbgLJqGdeK5bYb8VZY+U0T1Ww5gK0PM8anx8KMorKGm2YHd8/LmwKl9aEd6XCnofeAHayWaFT4CAIy55fPy5PoFqKePr0XiSLkBbSDUB9oryqitkLPi4tiqfWYZtUkFkwgrQq2ToEIeANQ8SH/d+RAwvSgW3x12AtpCqfr6dNPxwG47JDKpkEWE3n+VVgAmwd9LxChhWm9t87OVz7QHtZIvC/knHx7UFNslSakddgHYzR0OcBbIfFv7uq19SKHZaYh2HkAnz0RTAgxIwET5wctr2gJ5nnlp0Mv6L1Hjxw+fCYnhcnuHySKttDxiL6imEByXNwJt21oQeUMXSTi4B86YIfvj4ilylUDYQjfcm9kAHT3niUwuQ/ENIcRApCSIFhyG9OHl8WgFSdAgpDyKLg8gCl/x7x/nMZclIbkpCAbDSE//YSfAF7mVkrUMylqPnyyDS5Y0vOQkpcfn+dUjWcvRUGYS73HpiFdAcj03oAVUq3L5FydkFvgCNjY1UVlZSVVVFU1MT+PxI3k7PYSP5uyDFJj/FjxTudMRL7D2hBxLmgDnHaWCRE0SKg2BlUVVVRU9PDwC5ubnU19dDNIienu2IR0HKg+Bzyf95tj0+dvybrKA03mu3CuV5Tj7HUFd8UvnOeFDyRmYkFnDvNtD+w299D0BNTQ05OTnk5uZSU1MT+9vAUW980CX/+lE3PChZI7mJQ+gsISDNEZJejBSdAJ///sQ7fWh7OUS63Vej9GLkiROQYpP/azmEup3woITkOWbEp9kNoUHXpfDf9thqc7MOosHY68bB5PAKDLXHVpu+uPzeg8ngAQZGYhN74A/3SZzUOu/Udl/nvfCxSbzaexKfm6J4MLSPxCYUIIaWKYmPtVs8C0A4PiXxClh3ba4FLKQZpWvK4eEyjbSO5Cb8FhLBRE9zQJR3RovvH0jl+1Y/P52ZxYW/ZtI/kArAnKwI8+cO8fTCIKtKrxPIjIwWD8oB2Z64FWl/Q9PGPBU6Gb4eeOCDgz4+P5LP4YZsQhH3fYL0VMP6Zb289vwVZmVEk8WHCLNA1nIlqQIAom18KvCGF/5UZybvfTGfa/1pWBY8++QNVpRdp7RokOys2DfdezOVtouZ/HjKT8OZRzAG5vrD7NhygdLCwWSG2m55gW12Tueb+t8JaIR2PO6LV7+9mOAtH2ULBnn3pUsU5Q65Qfjz2kx21BXSdjGT2Rl3OLb9pBe+jwglstb+pt59W6WNTWr4ygkPUPtDDuGIxeaVf+MbHtQeEzYaFWobckjxKRuW9bjmoGyQNdQ5GT135qK/sE/g9UlcbeKP98gaqt18njtzVgdbUb55CPjvmMFbXr7kNndbyFCoQ6mcFLyhnigbZa335q5nDwBIBbcF1insm4xhw0zWJ4OHsTzgaGajGnajZE8w/h+UrW4T1i6S6oH4kKXUilCisBclNAH4EMpuoGS0eBjvQ74m8o2hWmALSsEo8V0o+wmzx+4Km2xM1GNWiwYqUFYR2/ooBvIx9z1m7cZwDmjB4jiNtNr9tpmO6ZiO0cV/e5tbq3ynlP8AAAAASUVORK5CYII=" style="width: 23px; height: 23px">
        </th>
        </tr>
        </thead>
        <tbody>
        <tr>
        <td style="padding: 0.2rem 1rem; font-size: 1.4rem; height: 35px; width: 50%; border: 1px solid rgb(132 36 129)">challenging</td>
              <td style="padding: 0.2rem 1rem; font-size: 1.4rem; height: 35px; width: 50%; border: 1px solid rgb(132 36 129)"><input width="100%" maxlength="50" style="text-align: left" /></td>
            </tr>
            <tr>
              <td style="padding: 0.2rem 1rem; font-size: 1.4rem; height: 35px; width: 50%; border: 1px solid rgb(132 36 129)"><input width="100%" maxlength="50" style="text-align: left" /></td>
              <td style="padding: 0.2rem 1rem; font-size: 1.4rem; height: 35px; width: 50%; border: 1px solid rgb(132 36 129)"><input width="100%" maxlength="50" style="text-align: left" /></td>
            </tr>
            <tr>
              <td style="padding: 0.2rem 1rem; font-size: 1.4rem; height: 35px; width: 50%; border: 1px solid rgb(132 36 129)"><input width="100%" maxlength="50" style="text-align: left" /></td>
              <td style="padding: 0.2rem 1rem; font-size: 1.4rem; height: 35px; width: 50%; border: 1px solid rgb(132 36 129)"><input width="100%" maxlength="50" style="text-align: left" /></td>
            </tr>
            <tr>
              <td style="padding: 0.2rem 1rem; font-size: 1.4rem; height: 35px; width: 50%; border: 1px solid rgb(132 36 129)"><input width="100%" maxlength="50" style="text-align: left" /></td>
              <td style="padding: 0.2rem 1rem; font-size: 1.4rem; height: 35px; width: 50%; border: 1px solid rgb(132 36 129)"><input width="100%" maxlength="50" style="text-align: left" /></td>
            </tr>
            <tr>
              <td style="padding: 0.2rem 1rem; font-size: 1.4rem; height: 35px; width: 50%; border: 1px solid rgb(132 36 129)"><input width="100%" maxlength="50" style="text-align: left" /></td>
              <td style="padding: 0.2rem 1rem; font-size: 1.4rem; height: 35px; width: 50%; border: 1px solid rgb(132 36 129)"></td>
            </tr>
            </tbody>
        </table>
      </div>
      `,

        answer: [
          'a',
          'y',
          'a',
          'i',
          'e',
          'a',
          'i',
          'e',
          'e',
          'e',
          'i',
          'i',
          'e',
          'e',
          'a',
          'i',
          'e',
          'u',
          'i',
          'i',
          'a',
          'i',
          'e',
          'e',
          'a',
          'i',
          'badly paid/repetitive/tiring/stressful',
          'creative/rewarding/varied/well paid',
          'badly paid/repetitive/tiring/stressful',
          'creative/rewarding/varied/well paid',
          'badly paid/repetitive/tiring/stressful',
          'creative/rewarding/varied/well paid',
          'badly paid/repetitive/tiring/stressful',
          'creative/rewarding/varied/well paid',
        ],
      },
    ],
  },
  4: {
    // Exercise num
    unit: 'Unit 5',
    id: 'WB10-U5-P40-E4',
    audio: 'Audios/1.15.mp3',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '4',
        title:
          '<headphone name="1.15"></headphone>  Listen to three people talking about their jobs.What are the jobs? Choose two adjectives from exercise 3 which you think best describe each job.',
        color: '#000000',
      },
    ],
    stylesTextInput: {
      fontSize: 20,

      backgroundColor: 'transparent',
    },
    inputSize: 30,
    // maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page40/E4/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    checkDuplicate: true,
    questions: [
      {
        title: /*html */`
          <div style="width: 650px; margin: 0 auto">
            <table style="width: 100%; margin-top: 2rem; border-collapse: collapse">
              <thead>
                <tr style="background-color: rgb(132 36 129); color: rgb(255 255 255)">
                  <th style="padding: 0.2rem 1rem; font-size: 1.4rem;border-left: 1px solid rgb(132 36 129)"; width: 250px" colspan="2">Job</th>
                  <th style="padding: 0.2rem 1rem; font-size: 1.4rem;border-left: 1px solid rgb(132 36 129); width: 200px" >Adjective 1</th>
                  <th style="padding: 0.2rem 1rem; font-size: 1.4rem;border-left: 1px solid rgb(132 36 129);  width: 200px">Adjective 2</th>
                </tr>
              </thead>
              <tbody>
              <tr>
                <td style="padding: 0.2rem 1rem; font-size: 1.4rem; height: 35px; width: 50px; border: 1px solid rgb(132 36 129);">1</td>
                <td style="padding: 0.2rem 1rem; font-size: 1.4rem; height: 35px; width: 200px; border: 1px solid rgb(132 36 129)">
                  <input width="100%" maxlength="50" style="text-align: left" />
                </td>
                <td style="padding: 0.2rem 1rem; font-size: 1.4rem; height: 35px; width: 200px; border: 1px solid rgb(132 36 129)">
                  <input width="100%" maxlength="50" style="text-align: left" />
                </td>
                <td style="padding: 0.2rem 1rem; font-size: 1.4rem; height: 35px; width: 200px; border: 1px solid rgb(132 36 129)">
                  <input width="100%" maxlength="50" style="text-align: left" />
                </td>
              </tr>

              <tr>
                <td style="padding: 0.2rem 1rem; font-size: 1.4rem; height: 35px; width: 50px; border: 1px solid rgb(132 36 129);">2</td>
                <td style="padding: 0.2rem 1rem; font-size: 1.4rem; height: 35px; width: 200px; border: 1px solid rgb(132 36 129)">
                  <input width="100%" maxlength="50" style="text-align: left" />
                </td>
                <td style="padding: 0.2rem 1rem; font-size: 1.4rem; height: 35px; width: 200px; border: 1px solid rgb(132 36 129)">
                  <input width="100%" maxlength="50" style="text-align: left" />
                </td>
                <td style="padding: 0.2rem 1rem; font-size: 1.4rem; height: 35px; width: 200px; border: 1px solid rgb(132 36 129)">
                  <input width="100%" maxlength="50" style="text-align: left" />
                </td>
              </tr>
              <tr>
                <td style="padding: 0.2rem 1rem; font-size: 1.4rem; height: 35px; width: 50px; border: 1px solid rgb(132 36 129);">3</td>
                <td style="padding: 0.2rem 1rem; font-size: 1.4rem; height: 35px; width: 200px; border: 1px solid rgb(132 36 129)">
                  <input width="100%" maxlength="50" style="text-align: left" />
                </td>
                <td style="padding: 0.2rem 1rem; font-size: 1.4rem; height: 35px; width: 200px; border: 1px solid rgb(132 36 129)">
                  <input width="100%" maxlength="50" style="text-align: left" />
                </td>
                <td style="padding: 0.2rem 1rem; font-size: 1.4rem; height: 35px; width: 200px; border: 1px solid rgb(132 36 129)">
                  <input width="100%" maxlength="50" style="text-align: left" />
                </td>
              </tr>
             
              </tbody>
            </table>
          </div>
        `,
        answer: ['receptionist', 'varied', 'tiring', 'architect', 'creative', 'well-paid', 'programmer', 'challenging', 'rewarding '],
      },
    ],
  },
}

export default json
