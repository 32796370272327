import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';

const json = {
  1: {
    // Exercise num
    unit: 'Unit 1',
    id: 'WB10-U1-P9-E1',
    component: T6,
    stylesTextInput: {
      padding: 0,
      fontSize: 22,
      textAlign: 'center',
    },
    exerciseKey: 'img/FriendsPlus/Page9/E1/Key/answerKey.png',
    inputSize: 140,
    titleQuestion: [
      {
        num: '1',
        title: 'Complete the sentences with the past simple affirmative form of the regular verbs below.',
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
              <div style="color: rgb(246, 140, 31); display: inline-flex; font-weight: bold; gap: 30px; flex-wrap: wrap; width: 660px; line-height: 10px; margin: 15px">
                <div>decide</div>
                <div>die</div>
                <div>drop</div>
                <div>look</div>
                <div>marry</div>
                <div>move</div>
                <div>stop</div>
                <div>study</div>
                <div>talk</div>
                <div>want</div>
              </div>
              <div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> My dad # maths at university. </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; ">We live in Oxford. We # here five years ago. </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> Jason # at me and smiled.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> The train # at the station and we got off. </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; "> Jenny # on the phone with her friend for over two hours! </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; "> In the end, we # to go on holiday to Italy, not to France.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>7. </b></div><div style="margin-left: 30px; flex: 1; "> The goalkeeper had the ball, but then he # it.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>8. </b></div><div style="margin-left: 30px; flex: 1; ">My mum # my dad in 1988. </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>9. </b></div><div style="margin-left: 30px; flex: 1; "> I # to go to the shopping mall, but my mum said no.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>10. </b></div><div style="margin-left: 30px; flex: 1; "> Sadly, my grandma # last year. She was 98.</div></div>
              </div>
        `,
        answer: [
          'studied ',
          'moved ',
          'looked ',
          'stopped ',
          'talked ',
          'decided ',
          'dropped ',
          'married ',
          'wanted ',
          'died',
        ],
      },
    ],
  },
  2: {
    // Exercise num
    unit: 'Unit 1',
    id: 'WB10-U1-P9-E2',
    component: T6,
    stylesTextInput: {
      padding: 0,
      fontSize: 22,
      textAlign: 'center',
    },
    exerciseKey: 'img/FriendsPlus/Page9/E2/Key/answerKey.png',
    inputSize: 140,
    titleQuestion: [
      {
        num: '2',
        title: 'Complete the sentences. Use the past simple affirmative form of the irregular verbs in brackets.',
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
              <div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; ">Emma tried on a green dress and a blue dress in the clothes shop, and # (choose) the blue one.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> I # (find) £20 in the street last Saturday.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; ">We # (go) bowling yesterday evening.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; ">Somebody # (steal) my smartphone from my schoolbag</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; ">The film # (begin) at seven and finished at ten.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; ">It # (take) six hours to drive from London to Edinburgh.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>7. </b></div><div style="margin-left: 30px; flex: 1; ">Hannah # (feel) relieved when she got her exam results.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>8. </b></div><div style="margin-left: 30px; flex: 1; ">Tom and Matt # (be) upset that George didn't invite them to his party</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>9. </b></div><div style="margin-left: 30px; flex: 1; ">We # (get) home at eight o'clock on Sunday.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>10. </b></div><div style="margin-left: 30px; flex: 1; ">Millie # (spend) all her money on computer games.</div></div>
              </div>
        `,
        answer: ['chose ', 'found ', 'went ', 'stole ', 'began ', 'took ', 'felt ', 'were ', 'got ', 'spent'],
      },
    ],
  },
  3: {
    // Exercise num
    unit: 'Unit 1',
    id: 'WB10-U1-P9-E3',
    audio: '',
    video: '',
    component: T6,
    isHiddenCheck: true,
    stylesTextInput: {
      borderBottom: '1px solid',
      padding: 0,
      fontSize: 22,
      textAlign: 'center',
      backgroundColor: 'transparent',
    },
    textareaStyle: { width: 565, resize: 'none' },
    exerciseKey: 'img/FriendsPlus/Page9/E3/Key/answerKey.png',
    inputSize: 125,
    titleQuestion: [
      {
        num: '3',
        title: 'Complete the texts with the past simple affirmative of the verbs in brackets',
      },
    ],
    questions: [
      {
        title: `
        <div style=" position: relative;">
          <div style="box-shadow: 3px 3px rgb(207, 198, 189);border: 3px solid; border-radius: 20px; padding: 10px 80px 10px 10px; width: 800px; position: absolute; left: -150px; top: 150px; z-index: 0 ">
            Callie Rogers <sup>1</sup> # (be) just sixteen when she 
            <sup>2</sup> # (win) the lottery in 2003. She <sup>3</sup> #
            (give) up her job as a shop assistant and <sup>4</sup> # (start) 
            to spend her money. She <sup>5</sup> # (take) her family on 
            expensive holidays, <sup>6</sup> # (invite) all her friends to 
            big parties every weekend and <sup>7</sup> # (have) cosmetic 
            surgery. Ten years later, she <sup>8</sup> # (have) just £2,000 
            left. But she's happy now. ‘I <sup>9</sup> # (be) too young to win 
            the lottery,' she <sup>10</sup> # (say).
          </div>
          <div style="box-shadow: 3px 3px 3px rgb(207, 198, 189);border: 3px solid; border-radius: 20px; padding: 10px; width: 600px;position: absolute; left: 580px; top: 0px; z-index: 1; background-color: white">
            Tom Crist <sup>11</sup> # (get) a big surprise 
            when he <sup>12</sup> # (answer) his phone on 
            16 December 2013. He <sup>13</sup> # (be) a 
            lottery winner, and the prize <sup>14</sup> #
            (be) enormous – $40 million! He <sup>15</sup> #
            (decide) not to tell anyone about the win. He 
            immediately <sup>16</sup> # (give) all the money 
            to charities. ‘I don't really need that money,' he 
            <sup>17</sup> # (say). ‘My wife <sup>18</sup> # (die) 
            earlier in the year so I <sup>19</sup> # (choose) 
            cancer charities that <sup>20</sup> # (help) her.
          </div>
        </div>
        `,
        answer: [
          'was ',
          'won ',
          'gave ',
          'started ',
          'took ',
          'invited ',
          'had ',
          'had ',
          'was ',
          'said ',
          'got ',
          'answered ',
          'was ',
          'was ',
          'decided ',
          'gave ',
          'said ',
          'died ',
          'chose ',
          'helped',
        ],
      },
    ],
  },
  4: {
    // Exercise num
    unit: 'Unit 1',
    id: 'WB10-U1-P9-E4',
    component: T6,
    questionImage: [],
    textareaStyle: { width: 700, resize: 'none', borderBottom: 'none' },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: '4',
        title:
          ' Write five true sentences about what you did last weekend. Use past simple form of the verbs below to help you, or your own ideas. ',
      },
    ],
    questions: [
      {
        title: `
            <div style="color: rgb(246, 140, 31); display: inline-flex; font-weight: bold; gap: 30px; flex-wrap: wrap; width: 660px; line-height: 10px; margin: 15px">
              <div>be</div>
              <div>get</div>
              <div>go</div>
              <div>have</div>
              <div>phone</div>
              <div>play</div>
              <div>study</div>
              <div>take</div>
              <div>talk</div>
              <div>visit</div>
              <div>watch </div>
            </div>
            <div>
              <div style="display: flex; margin-block: -5px"><b style="padding-top: 6px;">1</b><textarea id="1" rows="2" ></textarea></div>
              <div style="display: flex; margin-block: -5px"><b style="padding-top: 6px;">2</b><textarea id="2" rows="2" ></textarea></div>
              <div style="display: flex; margin-block: -5px"><b style="padding-top: 6px;">3</b><textarea id="3" rows="2" ></textarea></div>
              <div style="display: flex; margin-block: -5px"><b style="padding-top: 6px;">4</b><textarea id="4" rows="2" ></textarea></div>
              <div style="display: flex; margin-block: -5px"><b style="padding-top: 6px;">5</b><textarea id="5" rows="2" ></textarea></div>
            </div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
