import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
	1: {
		unit: "Unit 7",
		id: "FG10-U7-P56-E1",
		audio: "",
		video: "",
		exerciseKey: "img/FriendsPlus/Page56/E1/Key/answerKey.png",
		component: T6,
		isHiddenCheck: true,
		maxLength: 15,
		// hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: "1",
				title: `
					Complete the visitor attractions.
				`,
				color: "#000",
			},
		],
		stylesTextInput: {
			fontSize: 20,
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
			marginTop: 5
		  },
		questions: [
			{
				title: `
		<div>
			<div style="display: flex; flex-wrap: wrap; gap: 20px">
                <div style="width: calc(24% - (20px * 1/4))" >
                  <img src="img/FriendsPlus/Page56/E1/1.jpg"/>
                  <div style="display: flex; column-gap: 20px">
                    <strong>1</strong>
                    <div>
                      <div style="display: flex;">
                        <span>f</span>
                        <input />
                      </div>
                    </div>
                  </div>
                </div>
                <div style="width: calc(24% - (20px * 1/4))" >
                  <img src="img/FriendsPlus/Page56/E1/2.jpg"/>
                    <div style="display: flex; column-gap: 20px">
                      <strong>2</strong>
                      <div style="display: flex;">
                        <span>a</span>
                        <input />
                      </div>
                    </div>
                </div>
                <div style="width: calc(24% - (20px * 1/4))" >
                  <img src="img/FriendsPlus/Page56/E1/3.jpg"/>
                    <div style="display: flex; column-gap: 20px">
                      <strong>3</strong>
                      <div>
                        <div style="display: flex;">
                          <span>m</span>
                          <input />
                        </div>
                      </div>
                  </div>
                </div>
                <div style="width: calc(24% - (20px * 1/4))" >
                  <img src="img/FriendsPlus/Page56/E1/4.jpg"/>
                  <div style="display: flex; column-gap: 20px">
                    <strong>4</strong>
                    <div>
                      <div style="display: flex;">
                        <span>W</span>
                        <input />
                      </div>
                      <div style="display: flex;">
                        <span>p</span>
                        <input />
                      </div>
                    </div>
                  </div>
                </div>
                <div style="width: calc(24% - (20px * 1/4))" >
                  <img src="img/FriendsPlus/Page56/E1/5.jpg"/>
                  <div style="display: flex; column-gap: 20px">
                    <strong>5</strong>
                    <div>
                      <div style="display: flex;">
                        <span>h</span>
                        <input />
                      </div>
                    </div>
                  </div>
                </div>
                <div style="width: calc(24% - (20px * 1/4))" >
                  <img src="img/FriendsPlus/Page56/E1/6.jpg"/>
                  <div style="display: flex; column-gap: 20px">
                    <strong>6</strong>
                    <div>
                      <div style="display: flex;">
                        <span>c</span>
                        <input />
                      </div>
                    </div>
                  </div>
                </div>
                <div style="width: calc(24% - (20px * 1/4))" >
                  <img src="img/FriendsPlus/Page56/E1/7.jpg"/>
                  <div style="display: flex; column-gap: 20px">
                    <strong>7</strong>
                    <div>
                      <div style="display: flex;">
                        <span>c</span>
                        <input />
                      </div>
                    </div>
                  </div>
                </div>
                <div style="width: calc(24% - (20px * 1/4))" >
                  <img src="img/FriendsPlus/Page56/E1/8.jpg"/>
                  <div style="display: flex; column-gap: 20px">
                    <strong>8</strong>
                    <div>
                      <div style="display: flex;">
                        <span>s</span>
                        <input />
                      </div>
                    </div>
                  </div>
                </div>
                <div style="width: calc(24% - (20px * 1/4))" >
                  <img src="img/FriendsPlus/Page56/E1/9.jpg"/>
                  <div style="display: flex; column-gap: 20px">
                    <strong>9</strong>
                    <div>
                      <div style="display: flex;">
                        <span>r</span>
                        <input />
                      </div>
                    </div>
                  </div>
                </div>
                <div style="width: calc(24% - (20px * 1/4))" >
                  <img src="img/FriendsPlus/Page56/E1/10.jpg"/>
                  <div style="display: flex; column-gap: 20px">
                    <strong>10</strong>
                    <div>
                      <div style="display: flex;">
                        <span>t</span>
                        <input />
                      </div>
                    </div>
                  </div>
                </div>
            </div>
		</div>
				`,
				answer: [
					"ountain",
					"quarium",
					"arket",
					"ater","ark",
					"arbour",
					"astle",
					"athedral",
					"tatue",
					"uins",
					"emple",
				],
			},
		],
	},
	2: {
		unit: "Unit 7",
		id: "FG10-U7-P56-E2",
		audio: "",
		video: "",
		exerciseKey: "img/FriendsPlus/Page56/E2/Key/answerKey.png",
		component: T6,
		inputSize: 220,
		textAlign: 'center',
		// isHiddenCheck: true,
		maxLength: 20,
		// hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: "2",
				title: `
		Answer the questions with the words below. 
				`,
				color: "#000",
			},
		],
		questions: [
			{
				title: ` 
				<div>
					<div style="color: rgb(198, 29, 136); font-weight: bold; margin-bottom: 30px;">
						<span style= "margin-right: 50px">aquarium</span>
						<span style= "margin-right: 50px">botanical gardens</span>
						<span style= "margin-right: 50px">museum</span>
						<span style= "margin-right: 50px">palace</span>
						<span style= "margin-right: 50px">theme park</span>
						<span style= "margin-right: 50px"><br/>tower</span>
						<span style= "margin-right: 50px">national park</span>
					</div>
					<div>
						<div style="margin-bottom: 10px">
							<span><strong>Where can you …</strong></span>
						</div>
						<ol type="1" style="font-weight: bold; display: flex; flex-direction: column; gap: 20px 0;">
							<li><span style="font-weight: normal;">enjoy nature and see exotic plants? #</span></li>
							<li><span style="font-weight: normal;">learn about interesting old objects? #</span></li>
							<li><span style="font-weight: normal;">see where members of the royal family live? #</span></li>
							<li><span style="font-weight: normal;">go on exciting rides?  #</span></li>
							<li><span style="font-weight: normal;">get a great view of a city? #</span></li>
							<li><span style="font-weight: normal;">see fish and sharks? #</span></li>
							<li><span style="font-weight: normal;">see unusual trees, plants and flowers? #</span></li>
						</ol>
					</div>
				</div>
				`,
				answer: [
					"national park",
					"museum",
					"palace",
					"theme park",
					"tower",
					"aquarium",
					"botanical gardens",
				],
			},
		],
	},
	3: {
		unit: "Unit 7",
		id: "FG10-U7-P56-E3",
		audio: "Audios/2.02.mp3",
		video: "",
		exerciseKey: "img/FriendsPlus/Page56/E3/Key/answerKey.png",
		component: T6,
		inputSize: 200,
		// isHiddenCheck: true,
		maxLength: 15,
		// hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: "3",
				title: `
			<headphone name="2.02"></headphone> Listen to three tourists booking excursions. Which
			type of attraction from <br/>exercises 1 and 2 does each tourist
			decide to visit?
				`,
				color: "#000",
			},
		],
		questions: [
			{
				title: `
				<div style="margin-top: 20px">
				  <span>Tourist 1 #</br></span>
				  <span>Tourist 2 #</br></span>
				  <span>Tourist 3 # Time: #</br></span>
				</div>           
				`,
				answer: ["castle", "market", "harbour", "evening"],
			},
		],
	},
	4: {
		unit: "Unit 7",
		id: "FG10-U7-P56-E4",
		audio: "Audios/2.02.mp3",
		video: "",
		exerciseKey: "img/FriendsPlus/Page56/E4/Key/answerKey.png",
		component: T6,
		inputSize: 160,
		// isHiddenCheck: true,
		maxLength: 15,
		// hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: "4",
				title: `
			<headphone name="2.02"></headphone> Listen again. Complete the sentences with the
			adjectives below. There are four extra adjectives.
				`,
				color: "#000",
			},
		],
		questions: [
			{
				title: `
				  <div>
					<div style="color: rgb(147, 50, 176); font-weight: bold; margin-bottom: 30px; display: grid;  grid-template-columns: repeat(5, minmax(0, 1fr); grid-gap: 0 20px;">
					  <span style= "margin-right: 50px">atmospheric</span>
					  <span style= "margin-right: 50px">beautiful</span>
					  <span style= "margin-right: 50px">busy</span>
					  <span style= "margin-right: 50px">crowded</span>
					  <span style= "margin-right: 50px">disappointing</span>
					  <span style= "margin-right: 50px">impressive</span>
					  <span style= "margin-right: 50px">peaceful</span>
					  <span style= "margin-right: 50px">remote</span>
					  <span style= "margin-right: 50px">romantic</span>
					  <span style= "margin-right: 50px">spectacular</span>
					</div>
					<div>
					  <div>
						<ol type="1" style="font-weight: bold">
							<li>
								<ol type="a" style="font-weight: bold">
								<li><span style="font-weight: normal;">I imagine it's quite #</span></li>
								<li><span style="font-weight: normal;">The ruins are far more # at night.</span></li>
								</ol>
							</li>
							<li>
								<ol type="a" style="font-weight: bold">
								<li><span style="font-weight: normal;">The market looks #</span></li>
								<li><span style="font-weight: normal;">The market isn't as # on Fridays.</span></li>
								</ol>
							</li>
							<li>
								<ol type="a" style="font-weight: bold">
								<li><span style="font-weight: normal;">The evening excursion is much less #</span></li>
								<li><span style="font-weight: normal;">And it's more # , in my opinion.</span></li>
								</ol>
							</li>
						</ol>
					  </div>
					</div>
				  </div>
				`,
				answer: [
					"atmospheric",
					"spectacular",
					"beautiful",
					"crowded",
					"busy",
					"romantic",
				],
			},
		],
	},
};

export default json;
