import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    // Exercise num
    unit: "Unit 4",
    id: "WB10-U4-P36-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page36/E1/Key/answerKey.png",
    textAlign: "left",
    inputSize: 250,
    titleQuestion: [
      {
        num: "1",
        title: `
        Match words from box A with words from box B to make compound nouns. Write them below.
				`,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
        
          <table style="border:2px solid rgb(188, 43, 134);width:800px">
            <tr >
              <th style="padding: 10px;">A</th>
              <th style="padding: 10px; border-left:2px solid rgb(188, 43, 134);">B</th>
            </tr>
            <tr>
              <td style="padding: 10px;"><b><i>climate</i></b> &emsp;fossil &emsp;global &emsp;greenhouse &emsp;ice &emsp;rain &emsp;renewable &emsp;sea &emsp;surface</td>
              <td style="padding: 10px; border-left:2px solid rgb(188, 43, 134);">caps &emsp;<b><i>change</i></b> &emsp;energy &emsp;forest &emsp;fuels &emsp;gases &emsp;level &emsp;temperature &emsp;warming</td>
            </tr>
          </table>
          
				<div style='display:flex; gap:150px;'>
						<div>
              <div><b>1 &emsp;</b><i style='color: rgb(1, 142, 211);'>climate change</i></div>
              <div><b>2 &emsp;</b>#</div>
              <div><b>3 &emsp;</b>#</div>
              <div><b>4 &emsp;</b>#</div>
              <div><b>5 &emsp;</b>#</div>
            </div>
						<div>
              <div><b>6 &emsp;</b>#</div>
              <div><b>7 &emsp;</b>#</div>
              <div><b>8 &emsp;</b>#</div>
              <div><b>9 &emsp;</b>#</div>
            </div>
				</div>
        `,
        answer: [
          "fossil fuels",
          "global warming",
          "greenhouse gases",
          "ice caps",
          "rainforest",
          "renewable energy",
          "sea level",
          "surface temperature",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "WB10-U4-P36-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page36/E2/key/answerKey.png",
    selectStyle: {
      color: "black",
      fontWeight: "bold",
      width: 40,
      textAlign: "center",
      border: "2px solid black",
      borderRadius: "20%",
    },
    isHiddenCheck: true,

    selectOptionValues: ["T", "F"],
    titleQuestion: [
      {
        num: "2",
        title: `
				Complete the sentences with the particles below. You need to use three of them more than once. Are the sentences true (T) or false (F)?
			  `,
        color: "black",
      },
    ],
    inputSize: 80,
    textAlign: "center",
    background: "white",
    maxLength: 10,
    titleImage: "",
    questions: [
      {
        title: `
				<div style='color: rgb(171, 89, 167); margin:0px 40px 20px 40px;'><b>
					down &emsp; off &emsp; on &emsp; out &emsp; up &emsp; with
				</b></div>
        <div style='display:flex; gap:15px;'>
          <b>1</b>
          <div>Factories and power stations give # greenhouse gases.</div>
          <select id='9'></select>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>2</b>
          <div>When we cut # trees, we increase the amount of CO2
in the atmosphere. </div>
          <select id='10'></select>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>3</b>
          <div>The world's oceans are slowly heating #.</div>
          <select id='11'></select>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>4</b>
          <div>The world's supply of gas is likely to run # in the next 
ten years.</div>
          <select id='12'></select>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>5</b>
          <div>The EU recently decided to close # all the nuclear 
power stations in Europe.</div>
          <select id='13'></select>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>6</b>
          <div>Solar energy works better in places where you can rely # good weather. </div>
          <select id='14'></select>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>7</b>
          <div>Scientists recently came # # a car engine that 
uses only CO<sub>2</sub>.</div>
          <select id='15'></select>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>8</b>
          <div>Many types of animals and plants are dying #
because the Earth is getting warmer. </div>
          <select id='16'></select>
        </div>
			  `,
        answer: [
          "off",
          "down",
          "up",
          "out",
          "down",
          "on",
          "up",
          "with",
          "out",
          "T",
          "T",
          "T",
          "F",
          "F",
          "T",
          "F",
          "T",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "WB10-U4-P36-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page36/E3/key/answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: `
				Write the phrasal verbs which can replace the underlinedwords. Choose from the list below.
			  `,
        color: "black",
      },
    ],
    // isHiddenCheck: true,
    inputSize: 160,
    textAlign: "center",
    maxLength: 15,
    titleImage: "",
    questions: [
      {
        title: `
				<div style='color: rgb(171, 89, 167); margin:0px 40px 20px 40px;'><b>
					carry on &emsp; come up with &emsp; give up &emsp; go up &emsp; look after &emsp; put off &emsp; set off &emsp; use up
				</b></div>
        <div style='display:flex; gap:15px;'>
          <b>1</b>
          <div>I need to <u>think of</u> a good excuse for not handing in my homework.#</div>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>2</b>
          <div>'m trying to <u>stop eating</u> junk food.#</div>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>3</b>
          <div> After running ten kilometres, she was too tired to <u>continue</u>.#</div>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>4</b>
          <div> He left his job so he could <u>take care of</u> his sons.#</div>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>5</b>
          <div> Please don't <u>finish</u> all the milk – I need some for my breakfast tomorrow.#</div>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>6</b>
          <div> The number of people with smartphones is continuing to <u>rise</u>.#</div>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>7</b>
          <div> We should <u>delay</u> the meeting because nobody can come that day.#</div>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>8</b>
          <div> Don't stay up late! We need to <u>leave</u> early tomorrow.#</div>
        </div>
			  `,
        answer: [
          "come up with",
          "give up",
          "carry on",
          "look after",
          "use up",
          "go up",
          "put off",
          "set off",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "WB10-U4-P36-E4",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "",
    titleQuestion: [
      {
        num: "4",
        title: `
				Use a dictionary to find four phrasal verbs with the base verb <i>turn</i>. Include short definitions.
			  `,
        color: "black",
      },
    ],
    // isHiddenCheck: true,
    textareaStyle: { width: 700 },
    inputSize: 180,
    textAlign: "center",
    hideBtnFooter: true,
    titleImage: "",
    questions: [
      {
        title: `
        <div style='display:flex; gap:15px;'>
          <b>1 &ensp;</b>
          <div> # = </div>
          <textarea rows="2"></textarea>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>2 &ensp;</b>
          <div> # = </div>
          <textarea rows="2"></textarea>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>3 &ensp;</b>
          <div> # = </div>
          <textarea rows="2"></textarea>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>4 &ensp;</b>
          <div> # = </div>
          <textarea rows="2"></textarea>
        </div>
			  `,
        answer: [],
      },
    ],
  },
};

export default json;
