import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 8",
    id: "FG10-U8-P71-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page71/E1/Key/answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "1",
        title: `
        Read the task and the letter. What is the writer's main
        reason for complaining? Choose a, b or c.
            `,
        color: "#000",
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
          color: "black",
          fontWeight: "bold",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 3px",
          borderColor: "gray",
        },
        limitSelect: 1,
        listWords: ["a <br/><br/> b <br/><br/> c"],
        answers: ["0-0"],
        initialValue: [],
      },
      Layout: `
            <div style="display: flex; gap: 0 10px; font-size: 21px; line-height: 27px; margin-top: 15px">
              <div style="width: 400px">
                <b>The writer thinks the schedule </b>
                <div style='display:flex'>
                  <div style='width:43px;'><input id='0' type='Circle' /></div>
                  <div>
                  does not allow for teachers and students’ rest.<br/>
                  does not make use of teachers and students’ time.<br/>
                  does not encourage teachers and students.<br/>
                  </div>
                </div>
              </div>
              <div style="position: relative;">
                <img src="img/FriendsPlus/Page71/E1/1.jpg" alt="" style='width:700px;'/>
                <div style="position: absolute; top: 10px; padding: 0 70px 0 90px;">
                  <span>During the pandemic, all your classes are now online
                  and you have six periods every morning. Write a letter of
                  complaint about this problem.</span>
                </div>
                <div style="position: absolute; top: 310px; padding: 0 70px 0 100px;">
                  <span>Dear Sir or Madam,
                  I am writing to report unreasonable class schedule in my
                  school. (1)____ all classes in my school have gone
                  online because of the pandemic, the schedule has also been
                  changed accordingly and at present, we are having 6 periods
                  in the morning.
                  The main problem is that there is only a five-minute break
                  between the periods. In those five minutes, students have to
                  log in and out of their classes as well as get prepared for the
                  new class. Hardly do they have time to relax or to take their
                  eyes off the screens. My concern is that in the long run, this
                  practice may seriously affect students' eyes and vision in
                  particular and their well-being in general.
                  I understand that the school council wants to make the
                  best use of online time and that teachers and students
                  do not need long breaks to move from class to class as
                  in offline classes. (2)____ , I believe after 45 minutes
                  concentrating on lessons, teachers and students should
                  be allowed decent time to recharge and to prepare better
                  for the next class, (3)____ having 10-minute breaks is
                  probably a better option.
                  In conclusion, I find this problem irritating. (4)____
                  there is a change in the schedule, both teachers and students
                  will feel exhausted by the end of the day.<br/>
                  Yours faithfully,<br/>
                  Pham Minh </span>
                </div>
              </div>
            </div>
      `,
    },
  },
  2: {
    unit: "Unit 8",
    id: "FG10-U8-P70-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page71/E2/Key/answerKey.png",
    component: T6,
    inputSize: 120,
    textAlign: "center",
    stylesTextInput: {
      backgroundColor: "transparent",
    },
    maxLength: 10,
    titleQuestion: [
      {
        num: "2",
        title: `
        Read the <span style="color: rgb(88, 163, 212)">Writing Strategy</span>. Then complete the formal letter
        in exercise 1 with some of the conjunctions below
            `,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
            <div>
              <div style="background-image: url('img/FriendsPlus/Page71/E2/1.jpg'); background-repeat: no-repeat; background-size: 740px auto; height: 270px;width:760px; padding: 20px 50px 10px 30px; border-radius: 16px">
              <h2 style="font-size: 24px; color: rgb(88, 163, 212)">Speaking Strategy</h2>
              <span style="font-size: 20px;">Make sure you use a variety of different structures in your
              writing. You can form complex sentences by joining two
              clauses together with a conjunction (<i>although, because,
                if, or,</i> etc.). Use different conjunctions depending on the
              meaning of the clauses they introduce. </span>
              </div>
              <div style="color: rgb(88, 163, 212); font-weight: bold; margin-bottom: 10px;">
                <span style= "margin-right: 50px">however</span>
                <span style= "margin-right: 50px">if</span>
                <span style= "margin-right: 50px">or</span>
                <span style= "margin-right: 50px">since</span>
                <span style= "margin-right: 50px">so</span>
                <span style= "margin-right: 50px">unless</span>
                <span style= "margin-right: 50px">rather than</span>
              </div>
            </div>
            <div style="position: relative;">
                <img src="img/FriendsPlus/Page71/E2/2.jpg" alt="" style='width:740px;height:800px;'/>
                <div style="position: absolute; top: 30px; padding: 0 40px 0 60px; font-size: 20px;width:740px">
                  <span>Dear Sir or Madam,<br/>
                  I am writing to report unreasonable class schedule in my
                  school. (1)# all classes in my school have gone
                  online because of the pandemic, the schedule has also been
                  changed accordingly and at present, we are having 6 periods
                  in the morning.<br/>
                  The main problem is that there is only a five-minute break
                  between the periods. In those five minutes, students have to
                  log in and out of their classes as well as get prepared for the
                  new class. Hardly do they have time to relax or to take their
                  eyes off the screens. My concern is that in the long run, this
                  practice may seriously affect students' eyes and vision in
                  particular and their well-being in general.<br/>
                  I understand that the school council wants to make the
                  best use of online time and that teachers and students
                  do not need long breaks to move from class to class as
                  in offline classes. (2)# , I believe after 45 minutes
                  concentrating on lessons, teachers and students should
                  be allowed decent time to recharge and to prepare better
                  for the next class, (3)# having 10-minute breaks is
                  probably a better option.<br/>
                  In conclusion, I find this problem irritating. (4)#
                  there is a change in the schedule, both teachers and students
                  will feel exhausted by the end of the day.<br/>
                  Yours faithfully,<br/>
                  Pham Minh </span>
                </div>
              </div>
            `,
        answer: ["Since", "However", "so", "Unless"],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "FG10-U8-P71-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page71/E3/Key/answerKey.png",
    component: T6,
    textAlign: "center",
    inputSize: 150,
    maxLength: 10,
    titleQuestion: [
      {
        num: "3",
        title: `
        Complete the phrases for summing up using the words
        below. Underline the phrase that the writer uses in the
        letter in exercise 1.
            `,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
            <div>
              <div style="color: rgb(44, 137, 202); font-weight: bold; margin-bottom: 30px;">
                <span style= "margin-right: 50px">all</span>
                <span style= "margin-right: 50px">balance</span>
                <span style= "margin-right: 50px">conclusion</span>
                <span style= "margin-right: 50px">opinion</span>
                <span style= "margin-right: 50px">reasons</span>
              </div>
              <div>
                <ol type="1" style="font-weight: bold; display: flex; flex-direction: column; gap: 20px 0;">
                  <li><span style="font-weight: normal;">All in # , I believe …</span></li>
                  <li><span style="font-weight: normal;">On # , I think …</span></li>
                  <li><span style="font-weight: normal;">Overall, … in my #</span></li>
                  <li><span style="font-weight: normal;">In # ,...</span></li>
                  <li><span style="font-weight: normal;">For these # , I strongly believe that …</span></li>
                </ol>
              </div>
            </div>           
            `,
        answer: ["all", "balance", "opinion", "conclusion", "reasons"],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "FG10-U8-P71-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page71/E4/Key/answerKey.png",
    component: T6,
    inputSize: 800,
    // isHiddenCheck: true,
    maxLength: 0,
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: "4",
        title: `
        Read the task below. Then think about the problem and
        make notes.
            `,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
              <div>
                <div style="width: 800px; height: auto; border-radius: 16px; background-color: rgb(209, 222, 241); padding: 10px 30px">
                  <span>Your school has just launched a new official website which
                  most students find quite confusing and hard to use. Write
                  a letter of complaint about it.</span>
                </div>
                <div style="margin-top: 30px; display: flex; flex-direction: column">
                  <span>Explain the problem clearly and why it is an issue for you.</br># </br>#</span></br>
                  <span>Give more details about the problems. </br># </br>#</span></br>
                  <span>Give reasons why you think the problem needs to be
                  solved.</br># </br>#</span></br>
                  <span>State what you want to happen in the future.</br># </br>#</span></br>
                </div>
              </div>
            `,
        answer: [],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "FG10-U8-P71-E5",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page71/E5/Key/answerKey.png",
    component: T6,
    textareaStyle: { width: 700, resize: "none", borderBottom: "none" },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "5",
        title: `
        Use your notes to write a formal letter of complaint.
            `,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
        <div><img src='/img/FriendsPlus/Page71/E5/1.png' alt='' style='width: 700px;'/></div>
        <textarea id="0" rows="10" ></textarea>
            `,
        answer: [],
      },
    ],
  },
};

export default json;
