import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import DrawColor from "../../components/ExcerciseTypes/DrawColor";

const json = {
  1: {
    unit: "Unit 3",
    id: "WB10-U3-P31-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page31/E1/key/answerKey.png",
    component: T6,
    // selectStyle: {
    //   color: "gray",
    //   fontWeight: "bold",
    //   width: 40,
    //   textAlign: "center",
    //   border: "2px solid black",
    //   borderRadius: "20%",
    // },
    isHiddenCheck: true,

    selectOptionValues: ["✓", "✗"],
    titleQuestion: [
      {
        num: "1",
        title: `
				Complete the social activities with <i>a, e, i, o, u</i> and <i>y</i>
			  `,
        color: "black",
      },
    ],
    // stylesTextInput: {
    //   width: 40,
    //   height: 40,
    //   borderRadius: 5,
    //   borderBottom: "dash",
    // textAlign: "center",
    //   border: "2px solid black",
    //   // backgroundColor: "#fee4cb",
    //   fontSize: 22,
    // },
    maxLength: 1,
    inputSize: 37,

    titleImage: "",
    questions: [
      {
        title: `
        <div style='line-height:50px'>
          <b>1</b>&ensp;g#&emsp;for&emsp;a&emsp;b#k#&emsp;r#d#</br>
          <b>2</b>&ensp;g#&emsp;sh#pp#ng</br>
          <b>3</b>&ensp;m##t&emsp;fr##nds&emsp;#n&emsp;t#wn</br>
          <b>4</b>&ensp;pl##&emsp;b#sk#tb#ll</br>
          <b>5</b>&ensp;h#v#&emsp;a&emsp;c#ff##</br>
          <b>6</b>&ensp;l#st#n&emsp;to&emsp;m#s#c</br>
          <b>7</b>&ensp;pl##&emsp;t#bl#&emsp;t#nn#s </br>
        </div>
        

			  `,
        answer: [
          "o",
          "i",
          "e",
          "i",
          "e",
          "o",
          "o",
          "i",
          "e",
          "e",
          "i",
          "e",
          "i",
          "o",
          "a",
          "y",
          "a",
          "e",
          "a",
          "a",
          "e",
          "o",
          "e",
          "e",
          "i",
          "e",
          "u",
          "i",
          "a",
          "y",
          "a",
          "e",
          "e",
          "i",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 3",
    id: "WB10-U3-P31-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page31/E2/key/answerKey.png",
    // selectStyle: {
    //   color: "gray",
    //   fontWeight: "bold",
    //   width: 40,
    //   textAlign: "center",
    //   border: "2px solid black",
    //   borderRadius: "20%",
    // },
    isHiddenCheck: true,

    selectOptionValues: ["✓", "✗"],
    titleQuestion: [
      {
        num: "2",
        title: `
				Complete the invitations by writing the first word of each
phrase and adding a social activity from exercise 1. Use the
infinitive without to or the -ing form.
			  `,
        color: "black",
      },
    ],
    inputSize: 100,
    // stylesTextInput: {
    //   borderBottom: "dash",
    textAlign: "center",
    //   backgroundColor: "#fee4cb",
    //   fontSize: 18,
    //   height: 19,
    // },
    maxLength: 21,

    titleImage: "",
    questions: [
      {
        title: `
        <div style='line-height:50px'>
          <b>1</b>&ensp;#don't we<input id='1' width='300px'/>?<br>
          <b>2</b>&ensp;#you fancy<input id='3' width='300px'/>?<br>
          <b>3</b>&ensp;#about<input id='5' width='300px'/>?<br>
          <b>4</b>&ensp;#you like to<input id='7' width='300px'/>?<br>
        </div>

			  `,
        answer: [
          "Why",
          "play table tennis",
          "Do",
          "having a coffee",
          "How",
          "going for a bike ride",
          "Would",
          "meet in town",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "WB10-U3-P31-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page31/E3/key/answerKey.png",
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "3",
        title: `
        Read the <span style='color:rgb(35 135 199)'>
        Writing Strategy
      </span> Then write the missing
        words to complete the letter below.
			  `,
        color: "black",
      },
    ],
    inputSize: 80,
    stylesTextInput: {
      borderBottom: "dash",
      textAlign: "center",
      // backgroundColor: "#fee4cb",
      background: "transparent",
      // color: 'rgb(121, 85, 72)',
      fontSize: 21,
      height: 19,
    },
    maxLength: 4,
    titleImage: "",
    questions: [
      {
        title: `
          <div style='font-size: 21px; display: flex; gap: 20px; align-items: start;'>

            <div style="flex: 1; width: 450px; padding: 10px; border: 4px dotted rgb(34, 132, 198); background-color: rgb(232, 239, 248); box-shadow: 3px 3px 5px rgba(0,0,0,0.4)">
              <span style="font-weight: bold; color: rgb(34, 132, 198);">Writing Strategy</span>
              <div>
                When you write a letter to a friend or relative:
                <ul>
                  <li>Start with: <i>Dear [Jake],.</i></li>
                  <li>
                    Begin the main part of the letter with a short introduction. 
                    You can use phrases like: 
                    <i>How are you? Sorry I didn’t reply sooner. Thanks for your letter.</i>
                  </li>
                  <li>Write in paragraphs.</li>
                  <li>Do not use very formal language in your letter.</li>
                  <li>Use contractions (<i>it’s, there’s</i>, etc.).</li>
                  <li>
                    Finish the main part of the letter with a short paragraph. 
                    You can use phrases like: 
                    <i>That’s all for now. I’d better go now. Write again soon.</i>
                  </li>
                  <li>End the letter with <i>Love</i>, or <i>Best wishes</i>, and your first name.</li>
                </ul>
              </div>
            </div>

            <div style='background-image: url(img/FriendsPlus/Page31/E3/letter-bg.jpg); background-position: center; background-repeat: no-repeat; background-size: cover; background-color: transparent; 
              flex: 1; display: flex; flex-direction: column; gap: 5px; padding: 7px 70px;'>
              <div><sup>1</sup># Lily.</div>
              <div>
                Thanks for your letter. How <sup>2</sup># you? 
                I had a great weekend. 
                Lauren came over and we watched the third Hobbit film on DVD. 
                I saw the second film last year and I loved it.
              </div>
              <div>
                I enjoyed the film, but I didn’t think it was brilliant. 
                There are some exciting scenes, but it’s really long and I got a bit bored by the end.
              </div>
              <div>
                Before the film, we made pizzas and popcorn. 
                We ate them while we were watching the film. 
                (The pizza was a bit burned!)
              </div>
              <div>
                Would you like to go shopping next weekend? 
                I need to buy a birthday present for my dad, 
                but I want to find some clothes for me at the same time. 
                How about meeting for lunch too?
              </div>
              <div>
                Anyway, that’s all for <sup>3</sup># . Write again <sup>4</sup>#.
              </div>
              <div><sup>5</sup># wishes,</div>
              <div>Paige</div>
            </div>

          </div>
			  `,
        answer: ["Dear", "are", "now", "soon", "Best"],
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 3",
    id: "WB10-U3-P31-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page31/E4/Key/answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "4 ",
        title: `Circle the aspects of the film that Paige talks about. `,
        color: "black",
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: ["hello"],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          fontWeight: "bold",
          color: "rgb(35 132 199)",
        },
        selectWordStyle: {
          border: "solid 3px rgb(35 132 199)",
          // borderColor: "gray",
          padding: "5px",
        },
        limitSelect: 8,
        listWords: [
          `acting &emsp; characters &emsp; ending &emsp; plot &emsp; scenes  &emsp; script &emsp;|special&nbsp;effects|&emsp; length`,
        ],
        answers: ["0-24", "0-16", "0-30"],
        initialValue: [],
      },
      Layout: `

      <div style='width:20cm;line-height:50px'>
        <input id='0' type='Circle' />
      </div>
        <img src='img/FriendsPlus/Page31/E4/1.png' style='width:14cm' /> 

           
     
         
      
        `,
    },
  },
  5: {
    unit: "Unit 3",
    id: "WB10-U3-P31-E5",
    audio: "",
    video: "",
    component: DrawColor,
    exerciseKey: "img/FriendsPlus/Page31/E5/key/answerKey.png",
    titleQuestion: [
      {
        num: "5",
        title: `
				Read the task below. Underline the parts of Paige’s letter
				which give extra information about each of the four points.
			  `,
        color: "black",
      },
    ],
    hideBtnFooter: true,
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        listStyle: {
          UnderLine: { borderBottom: "2px solid #4285F4" },
        },
        limitSelect: 1,
        listWords: [
          "the_third_Hobbit", //0
          `I_enjoyed_the_film,_but_I_didn’t_think_it_was_brilliant._There_are_some_exciting_scenes,_but_it’s_really_long_and_I_got_a_bit_bored_by_the_end.`,
          `Before_the_film,_we_made_pizzas_and_popcorn._We_ate_them_while_we_were_watching_the_film._(The_pizza_was_a_bit_burned!)`,
          `go_shopping`,
          `meeting_for_lunch`,
        ],
        answers: {},
        initialValue: {},
      },
      Layout: `
      <div style="display: flex; gap: 20px; align-items: start;">
						<div style="flex: 2; background-color: rgb(209, 223, 242); padding: 20px; border-radius: 10px; box-shadow: 5px 5px 5px rgb(209, 223, 242);">
							<div>You and a friend watched a film at your house recently.</div>
							<div>Write a letter to another friend in which you:</div>
							<ul>
								<li>say what film you watched.</li>
								<li>give your personal opinion of the film.</li>
								<li>mention something else you did on that occasion.</li>
								<li>suggest an activity to do with the friend you are writing to.</li>
							</ul>
						</div>

						<div style='background-image: url(img/FriendsPlus/Page31/E3/letter-bg.jpg); background-position: center; background-repeat: no-repeat; background-size: cover; background-color: transparent;
								flex: 3; display: flex; flex-direction: column; gap: 5px; padding: 7px 70px;'>
								<div><sup>1</sup>_____ Lily.</div>
								<div>
									Thanks for your letter. How <sup>2</sup>_____ you?
									I had a great weekend.
									Lauren came over and we watched <input id='0' type='Circle' /> film on DVD.
									I saw the second film last year and I loved it.
								</div>
								<div>
									<input id='1' type='Circle' />
								</div>
								<div>
									<input id='2' type='Circle' />
								</div>
								<div>
									Would you like to <input id='3' type='Circle' /> next weekend?
									I need to buy a birthday present for my dad,
									but I want to find some clothes for me at the same time.
									How about <input id='4' type='Circle' /> too?
								</div>
								<div>
									Anyway, that’s all for <sup>3</sup>_____ . Write again <sup>4</sup>_____.
								</div>
								<div><sup>5</sup>_____ wishes,</div>
								<div>Paige</div>
							</div>
						</div>
      `,
    },
  },
  // 5: {
  // 	unit: 'Unit 3',
  // 	id: 'WB10-U3-P31-E5',
  // 	audio: '',
  // 	video: '',
  // 	component: T6,
  // 	exerciseKey: 'img/FriendsPlus/Page31/E5/key/answerKey.png',
  // 	isHiddenCheck: true,
  // 	titleQuestion: [
  // 		{
  // 			num: '5',
  // 			title: `
  // 			Read the task below. Underline the parts of Paige’s letter
  // 			which give extra information about each of the four points.
  // 		  `,
  // 			color: 'black',
  // 		},
  // 	],
  // 	inputSize: 80,
  // 	stylesTextInput: {
  // 		borderBottom: 'dash',
  // 		textAlign: 'center',
  // 		background: 'transparent',
  // 		fontSize: 21,
  // 		height: 19,
  // 	},
  // 	// maxLength: 4,
  // 	titleImage: '',
  // 	questions: [
  // 		{
  // 			title: `
  // 				<div style="display: flex; gap: 20px; align-items: start;">
  // 					<div style="flex: 2; background-color: rgb(209, 223, 242); padding: 20px; border-radius: 10px; box-shadow: 5px 5px 5px rgb(209, 223, 242);">
  // 						<div>You and a friend watched a film at your house recently.</div>
  // 						<div>Write a letter to another friend in which you:</div>
  // 						<ul>
  // 							<li>say what film you watched.</li>
  // 							<li>give your personal opinion of the film.</li>
  // 							<li>mention something else you did on that occasion.</li>
  // 							<li>suggest an activity to do with the friend you are writing to.</li>
  // 						</ul>
  // 					</div>

  // 					<div style='background-image: url(img/FriendsPlus/Page31/E3/letter-bg.jpg); background-position: center; background-repeat: no-repeat; background-size: cover; background-color: transparent;
  // 							flex: 3; display: flex; flex-direction: column; gap: 5px; padding: 7px 70px;'>
  // 							<div><sup>1</sup>_____ Lily.</div>
  // 							<div>
  // 								Thanks for your letter. How <sup>2</sup>_____ you?
  // 								I had a great weekend.
  // 								Lauren came over and we watched the third Hobbit film on DVD.
  // 								I saw the second film last year and I loved it.
  // 							</div>
  // 							<div>
  // 								I enjoyed the film, but I didn’t think it was brilliant.
  // 								There are some exciting scenes, but it’s really long and I got a bit bored by the end.
  // 							</div>
  // 							<div>
  // 								Before the film, we made pizzas and popcorn.
  // 								We ate them while we were watching the film.
  // 								(The pizza was a bit burned!)
  // 							</div>
  // 							<div>
  // 								Would you like to go shopping next weekend?
  // 								I need to buy a birthday present for my dad,
  // 								but I want to find some clothes for me at the same time.
  // 								How about meeting for lunch too?
  // 							</div>
  // 							<div>
  // 								Anyway, that’s all for <sup>3</sup>_____ . Write again <sup>4</sup>_____.
  // 							</div>
  // 							<div><sup>5</sup>_____ wishes,</div>
  // 							<div>Paige</div>
  // 						</div>
  // 					</div>
  // 		  `,
  // 			answer: [],
  // 		},
  // 	],
  // },
  6: {
    unit: "Unit 3",
    id: "WB10-U3-P31-E6",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page31/E6/key/answerKey.png",
    component: T6,
    hideBtnFooter: true,
    // selectStyle: {
    //   color: "gray",
    //   fontWeight: "bold",
    //   width: 40,
    //   textAlign: "center",
    //   border: "2px solid black",
    //   borderRadius: "20%",
    // },
    // isHiddenCheck: true,

    // selectOptionValues: ["✓", "✗"],
    titleQuestion: [
      {
        num: "6",
        title: `
				Write a letter for the task in exercise 5. Remember to include
extra information for each point. Do not copy ideas from the
model in exercise 3.
			  `,
        color: "black",
      },
    ],
    // stylesTextInput: {
    //   width: 36,
    //   height: 36,
    //   borderRadius: 5,
    //   borderBottom: "dash",
    // textAlign: "center",
    //   border: "2px solid black",
    //   // backgroundColor: "#fee4cb",
    //   fontSize: 22,
    // },
    // maxLength: 14,
    // inputSize: 180,

    titleImage: "",
    questions: [
      {
        title: `
        <div style=' line-height:50px'>
           <input id='0' width='200px'/> (greeting)
           <br><input id='1' width='700px'/> (introduction)
           <br><input id='2' width='700px'/> (what film?)
           <br><input id='3' width='700px'/> (your opinion)
           <br><input id='4' width='850px'/> 
           <br><input id='5' width='850px'/> 
           <br><input id='6' width='700px'/> (another activity)
           <br><input id='7' width='850px'/> 
           <br><input id='8' width='850px'/> 
           <br><input id='9' width='700px'/> (an invitation)
           <br><input id='10' width='850px'/> 
           <br><input id='11' width='700px'/> (final paragraph)
           <br><input id='12' width='850px'/> 
           <br><input id='13' width='850px'/> 
           <br><input id='14' width='200px'/> (close the letter)
           <br><input id='15' width='200px'/> (sign your name)
        </div>

			  `,
        answer: [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ],
      },
    ],
  },
  7: {
    // Exercise num
    unit: "Unit 3",
    id: "WB10-U3-P31-E3",
    audio: "",
    video: "",
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page31/E3/key/answerKey.png",

    component: DesignUnderLine,
    // recorder: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "black",
      },
    ],
    totalInput: 1,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page31/E7/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page31/E7/2.jpg" },
        { url: "img/FriendsPlus/Page31/E7/3.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page31/E7/4.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page31/E7/5.jpg" },
        { url: "img/FriendsPlus/Page31/E7/6.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page31/E7/7.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page31/E7/8.jpg" },
        { url: "img/FriendsPlus/Page31/E7/9.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page31/E7/10.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page31/E7/11.jpg" }],
    ],
  },
};

export default json;
