import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		// Exercise num
		unit: 'Unit 2',
		id: 'WB10-U2-P22-E1',
		audio: '',
		video: '',
		component: T6,
		checkUppercase: true,
		maxLength: 14,
		inputSize: 180,
		titleImage: '',
		exerciseKey: 'img/FriendsPlus/Page22/E1/Key/answerKey.png',
		titleQuestion: [
			{ num: '1', title: 'Label pictures 1–9 with the words below.' },
		],
		questionImage: [],
		questions: [
			{
				title: `
              <div style="color: rgb(0, 147, 69); display: inline-flex; font-weight: bold; gap: 30px; flex-wrap: wrap; width: 660px; line-height: 10px; margin: 15px">
                <div>boots</div>
                <div>dinghy</div>
                <div>helmet</div>
                <div>life jacket</div>
                <div>paddles</div>
                <div>poles</div>
                <div>rope</div>
                <div>rucksack</div>
                <div>safety harness </div>
              </div>
            <div style='display: flex; justify-content: space-between;'>
              <div>
                <img style='width:80%' src='img/FriendsPlus/Page22/E1/1.jpg'>
                <p>#</p>
              </div>
              <div>
                <img  style='width:80%'src='img/FriendsPlus/Page22/E1/2.jpg'>
                <p>#</p>
              </div>
              <div>
                <img  style='width:80%'src='img/FriendsPlus/Page22/E1/3.jpg'>
                <p>#</p>
              </div>
            </div>
            <div style='display: flex; justify-content: space-between;'>
              <div>
                <img style='width:80%' src='img/FriendsPlus/Page22/E1/4.jpg'>
                <p>#</p>
              </div>
              <div>
                <img  style='width:80%'src='img/FriendsPlus/Page22/E1/5.jpg'>
                <p>#</p>
              </div>
              <div>
                <img  style='width:80%'src='img/FriendsPlus/Page22/E1/6.jpg'>
                <p>#</p>
              </div>
            </div>
            <div style='display: flex; justify-content: space-between;'>
              <div>
                <img style='width:80%' src='img/FriendsPlus/Page22/E1/7.jpg'>
                <p>#</p>
              </div>
              <div>
                <img  style='width:80%'src='img/FriendsPlus/Page22/E1/8.jpg'>
                <p>#</p>
              </div>
              <div>
                <img  style='width:80%'src='img/FriendsPlus/Page22/E1/9.jpg'>
                <p>#</p>
              </div>
            </div>

          `,
				answer: [
					'life jacket',
					'rucksack ',
					'boots ',
					'helmet ',
					'rope ',
					'dinghy ',
					'paddles ',
					'safety harness',
					'poles',
				],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 2',
		id: 'WB10-U2-P22-E2',
		component: T6,
		checkDuplicated: true,
		stylesTextInput: {
			backgroundColor: 'transparent',
			borderBottom: '1px solid',
			padding: 10,
			textAlign: 'center',
		},
		exerciseKey: 'img/FriendsPlus/Page22/E2/Key/answerKey.png',
		inputSize: 180,
		maxLength: 1,
		isHiddenCheck: true,
		titleQuestion: [
			{
				num: '2',
				title: 'Which pieces of equipment from exercise 1 do you use for …',
			},
		],
		checkUppercase: true,
		questions: [
			{
				title: `
              <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1 </b></div><div style="margin-left: 30px; flex: 1; ">rock climbing? # # #</div></div>
              <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2 </b></div><div style="margin-left: 30px; flex: 1; ">kayaking? # # #</div></div>
              <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3 </b></div><div style="margin-left: 30px; flex: 1; ">walking / hiking? # # #</div></div>

        `,
				answer: [
					'helmet/safety harness/rope',
					'helmet/safety harness/rope',
					'helmet/safety harness/rope',
					'life jacket/helmet/paddles',
					'life jacket/helmet/paddles',
					'life jacket/helmet/paddles',
					'rucksack/boots/poles',
					'rucksack/boots/poles',
					'rucksack/boots/poles',
				],
			},
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 3',
		id: 'WB10-U2-P22-E3',
		titleQuestion: [
			{
				num: '1',
				title: 'Circle the correct adjective',
			},
		],
		component: Circle_Write,
		exerciseKey: 'img/FriendsPlus/Page22/E3/Key/answerKey.png',
		titleQuestion: [
			{
				num: '3',
				title:
					'Choose the correct words to complete the sentences about the photo below.',
			},
		],
		question: {
			Write: {
				inputStyle: {},
				answers: [],
				initialValue: [],
			},
			Circle: {
				// initialWordStyle: { margin: '5px 0', border: 'solid 2px', borderColor: 'black', borderRadius: '50%', padding: '0 5px' },
				initialWordStyle: {
					margin: '5px 0',
					border: 'none',
					borderColor: 'transparent',
					borderRadius: '50%',
				},
				selectWordStyle: {
					border: 'solid 2px',
					borderColor: 'rgb(0, 147, 69)',
					margin: '-2px',
					padding: 5,
				},
				limitSelect: 1,
				listWords: [
					' seems / shows',
					'look / look_as_if ',
					' on / at',
					'At / In',
					' as / like',
				],
				answers: ['0-6', '2-2', '1-0', '3-4', '4-2'],
				initialValue: [],
			},
			Layout: `
            <div style="display: flex">
              <div style="flex: 2">
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1 </b></div><div style="margin-left: 30px; flex: 1; ">The photo <b><input id='0' type='Circle' /></b> two children on a climbing wall. </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2 </b></div><div style="margin-left: 30px; flex: 1; ">They <b><input id='1' type='Circle' /></b> quite young.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3 </b></div><div style="margin-left: 30px; flex: 1; ">The boy <b><input id='2' type='Circle' /></b> the left is looking down. </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4 </b></div><div style="margin-left: 30px; flex: 1; "><b><input id='3' type='Circle' /></b> the background, there are some buildings. </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5 </b></div><div style="margin-left: 30px; flex: 1; ">It looks <b><input id='4' type='Circle' /></b> if the boys are having a good time.</div></div>
                </div>  
              <div style="flex: 1.2"><img  style='width:80%' src='img/FriendsPlus/Page22/E3/1.jpg'></div>
            </div>
      `,
		},
	},
	4: {
		// Exercise num
		unit: 'Unit 2',
		id: 'WB10-U2-P22-E4',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page22/E2/Key/answerKey.png',
		inputSize: 180,
		maxLength: 1,
		hideBtnFooter: true,
		titleQuestion: [
			{
				num: '4',
				title:
					'Read the <span style="color: rgb(0, 147, 69)">Speaking Strategy</span>. Then write five sentences to describe the photo below. Use phrases from exercise 3.',
			},
		],
		checkUppercase: true,
		questions: [
			{
				title: `
              <div style="display: flex; justify-content: space-between;">
                <div><img  style='width:90%' src='img/FriendsPlus/Page22/E4/1.jpg'></div>
                <div><img  style='width:90%' src='img/FriendsPlus/Page22/E4/2.jpg'></div>
              </div>
              <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1 </b></div><div style="margin-left: 30px; flex: 1; display:block">(general description)  <textarea></textarea></div></div>
              <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2 </b></div><div style="margin-left: 30px; flex: 1; display:block">(details ×3)  <textarea></textarea></div></div>
              <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3 </b></div><div style="margin-left: 30px; flex: 1; display:block">(speculation / comment) <textarea></textarea></div></div>

        `,
				answer: [],
			},
		],
	},

	5: {
		// Exercise num
		unit: 'Unit 2',
		id: 'WB10-U2-P22-E5',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page22/E2/Key/answerKey.png',
		inputSize: 180,
		maxLength: 1,
		hideBtnFooter: true,
		titleQuestion: [
			{
				num: '5',
				title: `Read the examiner's questions in the task and make notes.`,
			},
		],
		checkUppercase: true,
		questions: [
			{
				title: `
              <div style="padding: 20px; background-color: rgb(209, 222, 241); border-radius: 15px; width: 800px; box-shadow: 3px 3px 20px inset white">
                <b>Describe the photo and answer the questions.</b>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1 </b></div><div style="margin-left: 30px; flex: 1; display:block">Why do some people enjoy doing risky sports?</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2 </b></div><div style="margin-left: 30px; flex: 1; display:block">Is doing risky sports the best way to see spectacular scenery, in your opinion? Why? / Why not?</div></div>
              </div>
              <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1 </b></div><div style="margin-left: 30px; flex: 1; display:block"><textarea id='0' rows='4'></textarea ></div></div>
              <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2 </b></div><div style="margin-left: 30px; flex: 1; display:block"><textarea id='1' rows='4'></textarea ></div></div>

        `,
				answer: [],
			},
		],
	},

	6: {
		// Exercise num
		unit: 'Unit 2',
		id: 'WB10-U2-P22-E6',
		audio: '',
		video: '',
		component: UI,
		titleQuestion: [
			{
				num: '6',
				title: 'Now do the task using your notes from exercises 5.',
			},
		],
		questionImage: [],
	},
};

export default json;
