import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
const json = {
	1: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P5-E1',
		audio: '',
		video: '',
		component: T6,
		// maxLength: 1,
		isHiddenCheck: true,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 22,
			borderBottom: 'dotted 1px',
		},
		titleQuestion: [
			{
				num: '1',
				title:
					'Complete the dialogues with the correct present simple form of the verbs in brackets.',
			},
		],
		exerciseKey: 'img/FriendsPlus/Page5/E1/Key/answerKey.png',
		inputSize: 160,
		questionImage: [],
		questions: [
			{
				title: `
        <div style="display: flex; margin-block: 30px">
            <div style="flex: 1"><b>1</b></div>
            <div style="flex: 25">
                <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>A:</b></div> <div style="flex: 15"> # (you / go) to the gym every day?</div></div>
                <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>B:</b></div> <div style="flex: 15"> No, I  # (not go) every day. I  # (go) three times a week.</div></div>
            </div>
        </div>
        <div style="display: flex; margin-block: 30px">
            <div style="flex: 1"><b>2</b></div>
            <div style="flex: 25">
                <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>A:</b></div> <div style="flex: 15"> <input id='3' width='200px'/> (Sam / study) at your school?</div></div>
                <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>B:</b></div> <div style="flex: 15">No, he  # (not study) there. He  # (study) at a private school.</div></div>
            </div>
        </div>
        <div style="display: flex; margin-block: 30px">
            <div style="flex: 1"><b>3</b></div>
            <div style="flex: 25">
                <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>A:</b></div> <div style="flex: 15">Does your mother  # (teach) English?</div></div>
                <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>B:</b></div> <div style="flex: 15">Yes, she does. She also  # (teach) French and Spanish. She  # (be) very good at languages.</div></div>
            </div>
        </div>
        <div style="display: flex; margin-block: 30px">
            <div style="flex: 1"><b>4</b></div>
            <div style="flex: 25">
                <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>A:</b></div> <div style="flex: 15"> <input id='8' width='250px'> (your parents / work) in London?</div></div>
                <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>B:</b></div> <div style="flex: 15">No, they don't. They both  # (work) in Oxford.</div></div>
            </div>
        </div>
        <div style="display: flex; margin-block: 30px">
            <div style="flex: 1"><b>5</b></div>
            <div style="flex: 25">
                <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>A:</b></div> <div style="flex: 15"> <input id='8' width='220px'> (you / practise) much before you perform a new play?</div></div>
                <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>B:</b></div> <div style="flex: 15">Yes, we  # (practise) for weeks before the first performance, and the director # (make) a lot of changes.</div></div>
            </div>
        </div>
        `,
				answer: [
					' Do you go',
					"don't go",
					'go',
					' Does Sam study',
					"doesn't study",
					'studies',
					' teach',
					'teaches',
					"'s",
					' Do your parents work',
					'work',
					' Do you practise',
					'practise',
					'makes',
				],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P5-E2',
		audio: '',
		video: '',
		component: T6,
		isHiddenCheck: true,
		stylesTextInput: {
			backgroundColor: 'transparent',
			padding: 0,
			fontSize: 22,
			borderBottom: 'solid 1px',
		},
		titleQuestion: [
			{
				num: '2',
				title:
					'Complete the voice message with the correct present continuous form of the verbs in brackets.',
			},
		],
		exerciseKey: 'img/FriendsPlus/Page5/E2/Key/answerKey.png',
		inputSize: 160,
		questions: [
			{
				title: `
        <div style=" background-color: rgb(253, 216, 178); padding: 20px; border-radius: 20px; width: 635px; box-shadow: 5px 5px 10px grey ">
            Hi Steve! It's Sean. How are things? I hope you are well. 
            <sup>1</sup> # (you / have) a good time in Hull? 
            I <sup>2</sup> # (call) from my grandparents' house 
            in Cornwall. My sister and I <sup>3</sup> # (stay) 
            here for a couple of weeks, and we 
            <sup>4</sup> # (enjoy) it a lot. I <sup>5</sup> # (plan) to study for 
            my exams later, but now I <sup>6</sup> # (relax). 
            I'm not totally lazy, though – I <sup>7</sup> # (run) every 
            morning. Jake and I <sup>8</sup> # (think) about 
            spending a month in Spain. Would you like to go with 
            us? Let me know! Well, the sun <sup>9</sup> # (shine) 
            so I think I'll go for a walk. Bye!
        </div>
        `,
				answer: [
					' Are you having ',
					" 'm calling ",
					' are staying',
					' are enjoying ',
					' am planning ',
					'am relaxing',
					' am running ',
					' are thinking ',
					' is shining',
				],
			},
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P5-E3',
		audio: '',
		video: '',
		component: T6,
		isHiddenCheck: true,
		stylesTextInput: {
			backgroundColor: 'transparent',
			borderRadius: '10px',
			border: '1px solid',
			padding: 10,
			fontSize: 22,
			flex: 'end',
		},
		titleQuestion: [
			{
				num: '3',
				title: 'Match sentences a–f with the uses of the present tense (1–6).',
			},
		],
		exerciseKey: 'img/FriendsPlus/Page5/E3/Key/answerKey.png',
		inputSize: 33,
		questionImage: [],
		questions: [
			{
				title: `
               <div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1 </b></div><div style="margin-left: 30px; flex: 1;display: flex "><div style="flex: 12"> something that happens regularly</div><div style="flex: 1"> #</div></div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2 </b></div><div style="margin-left: 30px; flex: 1;display: flex "><div style="flex: 12"> verbs not used in continuous tenses </div><div style="flex: 1"> #</div></div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3 </b></div><div style="margin-left: 30px; flex: 1;display: flex "><div style="flex: 12"> something happening at this moment</div><div style="flex: 1"> #</div></div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4 </b></div><div style="margin-left: 30px; flex: 1;display: flex "><div style="flex: 12"> stating a fact or law</div><div style="flex: 1"> #</div></div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5 </b></div><div style="margin-left: 30px; flex: 1;display: flex "><div style="flex: 12"> something happening around this time</div><div style="flex: 1"> #</div></div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6 </b></div><div style="margin-left: 30px; flex: 1;display: flex "><div style="flex: 12"> a future plan </div><div style="flex: 1"> #</div></div></div>
                <div style="display: flex; margin-top: 30px"><div style="display: inline-block; width: 40px; text-align: right;"><b>a </b></div><div style="margin-left: 30px; flex: 1; ">I am leaving for London at 6 a.m. tomorrow.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>b </b></div><div style="margin-left: 30px; flex: 1; ">The Earth goes around the sun.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>c </b></div><div style="margin-left: 30px; flex: 1; ">I always play basketball on Saturdays.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>d </b></div><div style="margin-left: 30px; flex: 1; ">They are spending a lot of time together these days.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>e </b></div><div style="margin-left: 30px; flex: 1; ">I need some help with homework.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>f </b></div><div style="margin-left: 30px; flex: 1; ">Look outside – it's snowing!</div></div>
              </div>
        `,
				answer: ['c ', 'e ', 'f ', 'b ', 'd ', 'a'],
			},
		],
	},
	4: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P5-E4',
		audio: '',
		video: '',
		component: T6,
		// maxLength: 1,
		isHiddenCheck: true,
		titleQuestion: [
			{
				num: '4',
				title:
					'Complete the sentences with the correct present simple or present continuous form of the verbs in brackets.',
			},
		],
		exerciseKey: 'img/FriendsPlus/Page5/E4/Key/answerKey.png',
		inputSize: 160,
		questionImage: [],
		questions: [
			{
				title: `
            <div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> Josh # at least one point in every game he plays. (score)</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> I # that they will give Kate a place on the team. (believe)</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> Susan # to finish her homework today. (try)</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; ">  My dad # Chinese food tonight. (cook)</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; "> My brother loves books. He # a book every week. (read)</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; "> I # all of my old Friends DVDs this week. (watch)</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>7. </b></div><div style="margin-left: 30px; flex: 1; "> Sam # before 7 a.m., even on Sundays. (get up)</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>8. </b></div><div style="margin-left: 30px; flex: 1; "> Look out of the window. A big storm #  ! (come)</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>9. </b></div><div style="margin-left: 30px; flex: 1; "> Lisa usually # video games when she gets home. (play)</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>10. </b></div><div style="margin-left: 30px; flex: 1; "> I # what you’re saying, but I don’t agree with it. (understand)</div></div>
            </div>
        `,
				answer: [
					' scores ',
					'believe ',
					' is trying ',
					' is cooking',
					' reads ',
					" 'm watching ",
					' gets up ',
					' is coming',
					' plays ',
					' understand',
				],
			},
		],
	},
	5: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P5-E5',
		audio: '',
		video: '',
		component: T6,
		// maxLength: 1,
		isHiddenCheck: true,
		stylesTextInput: {
			backgroundColor: 'transparent',
			padding: 0,
			fontSize: 20,
		},
		exerciseKey: 'img/FriendsPlus/Page5/E5/Key/answerKey.png',
		inputSize: 120,
		titleQuestion: [
			{
				num: '5',
				title:
					'Complete the email with the correct present simple or present continuous form of the verbs in brackets.',
			},
		],
		questions: [
			{
				title: `
        <div style=" position: relative; font-size: 18px">
          <div> <img src='img/FriendsPlus/Page5/E5/1.jpg' /> </div>
          <div style=" position: absolute; top: 63px; left: 23px;width: 580px">
            Hi Lauren,</br>
            How <sup>1</sup> # (be) everything? I hope you </br>
            <sup>2</sup> # (have) a great time at football camp this week. I </br>
            <sup>3</sup> # (enjoy) my summer drama project. Every summer we </br>
            <sup>4</sup> # (put on) a play by Shakespeare, and this year we </br>
            <sup>5</sup> # (work) on A Midsummer Night's Dream. It's a great play because it
            <sup>6</sup> # (have) so many different roles. We </br>
            <sup>7</sup> # (practise) about six hours a day and we're performing</br> the play in
            public next week. I <sup>8</sup> # (try) very hard to learn my lines. </br>
            I <sup>9</sup> # (play) two parts in the play, and sometimes I </br>
            <sup>10</sup> # (forget) which part I'm playing! Anyway, I </br>
            <sup>11</sup> # (believe) it will be a great performance. My grandparents
            <sup>12</sup> # (travel) from Leeds next week just to see me in the play. </br>
            I know you <sup>13</sup> # (be) very busy at camp, but I </br>
            <sup>14</sup> # (hope) to hear from you soon.</br></br>
            Take care!</br>
            Daisy</br>
          </div>
        </div>
        `,
				answer: [
					' is ',
					" 're having ",
					" 'm enjoying ",
					' put on',
					" 're working ",
					' has ',
					" 're practising ",
					" 'm trying",
					" 'm playing ",
					' forget ',
					' believe ',
					' are travelling',
					" 're ",
					' hope',
				],
			},
		],
	},
};

export default json;
