import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots'

const json = {
  1: {
    unit: 'Unit 5',
    id: 'WB10-U5-P45-E1',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '',
        title: '',
        color: '#000000',
      },
    ],
    stylesTextInput: {
      textAlign: 'center',
      fontSize: 20,
      width: 180,
      borderBottom: 'none',
      backgroundColor: 'transparent',
    },
    // maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page45/E1/Key/answerKey.png',
    isHiddenCheck: true,
    questions: [
      // Row
      {
        title: /*html */ `
          <div style="display: flex;align-items: stretch; column-gap: 10px ">
            <div style=" position: relative;">
              <img src="img/FriendsPlus/Page45/E1/1.png" style="width: 700px; object-fit: contain;">
              <div style="position: absolute; top: calc(30% + 10px ); left: calc(10%  + 36px)">#</div>
              <div style="position: absolute; top: calc(30% + 42px ); left: calc(20% + 50px)">#</div>
              <div style="position: absolute; top: calc(30% + 104px ); left: calc(20% + 54px)">#</div>
              <div style="position: absolute; top: calc(30% + 166px ); left: calc(20% + 208px)">#</div>
              <div style="position: absolute; top: calc(30% + 229px ); left: calc(20% + 138px)">#</div>
              <div style="position: absolute; top: calc(30% + 291px ); left: calc(20% + 132px)">#</div>
              <div style="position: absolute; top: calc(30% + 386px ); left: calc(20% + 109px)">#</div>
              <div style="position: absolute; top: calc(30% + 417px ); left: calc(20% + 59px)">#</div>
         
            </div>
            
          </div>
        `,
        answer: ['a job', 'an advertisement ', 'an application ', 'a job', 'a team', 'a degree', 'the job', 'the day'],
      },
    ],
  },
  2: {
    unit: 'Unit 5',
    id: 'WB10-U5-P45-E2',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '2',
        title: 'Look quickly at texts A–C. Match each text with the correct photo (1–3).',
        color: '#000000',
      },
    ],
    stylesTextInput: {
      textAlign: 'center',
      fontSize: 20,
      width: 40,
      borderBottom: '1px solid',
      border: '1px solid',
      borderRadius: 5,
      backgroundColor: 'transparent',
    },
    maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page45/E2/Key/answerKey.png',
    isHiddenCheck: true,
    questions: [
      // Row
      {
        title: /*html */ `
          <div>
            <div style=" position: relative;">
              <img src="img/FriendsPlus/Page45/E2/1.png" style="width: 800px; object-fit: contain;">
            </div>
            <div style="display: flex; align-items: center; column-gap: 20px; margin-top: 30px">
              <strong>1 - </strong>
              <input />
            </div>
            <div style="display: flex; align-items: center; column-gap: 20px">
              <strong>2 - </strong>
              <input />
            </div>
            <div style="display: flex; align-items: center; column-gap: 20px">
              <strong>3 - </strong>
              <input />
            </div>
            
          </div>
        `,
        answer: ['A', 'C', 'B'],
      },
    ],
  },
  3: {
    unit: 'Unit 5',
    id: 'WB10-U5-P45-E3',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '3',
        title: 'Read the <span style="color: rgb(62 63 153);">Reading Strategy</span>. Then complete the sentences with information from the text.',
        color: '#000000',
      },
    ],
    stylesTextInput: {
      textAlign: 'center',
      fontSize: 20,
      width: 150,
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    // maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page45/E3/Key/answerKey.png',
    isHiddenCheck: true,
    questions: [
      // Row
      {
        title: /*html */ `
        <div style="width: 1200px;display: flex;align-items: stretch; column-gap: 20px">
          <div style="width: calc(500px - 20px);height: 260px;border-radius: 10px;border: 3px dotted rgb(62 63 153); padding: 1rem; background-color: rgb(227 226 241);">
            <h3 style="color: rgb(62 63 153); font-size: 25px; font-weight: 700">Reading Strategy</h3>
            <span style="font-size: 18px">When you complete gap-fill sentences about a text,
            the words you need to write are in the text. However,
            the words immediately before or after the gap may be
            different. Think carefully about the meaning and look
            for synonyms and paraphrases.</span>
          </div>
          <div style="width: 600px">
            <div style="display:flex; gap: 20px; margin-bottom: 15px">
              <strong>1</strong>
                <div > 
                  <span>Jeff Haslam's job is to keep the</span>
                  <input />
                  <span>clear in London. (1 word)</span>
                </div>
            </div>            
            <div style="display:flex; gap: 20px; margin-bottom: 15px">
              <strong>2</strong>
              <div> 
                <span>Jeff has a</span>
                <input />
                <span>on his helmet because he works in the dark. (1 word)</span>
              </div>
            </div>
            <div style="display:flex; gap: 20px; margin-bottom: 15px">
              <strong>3</strong>
              <div > 
                <span>Charlie Radley spends a week at a time on his</span>
                <input />
                <span>. (2 words)</span>
              </div>
            </div>
            <div style="display:flex; gap: 20px; margin-bottom: 15px">
              <strong>4</strong>
              <div > 
                <span>Charlie wants to get a different</span>
                <input />
                <span>in the next
                year or two. (1 word)
                </span>
              </div>
            </div>
            <div style="display:flex; gap: 20px; margin-bottom: 15px">
              <strong>5</strong>
              <div > 
                <span>Sandy Smith first found out about her job on</span>
                <input />
                <span>. (2 word)</span>
              </div>
            </div>
            <div style="display:flex; gap: 20px; margin-bottom: 15px">
              <strong>6</strong>
              <div > 
                <span>Lumberjacks have to work</span>
                <input />
                <span>but do not earn a lot of money. (2 words)</span>
              </div>
            </div>
          </div>
        </div>
        `,
        answer: ['sewers', 'light', 'fishing boat', 'job', 'the internet', 'long hours'],
      },
    ],
  },
}

export default json
