import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  4: {
    unit: "Cumulative",
    id: "FG10-C-P75-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page75/E4/Key/answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "4",
        title: `
            <span style="color: rgb(0, 110, 147)"> GRAMMAR AND VOCABULARY</span> 
             Choose the best options to fill in the blanks.
            `,
        color: "#000",
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "",
          color: "#000",
          textAlign: "center",
          maxLength: 16,
        },
        answers: [],
        initialValue: [],

      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
        },
        selectWordStyle: {
          border: "solid 3px",
          borderColor: "gray",
          padding: "5px",
        },
        limitSelect: 1,
        listWords: [
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
        ],
        answers: ['0-0', '1-4', '2-8', '3-4', '4-4', '5-8', '6-0', '7-0', '8-8', '9-4'],
        initialValue: [],
      },
      Layout: `
            <div style="background-color: rgb(254, 241, 226); border-radius: 20px; height: 980px; width: 900px ;padding: 30px">
              <img src="img/FriendsPlus/Page75/E4/1.jpg" alt="image" width=55%/>
              <div style="margin-top: 20px">
                <span>Going to the cinema is supposed to be fun, but sometimes, this
                is not the case. For example, how <b><sup>1</sup></b>___ times have you heard
                a mobile phone ring while you <b><sup>2</sup></b>___ a film? This is one of the
                most <b><sup>3</sup></b>___ things that can happen in the cinema. We are always
                reminded to turn off our mobiles before the film starts, so there
                really aren't <b><sup>4</sup></b>___ excuses. And if you forget, you <b><sup>5</sup></b>___ answer
                your phone, because the person can leave a message on your
                voicemail.<br/>
                Sitting next to a baby can also be a pain. Unfortunately, small
                children don't understand that they <b><sup>6</sup></b>___ be noisy in the
                cinema. <b><sup>7</sup></b>___ parents may not have anybody to leave their
                children with, but they don't <b><sup>8</sup></b>___ to go to the cinema to see a
                film. Instead, they could watch one online or rent a DVD.<br/>
                Finally, there is the issue of people talking. Most of us whisper
                when we want to say something, but a <b><sup>9</sup></b>___ people don't seem to
                know how. I'm afraid I don't have <b><sup>10</sup></b>___ patience with these rude
                people, and I usually tell them to be quiet!</span>
              </div>
            </div>
  
      <div style="position: relative; width: 800px; margin-top:10px;">
        
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;1 </b></div>
          <div style='flex: 3;'>many</div>
          <div style='flex: 3;'>much</div>
          <div style='flex: 3;'>some</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;2 </b></div>
          <div style='flex: 3;'>watch</div>
          <div style='flex: 3;'>were watching</div>
          <div style='flex: 3;'>watched</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;3 </b></div>
          <div style='flex: 3;'>annoyed</div>
          <div style='flex: 3;'>cross</div>
          <div style='flex: 3;'>annoying</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;4 </b></div>
          <div style='flex: 3;'>a lot</div>
          <div style='flex: 3;'>any</div>
          <div style='flex: 3;'>some</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;5 </b></div>
          <div style='flex: 3;'>mustn’t</div>
          <div style='flex: 3;'>needn’t</div>
          <div style='flex: 3;'>don’t</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;6 </b></div>
          <div style='flex: 3;'>needn’t</div>
          <div style='flex: 3;'>don’t</div>
          <div style='flex: 3;'>mustn’t</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;7 </b></div>
          <div style='flex: 3;'>Some</div>
          <div style='flex: 3;'>Any</div>
          <div style='flex: 3;'>A</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;8 </b></div>
          <div style='flex: 3;'>have</div>
          <div style='flex: 3;'>must</div>
          <div style='flex: 3;'>needn’t</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;9 </b></div>
          <div style='flex: 3;'>little</div>
          <div style='flex: 3;'>lot</div>
          <div style='flex: 3;'>few</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>10</b></div>
          <div style='flex: 3;'>many</div>
          <div style='flex: 3;'>much</div>
          <div style='flex: 3;'>some</div>
        </div>

        <div style="position: absolute; top: 0px; left: 49px; "><input id='0' type='Circle' /></div>
        <div style="position: absolute; top: 37px; left: 49px; "><input id='1' type='Circle' /></div>
        <div style="position: absolute; top: 76px; left: 49px; "><input id='2' type='Circle' /></div>
        <div style="position: absolute; top: 113px; left: 49px; "><input id='3' type='Circle' /></div>
        <div style="position: absolute; top: 152px; left: 49px; "><input id='4' type='Circle' /></div>
        <div style="position: absolute; top: 189px; left: 49px; "><input id='5' type='Circle' /></div>
        <div style="position: absolute; top: 227px; left: 49px; "><input id='6' type='Circle' /></div>
        <div style="position: absolute; top: 264px; left: 49px; "><input id='7' type='Circle' /></div>
        <div style="position: absolute; top: 302px; left: 49px; "><input id='8' type='Circle' /></div>
        <div style="position: absolute; top: 340px; left: 49px; "><input id='9' type='Circle' /></div>

      </div>
        `,
    },
  },
  5: {
    unit: "Cumulative",
    id: "FG10-C-P75-E5",
    audio: "",
    video: "",
    exerciseKey: "",
    component: T6,
    textareaStyle: { width: 1000, resize: 'none', borderBottom: 'none' },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "5",
        title: `
            <span style="color: rgb(0, 110, 147)"> WRITING</span> 
            You and a friend watched the first season of a TV series last
            weekend. Write a letter to another friend in which you:
            `,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
            <div>
                <ul>
                  <li>say which series you watched and who you watched it with.</li>
                  <li>give your personal opinion of the series.</li>
                  <li>mention what you did after you finished watching the series.</li>
                  <li>invite your friend to watch the next season of the series with
                  you soon.</li>
                </ul>
            </div>
                <textarea id="1" rows="7" ></textarea>
            `,
        answer: [],
      },
    ],
  },
};

export default json;
