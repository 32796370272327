import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
  1: {
    unit: 'Unit 6',
    id: 'WB10-U6-P49-E1',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '1',
        title: 'Complete the second conditional sentences with the correct form of the verbs below. ',
        color: '#000000',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      width: 200,
      textAlign: 'center',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    // maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page49/E1/Key/answerKey.png',
    isHiddenCheck: true,
    questions: [
      // Row
      {
        title: /*html */ `
          <div style="display: flex; column-gap: 20px">
            <span style="color: rgb(246 140 31); font-weight: 600">be</span>
            <span style="color: rgb(246 140 31); font-weight: 600">can</span>
            <span style="color: rgb(246 140 31); font-weight: 600">feel</span>
            <span style="color: rgb(246 140 31); font-weight: 600">have</span>
            <span style="color: rgb(246 140 31); font-weight: 600">help</span>
            <span style="color: rgb(246 140 31); font-weight: 600">not stay up</span>
            <span style="color: rgb(246 140 31); font-weight: 600">not take</span>
            <span style="color: rgb(246 140 31); font-weight: 600">win</span>
          </div>
          <div>
            <div style="display: flex; column-gap: 20px">
              <strong>1</strong>
              <div>
                <span>If you had a problem, I</span>
                <input />
                <span>you.</span>
              </div>
            </div>
            <div style="display: flex; column-gap: 20px">
              <strong>2</strong>
              <div>
                <span>If I</span>
                <input />
                <span>more time, I'd see more of my friends.</span>
              </div>
            </div>
            <div style="display: flex; column-gap: 20px">
              <strong>3</strong>
              <div>
                <span>I</span>
                <input />
                <span>the bus to school if I had a bike.</span>
              </div>
            </div>
            <div style="display: flex; column-gap: 20px">
              <strong>4</strong>
              <div>
                <span>If you</span>
                <input />
                <span>so late, you wouldn't be so tired in
                the morning.</span>
              </div>
            </div>
            <div style="display: flex; column-gap: 20px">
              <strong>5</strong>
              <div>
                <span>How</span>
                <input />
                <span>you</span>
                <input />
                <span>if Sam didn't invite you to his party?</span>
              </div>
            </div>
            <div style="display: flex; column-gap: 20px">
              <strong>6</strong>
              <div>
                <span>My parents</span>
                <input />
                <span>cross if I didn't revise for my exams.</span>
              </div>
            </div>
            <div style="display: flex; column-gap: 20px">
              <strong>7</strong>
              <div>
                <span>You</span>
                <input />
                <span>afford a new mobile if you didn't waste your money on DVDs and games.</span>
              </div>
            </div>
            <div style="display: flex; column-gap: 20px">
              <strong>8</strong>
              <div>
                <span>If I</span>
                <input />
                <span>the lottery, I'd buy my mum a new car.</span>
              </div>
            </div>
          </div>
          
          `,
        answer: [
          "would help",
          "had",
          "wouldn't take",
          "didn't stay up",
          "would",
          "feel",
          "would be",
          "could",
          "won",
        ],
      },
    ],
  },
  2: {
    unit: 'Unit 6',
    id: 'WB10-U6-P49-E2',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '2',
        title: 'Complete the money-saving tips with the correct form of the verbs in brackets.',
        color: '#000000',
      },
    ],
    stylesTextInput: {
      fontSize: 16,
      width: 135,
      textAlign: 'center',
      borderBottom: 'none',
      backgroundColor: 'transparent',
    },
    // maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page49/E2/Key/answerKey.png',
    isHiddenCheck: true,
    questions: [
      // Row
      {
        title: /*html */ `
          <div style="position: relative;">
            <img src="img/FriendsPlus/Page49/E2/1.png" style="width: 500px" />
            <div style="position: absolute; top: calc(25% + 20px ); left: calc(10% - 20px  + 91px)">#</div>
            <div style="position: absolute; top: calc(25% + 43px ); left: calc(10% - 20px  + 67px)">#</div>
            <div style="position: absolute; top: calc(25% + 76px ); left: calc(10% - 20px  + 125px)">#</div>
            <div style="position: absolute; top: calc(25% + 100px ); left: calc(10% - 20px  + 156px)">#</div>
            <div style="position: absolute; top: calc(25% + 155px ); left: calc(10% - 20px  + 92px)">#</div>
            <div style="position: absolute; top: calc(25% + 178px ); left: calc(10% - 20px  + 193px)">#</div>
            <div style="position: absolute; top: calc(25% + 234px ); left: calc(10% - 20px  + 90px)">#</div>
            <div style="position: absolute; top: calc(25% + 257px ); left: calc(10% - 20px  + 277px)">#</div>
            <div style="position: absolute; top: calc(25% + 313px ); left: calc(10% - 20px  + 138px)">#</div>
            <div style="position: absolute; top: calc(25% + 336px ); left: calc(10% - 20px  + 247px)">#</div>
            <div style="position: absolute; top: calc(25% + 391px ); left: calc(10% - 20px  + 183px)">#</div>
            <div style="position: absolute; top: calc(25% + 415px ); left: calc(10% - 20px  + 133px)">#</div>
            <div style="position: absolute; top: calc(25% + 470px ); left: calc(10% - 20px  + 90px)">#</div>
            <div style="position: absolute; top: calc(25% + 493px ); left: calc(10% - 20px  + 236px)">#</div>
           

          </div>
          `,
        answer: [
          "drove",
          "wouldn't use / would not use",
          "bought",
          "wouldn't throw / would not throw",
          "borrowed",
          "wouldn't have to / would not have to",
          "drank",
          "would save",
          "wore",
          "could",
          "were",
          "would spend",
          "turned off",
          "wouldn't use / would not use",
        ],
      },
    ],
  },
  3: {
    unit: 'Unit 6',
    id: 'WB10-U6-P49-E3',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '3',
        title: 'Complete the second conditional questions in the quiz below with the correct form of the verbs in brackets. Then do the quiz.',
        color: '#000000',
      },
    ],
    stylesTextInput: {
      fontSize: 16,
      width: 135,
      textAlign: 'center',
      borderBottom: '1px solid',
      backgroundColor: 'transparent',
    },
    // maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page49/E3/Key/answerKey.png',
    isHiddenCheck: true,
    questions: [
      // Row
      {
        title: /*html */ `
        <div style="position: relative;">
          <img src="img/FriendsPlus/Page49/E3/1.png" style="width: 700px"/>
          <div style="width: 450px; position: absolute; top: 150px; left: 70px">
              <div style=" font-size: 17px">
                <div>
                  <strong>1</strong>
                  <span style="margin-left: 20px; ">you</span>
                  <input />
                  <span style="">(see) a road accident?</span>
                </div>
                <div style="padding: 0 20px">
                  <div style="; display:flex; column-gap: 15px; margin-top: 5px; align-items: center">
                    <div style="border: 1px solid rgb(0 0 0 ); border-radius: 5px; width: 25px; height: 25px"></div>
                    <span>film it on my mobile</span>
                  </div>
                  <div style="; display:flex; column-gap: 15px; margin-top: 5px; align-items: center">
                    <div style="border: 1px solid rgb(0 0 0 ); border-radius: 5px; width: 25px; height: 25px"></div>
                    <span>call the police</span>
                  </div>
                  <div style="; display:flex; column-gap: 15px; margin-top: 5px; align-items: center">
                    <div style="border: 1px solid rgb(0 0 0 ); border-radius: 5px; width: 25px; height: 25px"></div>
                    <span>help the people in the accident</span>
                  </div>
                </div>
              </div>
              <div style=" font-size: 17px">
                <div>
                  <strong >2</strong>
                  <span style="margin-left: 20px;">your friend</span>
                  <input />
                  <span >(give) you a present that you</span>
                  <input />
                  <span>(not like)?</span>
                </div>
                <div style="padding: 0 20px">
                  <div style="display:flex; column-gap: 15px; margin-top: 5px; align-items: center">
                    <div style="border: 1px solid rgb(0 0 0 ); border-radius: 5px; width: 25px; height: 25px"></div>
                    <span>throw it awa</span>
                  </div>
                  <div style="display:flex; column-gap: 15px; margin-top: 5px; align-items: center">
                    <div style="border: 1px solid rgb(0 0 0 ); border-radius: 5px; width: 25px; height: 25px"></div>
                    <span>ask your friend to change it</span>
                  </div>
                  <div style="display:flex; column-gap: 15px; margin-top: 5px; align-items: center">
                    <div style="border: 1px solid rgb(0 0 0 ); border-radius: 5px; width: 25px; height: 25px"></div>
                    <span>keep it and say nothing</span>
                  </div>
                </div>
              </div>
              <div style=" font-size: 17px">
                <div>
                  <strong >3</strong>
                  <span style="margin-left: 20px;">you</span>
                  <input />
                  <span >(can) speak perfect English?</span>
                </div>
                <div style="padding: 0 20px">
                  <div style="display:flex; column-gap: 15px; margin-top: 5px; align-items: center">
                    <div style="border: 1px solid rgb(0 0 0 ); border-radius: 5px; width: 25px; height: 25px"></div>
                    <span>move to Britain or the USA</span>
                  </div>
                  <div style="display:flex; column-gap: 15px; margin-top: 5px; align-items: center">
                    <div style="border: 1px solid rgb(0 0 0 ); border-radius: 5px; width: 25px; height: 25px"></div>
                    <span>become an interpreter</span>
                  </div>
                  <div style="display:flex; column-gap: 15px; margin-top: 5px; align-items: center">
                    <div style="border: 1px solid rgb(0 0 0 ); border-radius: 5px; width: 25px; height: 25px"></div>
                    <span>learn more languages</span>
                  </div>
                </div>
              </div>
              <div style=" font-size: 17px">
                <div>
                  <strong >4</strong>
                  <span style="margin-left: 20px;">you</span>
                  <input />
                  <span >(know) that a classmate was cheating in an exam?</span>
                </div>
                <div style="padding: 0 20px">
                  <div style="display:flex; column-gap: 15px; margin-top: 5px; align-items: center">
                    <div style="border: 1px solid rgb(0 0 0 ); border-radius: 5px; width: 25px; height: 25px"></div>
                    <span>tell the teacher</span>
                  </div>
                  <div style="display:flex; column-gap: 15px; margin-top: 5px; align-items: center">
                    <div style="border: 1px solid rgb(0 0 0 ); border-radius: 5px; width: 25px; height: 25px"></div>
                    <span>talk to the classmate about it</span>
                  </div>
                  <div style="display:flex; column-gap: 15px; margin-top: 5px; align-items: center">
                    <div style="border: 1px solid rgb(0 0 0 ); border-radius: 5px; width: 25px; height: 25px"></div>
                    <span>do nothing</span>
                  </div>
                </div>
              </div>
              <div style=" font-size: 17px">
                <div>
                  <strong >5</strong>
                  <span style="margin-left: 20px;">you</span>
                  <input />
                  <span >(break) your friend's mobile?</span>
                </div>
                <div style="padding: 0 20px">
                  <div style="display:flex; column-gap: 15px; margin-top: 5px; align-items: center">
                    <div style="border: 1px solid rgb(0 0 0 ); border-radius: 5px; width: 25px; height: 25px"></div>
                    <span>give him / her your mobile</span>
                  </div>
                  <div style="display:flex; column-gap: 15px; margin-top: 5px; align-items: center">
                    <div style="border: 1px solid rgb(0 0 0 ); border-radius: 5px; width: 25px; height: 25px"></div>
                    <span>buy a new one</span>
                  </div>
                  <div style="display:flex; column-gap: 15px; margin-top: 5px; align-items: center">
                    <div style="border: 1px solid rgb(0 0 0 ); border-radius: 5px; width: 25px; height: 25px"></div>
                    <span>say that you didn't break it</span>
                  </div>
                </div>
              </div>
              <div style=" font-size: 17px">
                <div>
                  <strong >6</strong>
                  <span style="margin-left: 20px;">you</span>
                  <input />
                  <span >(find) €100 in school?</span>
                </div>
                <div style="padding: 0 20px">
                  <div style="display:flex; column-gap: 15px; margin-top: 5px; align-items: center">
                    <div style="border: 1px solid rgb(0 0 0 ); border-radius: 5px; width: 25px; height: 25px"></div>
                    <span>keep it</span>
                  </div>
                  <div style="display:flex; column-gap: 15px; margin-top: 5px; align-items: center">
                    <div style="border: 1px solid rgb(0 0 0 ); border-radius: 5px; width: 25px; height: 25px"></div>
                    <span>give it to a teacher</span>
                  </div>
                  <div style="display:flex; column-gap: 15px; margin-top: 5px; align-items: center">
                    <div style="border: 1px solid rgb(0 0 0 ); border-radius: 5px; width: 25px; height: 25px"></div>
                    <span>give it to a charity</span>
                  </div>
                </div>
              </div>
          </div>
        </div>
          `,
        answer: [
          "saw",
          "gave",
          "didn't like / did not like",
          "could",
          "knew",
          "broke",
          "found",
        ],
      },
    ],
  },
  4: {
    // Exercise num
    unit: 'Unit 5',
    id: 'WB10-U5-P49-E5',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '4',
        title: 'Now write your answers to the quiz in exercise 3 as conditional sentences.',
        color: '#000000',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      borderBottom: '2px solid rgb(0 0 0 )',
      backgroundColor: 'transparent',
    },
    // maxLength: 1,
    // exerciseKey: 'img/FriendsPlus/Page41/E6/Key/answerKey.png',
    isHiddenCheck: true,
    hideBtnFooter: true,
    questions: [
      // Row
      {
        title: /*html*/ `
        <div style="display: flex; column-gap: 20px; align-items: stretch;"> 
          <div style="display: flex; font-size: 25px; column-gap: 20px">
            <strong >1</strong>
          </div>
          <div>
            <div style="display: flex; justify-content: space-between;">
              <span>If I saw a road accident, I'd</span>
              <input width="530px" maxlength="45" />
            </div>
            <input width="830px" />
          </div>
        </div>        
         
        <div style="display: flex; column-gap: 20px; align-items: stretch;"> 
          <div style="display: flex; font-size: 25px; column-gap: 20px">
            <strong >2</strong>
          </div>
          <div>
            <input width="830px" />
            <input width="830px" />
          </div>
        </div>
        <div style="display: flex; column-gap: 20px; align-items: stretch;"> 
          <div style="display: flex; font-size: 25px; column-gap: 20px">
            <strong >3</strong>
          </div>
          <div>
            <input width="830px" />
            <input width="830px" />
          </div>
        </div>        
         
        <div style="display: flex; column-gap: 20px; align-items: stretch;"> 
          <div style="display: flex; font-size: 25px; column-gap: 20px">
            <strong >4</strong>
          </div>
          <div>
            <input width="830px" />
            <input width="830px" />
          </div>
        </div>
        <div style="display: flex; column-gap: 20px; align-items: stretch;"> 
          <div style="display: flex; font-size: 25px; column-gap: 20px">
            <strong >5</strong>
          </div>
          <div>
            <input width="830px" />
            <input width="830px" />
          </div>
        </div>        
         
        <div style="display: flex; column-gap: 20px; align-items: stretch;"> 
          <div style="display: flex; font-size: 25px; column-gap: 20px">
            <strong >6</strong>
          </div>
          <div>
            <input width="830px" />
            <input width="830px" />
          </div>
        </div>
          
        `,
        answer: [],
      },
    ],
  },
}

export default json;