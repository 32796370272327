import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Unit 8",
    id: "FG10-U8-P65-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page65/E1/Key/answerKey.png",
    component: T6,
    inputSize: 180,
    textAlign: 'center',
    // isHiddenCheck: true,
    maxLength: 15,
    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: "1",
        title: `
        Complete the sentences below with the present simple
        passive form of the verbs in brackets.
            `,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
            <div>
              <ol type="1" style="font-weight: bold">
                <li><span style="font-weight: normal;">Millions of mobile phones # every year.
                (sell)
                </span></li>
                <li><span style="font-weight: normal;">American football # very much in the UK.
                (not play)</span></li>
                <li><span style="font-weight: normal;"># tablets # in your school?
                (use)
                </span></li>
                <li><span style="font-weight: normal;">Most crimes # in big cities. (commit)</span></li>
                <li><span style="font-weight: normal;">Rubber trees # in cold countries. (not grow)</span></li>
                <li><span style="font-weight: normal;"># Samsung televisions # in
                Korea? (make)</span></li>
              </ol>
          </div>
            `,
        answer: [
          "are sold",
          "isn't played",
          "Are",
          "used",
          "are committed",
          "aren't grown",
          "Are",
          "made",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "FG10-U8-P65-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page65/E2/Key/answerKey.png",
    component: T6,
    inputSize: 750,
    // isHiddenCheck: true,
    maxLength: 200,
    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: "2",
        title: `
        Make the active sentences passive. Include the word by.
            `,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
              <div>
                <ol type="1" style="font-weight: bold">
                  <li><span style="font-weight: normal;">Millions of people watched the 2014 World Cup Final
                  on TV.</span> <br/><i style="color: rgb(97, 156, 236);font-weight: normal;">The 2014 World Cup Final was watched by millions of
                  people on TV.</i></li>
                  <li><span style="font-weight: normal;">Alexander Fleming discovered penicillin</br>#</span></li>
                  <li><span style="font-weight: normal;">Rafa Nadal didn't win the match.</br>#</span></li>
                  <li><span style="font-weight: normal;">Robots made that car.</br>#</span></li>
                  <li><span style="font-weight: normal;">Did Suzanne Collins write the <i>Hunger Games</i> books?</br>#</span></li>
                  <li><span style="font-weight: normal;">Peter Jackson directed the <i>Hobbit</i> films.</br>#</span></li>
                </ol>
              </div>
            `,
        answer: [
          "Penicillin was discovered by Alexander Fleming.",
          "The match wasn't won by Rafa Nadal.",
          "That car was made by robots.",
          "Were the Hunger Games books written by Suzanne Collins?",
          "The Hobbit films were directed by Peter Jackson. ",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "FG10-U8-P65-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page65/E3/Key/answerKey.png",
    component: T6,
    inputSize: 750,
    // isHiddenCheck: true,
    maxLength: 200,
    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: "3",
        title: `
        Put the words below in the correct order. (Remember where
          to put the adverb.)
            `,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
        <div>
          <ol type="1" style="font-weight: bold">
            <li><span style="font-weight: normal;">satnavs. / are / Phones / used as / sometimes </br> #</span></li>
            <li><span style="font-weight: normal;">Was / Bob Dylan? / <i>Make you feel my love</i> / first / by / sung </br> #</span></li>
            <li><span style="font-weight: normal;">invented / The wheel / about 5,000 years ago. / probably /
            was</br> #</span></li>
            <li><span style="font-weight: normal;">quickly / was / The suspect / arrested.</br> #</span></li>
            <li><span style="font-weight: normal;">the missing jewellery / ever / found? / Was</br> #</span></li>
          </ol>
        </div>
            `,
        answer: [
          "Phones are sometimes used as satnavs.",
          "Was Make you feel my love first sung by Bob Dylan?",
          "The wheel was probably invented about 5,000 years ago.",
          "The suspect was quickly arrested.",
          "Was the missing jewellery ever found?",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "FG10-U8-P65-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page65/E4/Key/answerKey.png",
    component: T6,
    inputSize: 190,
    textAlign: 'center',
    // isHiddenCheck: true,
    maxLength: 20,
    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: "4",
        title: `
        Listen again and complete the sentences with the
        words below. Circle the correct speaker (C – customer or
        SA – shop assistant) for each sentence.
                    `,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
              <div>
                <div style="background-color: rgb(250, 206, 157); width: 1200px; height: auto; padding: 20px; border-radius: 16px">
                  <div>
                    <img src="img/FriendsPlus/Page65/E4/1.jpg" alt="image" width = 50%/>
                  </div>
                  <div style="background-color: white; position: relative; width: 1160px; height: auto; margin-top: 30px; padding:30px; border-radius: 16px">
                    <div style="position: absolute; top: 10px; right: -200px">
                      <img src="img/FriendsPlus/Page65/E4/2.jpg" alt="image" width = 60%/>
                    </div>
                    <div style= "max-width: 950px">
                      <ul>
                        <li>The first computer <sup><strong>1</strong></sup>#
                        (invent) by Charles Babbage in the
                        early 19th century. It <sup><strong>2</strong></sup>#
                        (not power) by electricity – it was
                        mechanical.</li>
                        <li>The first electronic digital computer
                        <sup><strong>3</strong></sup># (build) in 1943 in
                        Britain. It <sup><strong>4</strong></sup># (call)
                        ‘Colossus' and it <sup><strong>5</strong></sup>#
                        (use) for decoding enemy messages in
                        the war.</li>
                        <li>Businesses began to use computers in the
                        1950s, but computers <sup><strong>6</strong></sup> # (not sell)
                        in shops until the early 1970s.</li>
                      </ul>
                    </div>
                  </div>
                  <div style="background-color: white; position: relative; margin-top: 40px; padding: 30px; border-radius: 16px">
                    <div style="position: absolute; top: 50px; right: -400px">
                      <img src="img/FriendsPlus/Page65/E4/3.jpg" alt="image" width = 60% style="border-radius: 16px"/>
                    </div>
                    <div style= "max-width: 750px">
                      <ul>
                        <li>The first desktop computers <sup><strong>7</strong></sup> #
                        (develop) in the late 1970s.</li>
                        <li>Internet access via a modem
                        <sup><strong>8</strong></sup> # (add) in the
                        1990s, but wireless access
                        <sup><strong>9</strong></sup> # (not add)
                        to most computers until
                        about 2000.</li>
                      </ul>
                    </div>
                  </div>
                  <div style="background-color: white; margin-top: 30px; width: 800px; padding: 30px; border-radius: 16px">
                    <div style= "max-width: 750px">
                      <ul>
                        <li>For the first time, in 2015, more tablets
                        and smartphones <sup><strong>10</strong></sup> #
                        (sell) than laptops and desktops. Will
                        computers eventually disappear from
                        our homes and schools? </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            `,
        answer: [
          "was invented",
          "wasn't powered",
          "was built",
          "was called",
          "was used",
          "weren't sold",
          "were developed",
          "was added",
          "wasn't added",
          "were sold",
        ],
      },
    ],
  },
};

export default json;
