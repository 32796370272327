
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';

const json = {
  1: {
    unit: "Unit 7",
    id: "FG10-U7-P58-E1",
    audio: "Audios/2.03.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page58/E1/Key/answerKey.png",
    component: Circle_Write,
    // isHiddenCheck: true,
    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: "1",
        title: `
			<headphone name="2.03"></headphone>  Read the <span style="color: rgb(195, 14, 116)">Listening Strategy</span>. Then listen and underline the stressed word in the sentences below.
				`,
        color: "#000",
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 5, textDecorationLine: "underline" },
        limitSelect: 1,
        listWords: [
          ` Actually, I ordered the chicken pasta.`,
          ` I need two seats near the front of the plane.`,
          ` No, there's a bus to the cathedral.`,
          ` I'd rather go to the castle first.`,
          ` The market is very touristy.`,
        ],
        answers: ['0-12', '1-6', '2-14', '3-12', '4-10'],
        initialValue: [],
      },
      Layout: `
				  <div style="margin-top: 30px">
              <div style="background-image: url('img/FriendsPlus/Page58/E1/1.jpg'); background-repeat: no-repeat; background-size: 700px auto; height: 350px;width:700px; padding: 20px 100px 30px 30px; border-radius: 16px">
              <h2 style="font-size: 24px; color: rgb(195, 14, 116)">Listening Strategy</h2>
              <div style="font-size: 22px">
                <div style="margin-bottom: 20px">
                  <span>Notice the way that speakers emphasise certain words
                  to stress an alternative or to correct what someone has
                  said:</span>
                </div>
                <span><i style="color: rgb(76, 132, 188)">‘Would you like a double room or a single room?'</i></span></br>
                <span><i style="color: rgb(76, 132, 188)">‘A double room, please.'</i></span></br>
                <span><i style="color: rgb(76, 132, 188)">‘Does the plane arrive at nine?' ‘No, it arrives at five.'</i></span></br>
                <span><i style="color: rgb(76, 132, 188)">‘Dave's in Paris.' ‘No, he isn't. He's in Rome.'</i></span></br>
              </div>
            </div>
            <div style="margin-top: 10px">
                <ol type="1" style="font-weight: bold; display: flex; flex-direction: column; gap: 20px 0;">
                  <li><span style="font-weight: normal;"><input id='0' type='Circle' /></span></li>
                  <li><span style="font-weight: normal;"><input id='1' type='Circle' /></span></li>
                  <li><span style="font-weight: normal;"><input id='2' type='Circle' /></span></li>
                  <li><span style="font-weight: normal;"><input id='3' type='Circle' /></span></li>
                  <li><span style="font-weight: normal;"><input id='4' type='Circle' /></span></li>
                </ol>
            </div>
      `,
    },
  },
  2: {
    unit: "Unit 7",
    id: "FG10-U7-P58-E2",
    audio: "Audios/2.03.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page58/E2/Key/answerKey.png",
    component: T6,
    inputSize: 40,
    // isHiddenCheck: true,
    maxLength: 1,
    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: "2",
        title: `
			<headphone name="2.03"></headphone> Listen again. Decide which
      sentence came before each sentence in
      exercise 1:<br/> a or b. Only one option makes
      sense with the stressed words.
				`,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
				  <div style="margin-top: 30px">
            <ol type="1" style="font-weight: bold; display: flex; flex-direction: column; gap: 15px 0">
              <li>
                <ol type="a" style="font-weight: bold">
                  <li><span style="font-weight: normal;">Here's your chicken curry, madam. </span></li>
                  <li><span style="font-weight: normal;">Here's your vegetable pasta, madam.</br>
                  Actually, I ordered the chicken pasta.</span></li>
                </ol>
              </li>
              <li>
                <ol type="a" style="font-weight: bold">
                  <li><span style="font-weight: normal;">I've booked you two seats near the
                  middle of the plane.</span></li>
                  <li><span style="font-weight: normal;">I've booked you a seat near the front of the plane.</br>
                  I need two seats near the front of the plane.</span></li>
                </ol>
              </li>
              <li>
                <ol type="a" style="font-weight: bold">
                  <li><span style="font-weight: normal;">Do we have to walk to the cathedral?</span></li>
                  <li><span style="font-weight: normal;">Is there a bus to the palace?</br>
                  No, there's a bus to the cathedral.</span></li>
                </ol>
              </li>
              <li>
                <ol type="a" style="font-weight: bold">
                  <li><span style="font-weight: normal;">Let's go to the castle later. </span></li>
                  <li><span style="font-weight: normal;">Let's go to the museum first.</br>
                  I'd rather go to the castle first.</span></li>
                </ol>
              </li>
              <li>
                <ol type="a" style="font-weight: bold">
                  <li><span style="font-weight: normal;">The market is a good place to visit, isn't it?</span></li>
                  <li><span style="font-weight: normal;">The whole city is really touristy, isn't it?</br>
                  The market is very touristy.</span></li>
                </ol>
              </li>
            </ol>
            <div>
              <strong>
                <span style="margin-right: 30px">Answer: </span>
                <span style="margin-right: 30px">1: #</span>
                <span style="margin-right: 30px">2: #</span>
                <span style="margin-right: 30px">3: #</span>
                <span style="margin-right: 30px">4: #</span>
                <span style="margin-right: 30px">5: #</span>
              </strong>
            </div>
				  </div>
				`,
        answer: ["a", "b", "b", "b", "a"],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "FG10-U7-P58-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page58/E3/Key/answerKey.png",
    component: T6,
    inputSize: 200,
    // isHiddenCheck: true,
    maxLength: 15,
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: "3",
        title: `
        Read the mini-dialogues and underline the word you need
        to stress in each of B's lines.
				`,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
      <div style="margin-top: 30px">
        <ol type="1" style="font-weight: bold; display: flex; flex-direction: column; gap: 15px 0">
          <li>
            <ol type="A" style="font-weight: bold">
              <li><span style="font-weight: normal;">Did you get a new smartphone for your birthday?</span></li>
              <li><span style="font-weight: normal;">No, I got a new camera for my birthday.</span></li>
            </ol>
          </li>
          <li>
            <ol type="A" style="font-weight: bold">
              <li><span style="font-weight: normal;">Did you have a good time in Egypt last month?</span></li>
              <li><span style="font-weight: normal;">We went to Morocco last month.</span></li>
            </ol>
          </li>
          <li>
            <ol type="A" style="font-weight: bold">
              <li><span style="font-weight: normal;">Did you book a double room for three nights?</span></li>
              <li><span style="font-weight: normal;">No, I booked a single room for three nights.</span></li>
            </ol>
          </li>
          <li>
            <ol type="A" style="font-weight: bold">
              <li><span style="font-weight: normal;">Shall we go by taxi or by bus?</span></li>
              <li><span style="font-weight: normal;">Let's go by taxi.</span></li>
            </ol>
          </li>
          <li>
            <ol type="A" style="font-weight: bold">
              <li><span style="font-weight: normal;">I think the zoo is closed in December</span></li>
              <li><span style="font-weight: normal;">No, I checked – it's open in December</span></li>
            </ol>
          </li>
        </ol>
      </div>
				`,
        answer: [
          "atmospheric",
          "spectacular",
          "beautiful",
          "crowded",
          "busy",
          "romantic",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "FG10-U7-P58-E4",
    audio: "Audios/2.04.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page58/E4/Key/answerKey.png",
    component: Circle_Write,
    inputSize: 120,
    // isHiddenCheck: true,
    maxLength: 15,
    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: "4",
        title: `
        <headphone name="2.04"></headphone> Listen and check your answers to exercise 3.
				`,
        color: "#000",
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 5, textDecorationLine: "underline" },
        limitSelect: 1,
        listWords: [
          ` No, I got a new camera for my birthday.`,
          ` We went to Morocco last month.`,
          ` No, I booked a single room for three nights.`,
          ` Let's go by taxi.`,
          ` No, I checked – it's open in December.`,
        ],
        answers: ['0-12', '1-8', '2-10', '3-8', '4-12'],
        initialValue: [],
      },
      Layout: `
        <div style='display:flex'>
          <b>1 &emsp;</b>
          <div>
            <div style='display:flex'>
              <div>
                <div><b>A &ensp;</b></div>
                <div><b>B &ensp;</b></div>
              </div>
              <div>Did you get a new smartphone for your birthday?<br/><input id='0' type='Circle' /></div>
            </div>
          </div>
        </div>
        <div style='display:flex'>
          <b>2 &emsp;</b>
          <div>
            <div style='display:flex'>
              <div>
                <div><b>A &ensp;</b></div>
                <div><b>B &ensp;</b></div>
              </div>
              <div>Did you have a good time in Egypt last month? <br/><input id='1' type='Circle' /></div>
            </div>
          </div>
        </div>
        <div style='display:flex'>
          <b>3 &emsp;</b>
          <div>
            <div style='display:flex'>
              <div>
                <div><b>A &ensp;</b></div>
                <div><b>B &ensp;</b></div>
              </div>
              <div>Did you book a double room for three nights? <br/><input id='2' type='Circle' /></div>
            </div>
          </div>
        </div>
        <div style='display:flex'>
          <b>4 &emsp;</b>
          <div>
            <div style='display:flex'>
              <div>
                <div><b>A &ensp;</b></div>
                <div><b>B &ensp;</b></div>
              </div>
              <div>Shall we go by taxi or by bus? <br/><input id='3' type='Circle' /></div>
            </div>
          </div>
        </div>
        <div style='display:flex'>
          <b>5 &emsp;</b>
          <div>
            <div style='display:flex'>
              <div>
                <div><b>A &ensp;</b></div>
                <div><b>B &ensp;</b></div>
              </div>
              <div>I think the zoo is closed in December. <br/><input id='4' type='Circle' /></div>
            </div>
          </div>
        </div>
      `,
    },
  },
  5: {
    unit: "Unit 7",
    id: "FG10-U7-P58-E5",
    audio: "Audios/2.05.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page58/E5/Key/answerKey.png",
    component: T6,
    inputSize: 40,
    // isHiddenCheck: true,
    maxLength: 1,
    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: "5",
        title: `
			<headphone name="2.05"></headphone>  Listen to a true story. Look at the map and identify
      the route Sandy Valdivieso and her husband flew. Is it 1, 2, 3
      or 4?
				`,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
				  <div style="margin-top: 30px">
            <div style="display: flex;align-items: center;">
              <div>
                <img src="img/FriendsPlus/Page58/E5/1.jpg" alt="" style="width: 90%;"/>
              </div>
              <div style="width: 900px">
                <div>
                  <ol type="1" style="font-weight: bold; display: flex; flex-direction: column; gap: 20px 0;">
                    <li><span style="font-weight: normal;">They flew from A to B to C to D.</span></li>
                    <li><span style="font-weight: normal;">They flew from A to C to D to B.</span></li>
                    <li><span style="font-weight: normal;">They flew from A to C to D to C to B.</span></li>
                    <li><span style="font-weight: normal;">They flew from A to C to D to C to A.</span></li>
                  </ol>
                </div>
                <div style="margin-top: 30px">
                  <strong>
                    <span style="margin-right: 10px">Answer: #</span>
                  </strong>
                </div>
              </div>
            </div>
            <div>
              
            </div>
          </div>
				`,
        answer: ["3"],
      },
    ],
  },
  6: {
    unit: "Unit 7",
    id: "FG10-U7-P58-E6",
    audio: "Audios/2.05.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page58/E6/Key/answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "6",
        title: `
			<headphone name="2.05"></headphone>  Listen again. Are the sentences true (T) or false (F)?
				`,
        color: "#000",
      },
    ],
    selectStyle: {
      color: "black",
      fontWeight: "bold",
      width: 40,
      textAlign: "center",
      border: "2px solid black",
      borderRadius: "20%",
    },
    selectOptionValues: ["T", "F"],
    textAlign: 'center',
    questions: [
      {
        title: `
        
        <div style='display:flex; gap:15px;'>
            <b>1</b>
            <div style='display:flex; gap:10px;'>
            Sandy Valdivieso and her husband wanted to visit Africa,
          not Asia. <select id='0'></select>
            </div>
        </div>
        <div style='display:flex; gap:15px;'>
            <b>2</b>
            <div style='display:flex; gap:10px;'>
            Their journey went wrong because an employee made a
          mistake at the airport in Istanbul.<select id='1'></select>
            </div>
        </div>
        <div style='display:flex; gap:15px;'>
            <b>3</b>
            <div style='display:flex; gap:10px;'>They realised something was wrong as soon as the plane
          left Istanbul.<select id='2'></select>
            </div>
        </div>
        <div style='display:flex; gap:15px;'>
            <b>4</b>
            <div style='display:flex; gap:10px;'>They saw the location of the plane on a map while they
          were flying.<select id='3'></select>
            </div>
        </div>
        <div style='display:flex; gap:15px;'>
            <b>5</b>
            <div style='display:flex; gap:10px;'>They were in Bangladesh for more than twelve hours.<select id='4'></select>
            </div>
        </div>
        <div style='display:flex; gap:15px;'>
            <b>6</b>
            <div style='display:flex; gap:10px;'>They never managed to get to Senegal.<select id='5'></select>
            </div>
        </div>
				`,
        answer: ["T", "F", "F", "T", "F", "F"],
      },
    ],
  },
};

export default json;
