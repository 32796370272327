import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';

const json = {
	1: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P14-E1',
		component: T6,
		stylesTextInput: {
			backgroundColor: 'transparent',
			borderBottom: '1px solid',
			padding: 10,
			fontSize: 22,
		},
		exerciseKey: 'img/FriendsPlus/Page14/E1/Key/answerKey.png',
		inputSize: 330,
		maxLength: 29,
		titleQuestion: [
			{
				num: '1',
				title:
					'Put the words and punctuation marks in the correct order to make phrases for reacting and showing interest.',
			},
		],
		isHiddenCheck: true,
		checkUppercase: true,
		questions: [
			{
				title: `
              <div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; ">envious / I'm / so / really / ? / !</br><span style="color: rgb(0, 68, 98)">Really? I'm so envious!</span></div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; ">what / really / relief / a / ? / !</br>#</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; ">like / sounds / nightmare / a / that / !</br>#</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; ">what / oh / shame / no / a / ! / ! </br>#</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; ">kidding / you're / !</br>#</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; ">that / like / sounds / fun / ! </br>#</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>7. </b></div><div style="margin-left: 30px; flex: 1; ">upsetting / how / !</br>#</div></div>
              </div>
        `,
				answer: [
					'Really? What a relief!',
					'That sounds like a nightmare!',
					'Oh no! What a shame!',
					"You're kidding!",
					'That sounds like fun!',
					'How upsetting! ',
				],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P14-E2',
		component: T6,
		stylesTextInput: {
			backgroundColor: 'transparent',
			borderBottom: '1px solid',
			padding: 10,
			fontSize: 20,
		},
		exerciseKey: 'img/FriendsPlus/Page14/E2/Key/answerKey.png',
		inputSize: 330,
		titleQuestion: [
			{
				num: '2',
				title:
					'React to these sentences. Use phrases from exercise 1. More than one answer is possible for each sentence.',
			},
		],
		checkUppercase: true,
		questions: [
			{
				title: `
        <div style="font-size: 20px">
          <div style="display: flex; margin-block: 20px"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "><div style="background-color: rgb(182, 212, 185); padding: 10px; width: fit-content; border-radius: 20px; box-shadow: 3px 3px 3px grey; margin-bottom: 5px ">I lost my mobile phone!</div>#</div></div>
          <div style="display: flex; margin-block: 20px"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "><div style="background-color: rgb(182, 212, 185); padding: 10px; width: fit-content; border-radius: 20px; box-shadow: 3px 3px 3px grey; margin-bottom: 5px ">I went scuba diving when I was on holiday. </div>#</div></div>
          <div style="display: flex; margin-block: 20px"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "><div style="background-color: rgb(182, 212, 185); padding: 10px; width: fit-content; border-radius: 20px; box-shadow: 3px 3px 3px grey; margin-bottom: 5px ">I'm going to be on TV tonight!</div>#</div></div>
          <div style="display: flex; margin-block: 20px"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "><div style="background-color: rgb(182, 212, 185); padding: 10px; width: fit-content; border-radius: 20px; box-shadow: 3px 3px 3px grey; margin-bottom: 5px ">I left my wallet in a shop, but they returned it to me.</div>#</div></div>
          <div style="display: flex; margin-block: 20px"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; "><div style="background-color: rgb(182, 212, 185); padding: 10px; width: fit-content; border-radius: 20px; box-shadow: 3px 3px 3px grey; margin-bottom: 5px ">I got 95% in my French exam.</div>#</div></div>
        </div>
        `,
				answer: [
					"That sounds like a nightmare!/Oh no!/What a shame!/You're kidding!/How upsetting!",
					"What a cool thing to do!/That's amazing!/You're kidding!/That sounds like fun!",
					"What a cool thing to do!/That's amazing!/You're kidding!/That sounds like fun!",
					"Really? What a relief!/You're kidding!",
					"Really? What a relief!/That's amazing!/You're kidding!",
				],
			},
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P14-E3',
		component: T6,
		checkDuplicated: true,
		stylesTextInput: {
			backgroundColor: 'transparent',
			borderBottom: '1px solid',
			padding: 10,
			fontSize: 20,
			textAlign: 'center',
		},
		exerciseKey: 'img/FriendsPlus/Page14/E3/Key/answerKey.png',
		inputSize: 100,
		titleQuestion: [
			{
				num: '3',
				title: 'Read the forum post about an event and complete the notes.',
			},
		],
		isHiddenCheck: true,
		questions: [
			{
				title: `
              <div style="display: flex; align-items: center; font-size: 20px; background-color: rgb(227, 226, 241); padding: 10px; border-radius: 15px">
                <div style="flex: 2; background-color: rgb(182, 212, 185); padding: 10px 10px 30px 10px; display: flex; border-radius: 15px; box-shadow: 2px 2px 4px grey">
                  <div style="padding: 10px;background-color: white; border-radius: 15px">
                    <b style="color: rgb(0, 147, 69)">littlehelen</b> It was the last day of the summer holidays and I 
                    was at my friend Alice's house. She said, ‘Everyone is wearing 
                    fancy dress to school tomorrow to raise money for charity.'</br>
                    So the next day, I went to school in fancy dress. But it was a 
                    joke! No one was wearing fancy dress! I had to go home and 
                    change, and I missed the first two lessons.</br>
                    I was very embarrassed, but Alice thought it was really funny 
                    and couldn't stop laughing. The teacher was a bit cross with 
                    me and Alice!
                  </div>
                </div>

                <div style="margin-left: 20px; flex: 3;">
                  <div style="display: flex; margin-block: 20px">
                    <div style="display: inline-block; width: 40px; text-align: right;"><strong>1.</strong></div>
                    <div style="margin-left: 30px; flex: 1; ">
                      <div>Set the scene.</div>
                      <div style="display: flex">Who?<span style="padding-left: 10px; color: rgb(0, 68, 98)">Helen</span>, <input id='0' width='70px' /></div>
                      <div style="display: flex">Where? <input id='1' width='150px' />, <input id='2' width='150px' />, <input id='3' width='150px' /></div>
                      <div style="display: flex">
                        <div>When?</div>
                        <div>
                          <div><input id='4' width='350px' />,</div>
                          <div><input id='5' width='350px' /></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div style="display: flex; margin-block: 20px">
                    <div style="display: inline-block; width: 40px; text-align: right;"><b>2.</b></div>
                      <div style="margin-left: 30px; flex: 1; ">
                        <div>What happened?</div>
                        <div style="display: flex"><span style="color: rgb(0, 68, 98)">Alice - said tomorrow - fancy dress</span></div>
                        <div><input id='6' width='230px' /> - <input id='7' width='290px' /></div>
                        <div><input id='8' width='230px' /> - <input id='9' width='290px' /></div>
                        <div><input id='10' width='230px' /> - <input id='11' width='290px' /></div>
                        <div><input id='12' width='230px' /> - <input id='13' width='290px' /></div>
                        <div><input id='14' width='230px' /></div>
                      </div>
                    </div>

                      <div style="display: flex; margin-block: 20px">
                        <div style="display: inline-block; width: 40px; text-align: right;"><b>3.</b></div>
                        <div style="margin-left: 30px; flex: 1; ">
                          <div>How did people feel?</div>
                          <div style="display: flex"><span style="color: rgb(0, 68, 98)">Alice - amused - laughed</span></div>
                          <div style="display: flex"><span style="color: rgb(0, 68, 98)">Helen -</span><input id='15' width='150px' /></div>
                          <div style="display: flex"><span style="color: rgb(0, 68, 98)">Teacher -</span><input id='16' width='150px' /></div>
                        </div>
                      </div>

                    </div>
                  </div>
              </div>
            </div>
        `,
				answer: [
					'Alice',
					"Alice's house",
					'school',
					"Helen's house",
					'The last day of the summer holidays',
					'the first day of school',
					'raise money for charity',
					'next day school in fancy dress',
					'joke',
					'no one wearing fancy dress',
					'home to change',
					'missed lessons',
					'embarrassed',
					'Alice laughed',
					'teacher cross',
					'embarrassed',
					'cross',
				],
			},
		],
	},
	4: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P14-E4',
		component: T6,
		stylesTextInput: {
			backgroundColor: 'transparent',
			borderBottom: '1px solid',
			padding: 10,
			fontSize: 22,
		},
		exerciseKey: 'img/FriendsPlus/Page14/E1/Key/answerKey.png',
		inputSize: 330,
		maxLength: 1,
		textareaStyle: {
			width: 400,
			background: 'transparent',
			bordorBottom: '1px solid',
		},
		titleQuestion: [
			{
				num: '4',
				title:
					'Read the <span style="color: rgb(0, 147, 69)">Speaking Strategy</span> and the task. Then write notes.',
			},
		],
		isHiddenCheck: true,
		checkUppercase: true,
		hideBtnFooter: true,
		questions: [
			{
				title: `
              <div style="text-align: center"><img style="width: 50%" src='img/FriendsPlus/Page14/E4/1.jpg' /></div>
              <div style="margin-block: 10px;padding: 10px; background-color: rgb(204, 225, 206); border-radius: 15px; box-shadow: 1px 1px 40px inset white; width: fit-content">
                Tell the story of an event that happened in the school holidays.
              </div>
              <div style="display: flex; margin-block: 20px">
                    <div style="display: inline-block; width: 40px; text-align: right;">
                      <b>1. </b>
                    </div>
                    <div style="margin-left: 30px; flex: 1; ">
                      <div>
                        Set the scene.
                      </div>
                      <div style="display: flex">Who?#</div>
                      <div style="display: flex">Where?# </div>
                      <div style="display: flex">When?#</div>
                    </div>
                  </div>
                  <div style="display: flex; margin-block: 20px">
                    <div style="display: inline-block; width: 40px; text-align: right;">
                      <b>2. </b>
                      </div>
                        <div style="margin-left: 30px; flex: 1; ">
                          <div>
                            What happened !
                          </div>
                          <div style="display: flex">Alice – said tomorrow – fancy dress</span># </div>
                        </div>
                      </div>
                      <div style="display: flex; margin-block: 20px">
                        <div style="display: inline-block; width: 40px; text-align: right;">
                          <b>3. </b>
                        </div>
                        <div style="margin-left: 30px; flex: 1; ">
                          <div>
                           How did you (and/or other people) feel? Think of at least three adjectives.
                          </div>
                          <div style="display: flex">#</div>
                        </div>
                      </div>
                    </div>
                  </div>
        `,
				answer: [],
			},
		],
	},
	5: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P14-E5',
		audio: '',
		video: '',
		component: UI,
		titleQuestion: [
			{
				num: '5',
				title: 'Now do the task using your notes from exercise 4',
			},
		],
		questionImage: [],
	},
};

export default json;
