import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 3",
    id: "WB10-U3-P29-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page29/E1/key/answerKey.png",
    component: T6,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "",
        title: `
			  `,
        color: "black",
      },
    ],
    textAlign: "center",
    maxLength: 12,
    inputSize: 140,
    titleImage: "",
    questions: [
      {
        title: `
          <div style='position: relative; margin-top: -40px; max-width: 850px; padding: 10px 20px 10px 15px; border-radius: 20px; border: 2px solid rgb(66, 64, 153);'>
            <div><span style='font-size: 25px; font-weight: bold; color: rgb(66, 64, 153);'>Revision:</span> Student Book page 40</div>
            <div style='display: flex; gap: 20px;'>
              <span style='font-size: 25px; font-weight: 600; color: rgb(110, 110, 112);'>1</span>
              <div>
                <div style='font-size: 25px; font-weight: 600;'>Complete the sentences with the correct form of the verbs below.</div>
                <div style='max-width: 500px; display: flex; gap: 1px 30px; flex-wrap: wrap; color: rgb(66, 64, 153); font-weight: 600;'>
                  <span>cause</span><span>do</span><span>express</span>
                  <span>get</span><span>have</span><span>make</span>
                  <span>provide</span><span>take part in</span><span>use</span>
                </div>    
                <div>
                  <div style='display: flex; gap: 20px;'>
                    <strong>1</strong>
                    <div>I often # online games like Minecraft with my friends.</div>
                  </div>
                  <div style='display: flex; gap: 20px;'>
                    <strong>2</strong>
                    <div>Video games can # many benefits, such as improving memory and creativity.</div>
                  </div>
                  <div style='display: flex; gap: 20px;'>
                    <strong>3</strong>
                    <div>The scientists # a lot of research on the topic and published the results yesterday.</div>
                  </div>
                  <div style='display: flex; gap: 20px;'>
                    <strong>4</strong>
                    <div>I prefer to # a smartphone rather than a computer.</div>
                  </div>
                  <div style='display: flex; gap: 20px;'>
                    <strong>5</strong>
                    <div>Video games can sometimes # a positive effect on teenagers.</div>
                  </div>
                  <div style='display: flex; gap: 20px;'>
                    <strong>6</strong>
                    <div>The amount of violence in video games can # concern for a lot of parents.</div>
                  </div>
                  <div style='display: flex; gap: 20px;'>
                    <strong>7</strong>
                    <div>Newspapers sometimes claim that video games are bad for children, and parents often # the same opinion.</div>
                  </div>
                  <div style='display: flex; gap: 20px;'>
                    <strong>8</strong>
                    <div>Playing computer games in groups can help you to work together with other people and to # decisions more quickly.</div>
                  </div>
                  <div style='display: flex; gap: 20px;'>
                    <strong>9</strong>
                    <div>Do you think playing computer games helps you to # better grades?</div>
                  </div>
                </div>
              </div>
            </div>

            <div style='position: absolute; top: 42px; left: -27px; z-index: 50;'><img src="img/FriendsPlus/Page29/E1/1.jpg" style="max-width: 45%; height: auto;" /></div>
          </div>
			  `,
        answer: [
          "take part in",
          "provide",
          "did",
          "use",
          "have",
          "cause",
          "express",
          "make",
          "get",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 3",
    id: "WB10-U3-P29-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page29/E2/key/answerKey.png",
    // selectStyle: {
    //   color: "gray",
    //   fontWeight: "bold",
    //   width: 40,
    //   textAlign: "center",
    //   border: "2px solid black",
    //   borderRadius: "20%",
    // },
    isHiddenCheck: true,

    selectOptionValues: ["✓", "✗"],
    titleQuestion: [
      {
        num: "2",
        title: `
				Read the text and answer the questions.
			  `,
        color: "black",
      },
    ],
    inputSize: 200,
    // stylesTextInput: {
    //   borderBottom: "dash",
    textAlign: "center",
    //   backgroundColor: "#fee4cb",
    //   fontSize: 18,
    //   height: 19,
    // },
    maxLength: 18,

    titleImage: "",
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page29/E2/1.jpg' style='border-radius:8%; width:1000px'/> 
        <div style='margin:20px;line-height:60px'>
          <b>1</b>&ensp; What does Jenny do?#<br>
          <b>2</b>&ensp; Which country did she visit?#<br>
          <b>3</b>&ensp; What kind of natural disaster did Jenny experience in 2011? #<br>
        </div>

			  `,
        answer: ["She's a scientist.", "Japan", "earthquake"],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 3",
    id: "WB10-U3-P29-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page29/E3/Key/answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "3 ",
        title: `Read the <span style='color:rgb(71 68 155)'>Listening Strategy</span>. Then read the text again and
        choose the correct answers.`,
        color: "black",
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: ["hello"],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
        },
        selectWordStyle: {
          border: "solid 3px",
          borderColor: "gray",
          padding: "5px",
        },
        limitSelect: 1,
        listWords: [
          `a|&emsp;don't&nbsp;value&nbsp;apps&nbsp;very&nbsp;highly.<br> b|&emsp;don't&nbsp;know&nbsp;what&nbsp;the&nbsp;main&nbsp;benefits&nbsp;of&nbsp;apps&nbsp;are.<br> c|&emsp;use&nbsp;apps&nbsp;mainly&nbsp;for&nbsp;fun&nbsp;and&nbsp;social&nbsp;situations.<br> d|&emsp;think&nbsp;apps&nbsp;are&nbsp;useless&nbsp;in&nbsp;dangerous&nbsp;situations.`, //4
          `a|&emsp;prevent&nbsp;an&nbsp;earthquake&nbsp;like&nbsp;the&nbsp;one&nbsp;in&nbsp;2011.<br> b|&emsp;warn&nbsp;people&nbsp;who&nbsp;are&nbsp;visiting&nbsp;Japan&nbsp;about&nbsp;earthquakes.<br> c|&emsp;warn&nbsp;Japanese&nbsp;people&nbsp;about&nbsp;earthquakes.<br> d|&emsp;warn&nbsp;business&nbsp;people&nbsp;about&nbsp;earthquakes.`, //4
          `a|&emsp;because&nbsp;no&nbsp;one&nbsp;told&nbsp;her&nbsp;what&nbsp;to&nbsp;do&nbsp;after&nbsp;an&nbsp;earthquake.<br> b|&emsp;because&nbsp;she&nbsp;couldn't&nbsp;understand&nbsp;the&nbsp;advice&nbsp;on&nbsp;Japanese&nbsp;media.
          <br> c|&emsp;because&nbsp;there&nbsp;was&nbsp;no&nbsp;information&nbsp;for&nbsp;tourists&nbsp;about&nbsp;what&nbsp;to&nbsp;do&nbsp;in&nbsp;an&nbsp;earthquake.<br> d|&emsp;because&nbsp;her&nbsp;Japanese&nbsp;colleagues&nbsp;didn't&nbsp;know&nbsp;what&nbsp;to&nbsp;do.`, //4
          `a|&emsp;is&nbsp;available&nbsp;in&nbsp;a&nbsp;number&nbsp;of&nbsp;languages.
          <br> b|&emsp;is&nbsp;available&nbsp;at&nbsp;airports&nbsp;and&nbsp;tourist&nbsp;information&nbsp;centres.
          <br> c|&emsp;warns&nbsp;about&nbsp;earthquakes&nbsp;but&nbsp;not&nbsp;about&nbsp;tsunamis.<br> d|&emsp;is&nbsp;in&nbsp;English,&nbsp;with&nbsp;Japanese&nbsp;phrases.`,
        ],
        answers: ["0-8", "1-4", "2-4", "3-56"],
        initialValue: [],
      },
      Layout: `
      <div style="position: relative; ">

        <div style=" position: absolute; top:5px; left: 572px; "><img src='img/FriendsPlus/Page29/E3/1.jpg' style=' width:16cm'/> </div>

          <b>1</b>&ensp;According to the text, most people
          <div style='margin-left:27px'>
            <input id='0' type='Circle' />
          </div>
          <b>2</b>&ensp;The main purpose of the Safety Tips app is to
          <div style='margin-left:27px'>
            <input id='1' type='Circle' />
          </div>
          <b>3</b>&ensp;Jenny had problems
          <div style='margin-left:27px'>
            <input id='2' type='Circle' />
          </div>
          <b>4</b>&ensp;At the moment, the app
          <div style='margin-left:27px'>
            <input id='3' type='Circle' />
          </div>
      </div>
     
         
      
        `,
    },
  },
  4: {
    unit: "Unit 3",
    id: "WB10-U3-P29-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page29/E4/key/answerKey.png",
    component: T6,
    // selectStyle: {
    //   color: "gray",
    //   fontWeight: "bold",
    //   width: 40,
    //   textAlign: "center",
    //   border: "2px solid black",
    //   borderRadius: "20%",
    // },
    // isHiddenCheck: true,

    selectOptionValues: ["✓", "✗"],
    titleQuestion: [
      {
        num: "4",
        title: `
				Study the dictionary entries. Then answer the questions
below.
			  `,
        color: "black",
      },
    ],
    // stylesTextInput: {
    //   width: 36,
    //   height: 36,
    //   borderRadius: 5,
    //   borderBottom: "dash",
    textAlign: "center",
    //   border: "2px solid black",
    //   // backgroundColor: "#fee4cb",
    //   fontSize: 22,
    // },
    maxLength: 11,
    inputSize: 170,

    titleImage: "",
    questions: [
      {
        title: `
          <img src='img/FriendsPlus/Page29/E4/1.jpg' style='width:16cm' />
          <div style='margin:10px;line-height:50px'>
            <b>1</b>&ensp;What is a synonym for caring?#<br>
            <b>2</b>&ensp;What is a synonym for considerate?# <br>
            <b>3</b>&ensp;Do both adjectives have antonyms beginning with a
            negative prefix?# <br>
            <div style='display:flex'>
              <b>4</b>&ensp;
              <div style='margin-left:10px; width:22cm'>Which dictionary entry has information about which
              preposition you use with the adjective?#<br><div>
            </div>
          </div>
			  `,
        answer: ["kind", "thoughtful", "yes", "considerate"],
      },
    ],
  },
  5: {
    unit: "Unit 3",
    id: "WB10-U3-P29-E5",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page29/E5/key/answerKey.png",
    component: T6,
    // selectStyle: {
    //   color: "gray",
    //   fontWeight: "bold",
    //   width: 40,
    //   textAlign: "center",
    //   border: "2px solid black",
    //   borderRadius: "20%",
    // },
    // isHiddenCheck: true,

    selectOptionValues: ["✓", "✗"],
    titleQuestion: [
      {
        num: "5",
        title: `
				Read the <span style='color:rgb(146 39 143)'>
        Vocab boost!
      </span> box. Use a dictionary to find one
synonym and one antonym of each adjective.
			  `,
        color: "black",
      },
    ],
    // stylesTextInput: {
    //   width: 36,
    //   height: 36,
    //   borderRadius: 5,
    //   borderBottom: "dash",
    textAlign: "center",
    //   border: "2px solid black",
    //   // backgroundColor: "#fee4cb",
    //   fontSize: 22,
    // },
    maxLength: 14,
    inputSize: 180,

    titleImage: "",
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page29/E5/1.jpg'/>
        <div style='display:flex;margin:10px;line-height:50px '>
          <div>
            <b>1&ensp;pleasant</b><br>
            <b>2&ensp;fortunate</b><br>
            <b>3&ensp;courteous</b><br>
            <b>4&ensp;probable</b>
          </div>
          <div style='margin-left:40px'>
              SYN#&emsp;OPP#<br>
              SYN#&emsp;OPP#<br>
              SYN#&emsp;OPP#<br>
              SYN#&emsp;OPP#
        
          </div>
        </div>

			  `,
        answer: [
          "enjoyable",
          "unpleasant",
          "lucky",
          "unfortunate",
          "polite",
          "discourteous",
          "almost certain",
          "improbable",
        ],
      },
    ],
  },
};

export default json;
